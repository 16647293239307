import React, { useState }from 'react';
import { Link } from 'react-router-dom';
import { FiLogOut , FiUser } from "react-icons/fi";
import { VscMenu , VscChromeClose } from "react-icons/vsc";
import logo from '../../../assets/images/logo.svg';
import './nav.scss';

export default function Nav({ activePage , logout }) {

  const [ mobileNavIsActive , setMobileNavIsActive ] = useState(false);

  return (
    <>
    <nav className={`${activePage !== "login" && activePage !== "legal" ? "isActive" : ""}`}>
      <div className="content">

        <Link to="/">
          <img src={logo} alt="medocta" />
        </Link>

        <div className="links">
          <Link to="/dataviz-genealogy" className={`${activePage === "dataviz-genealogy" || activePage === "dataviz-geography" || activePage === "dataviz-timeline" ? "isActive" : ""}`}>Visualisation</Link>
          <Link to="/" className={`${activePage === "patient" ? "isActive" : ""}`}>patients</Link>
          <Link to="/pathology" className={`${activePage === "pathology" ? "isActive" : ""}`}>maladies</Link>
        </div>
        
        <div className="icons">

          <Link to="/account">
            <FiUser size={22} color={"#192243"}/>
          </Link>

          <button className="logout" onClick={() => logout()}>
            <FiLogOut size={22} color={"#192243"}/>
          </button>

          <button className="mobile-toggle" onClick={() => setMobileNavIsActive(!mobileNavIsActive)}>
            {mobileNavIsActive ? <VscChromeClose size={22} color={"#192243"}/> : <VscMenu size={22} color={"#192243"}/>}
          </button>

        </div>

      </div>

    </nav>
      <div
        onClick={() => setMobileNavIsActive(!mobileNavIsActive)}
        className={`links-mobile ${mobileNavIsActive ? "isActive" : ""}`}>
        <Link to="/" className={`${activePage === "patient" ? "isActive" : ""}`}>patients</Link>
        <Link to="/pathology" className={`${activePage === "pathology" ? "isActive" : ""}`}>maladies</Link>
        <Link to="/account" className={`${activePage === "account" ? "isActive" : ""}`} >Mon compte</Link>
        <button className="logout" onClick={() => logout()}>
          Déconnexion
        </button>
      </div>
    </>
  )
}
