import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { GlobalProvider } from './context/GlobalContext';

import authReducer from './reducers/authReducer';
import pathologyReducer from './reducers/pathologyReducer';
import patientsReducer from './reducers/patientsReducer';
import dataVizReducer from './reducers/dataVizReducer';

import Routes from './components/routes/GlobalRouter';

function App() {

  function combineReducers(reducers) {
    const reducerKeys = Object.keys(reducers);
  
    return function combination(state = {}, action) {
      const nextState = {}
      for (let i = 0; i < reducerKeys.length; i++) {
        const key = reducerKeys[i];
        const reducer = reducers[key]
        const previousStateForKey = state[key]
        const nextStateForKey = reducer(previousStateForKey, action)
        nextState[key] = nextStateForKey;
      }
      return nextState;
    }
  }

  const reducers = combineReducers({
    auth: authReducer,
    pathology: pathologyReducer,
    patient: patientsReducer,
    dataviz: dataVizReducer
  });

  const initialState = reducers( {} , { type: '__INIT__' });

  return (
    <GlobalProvider
      reducers={reducers}
      initialState={initialState}
      >
      <div className="app-container">
        <Router>
          <Routes />
        </Router>
      </div>
    </GlobalProvider>
  );
}

export default App;
