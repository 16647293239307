import React , { useEffect , useCallback , useState } from 'react';
import { useGlobalContext } from '../../../context/GlobalContext';
import { getUserAuthAction } from '../../../actions/authActions';
import { getPathologiesAction } from '../../../actions/pathologyActions';
import Card from '../../partials/Card/Card';
import PathologyPanel from './partial/PathologyPanel';

export default function Pathologies({ setActivePage }) {

  const [ context, dispatch ] = useGlobalContext();

  const [ filteredList , setFilteredList ] = useState();
  const [ panelIsOpen , setPanelIsOpen ] = useState(false)

  const { pathologiesList , pathology } = context.pathology

  const getData = useCallback(() => {
    getUserAuthAction(dispatch);
    getPathologiesAction(dispatch);
  },[dispatch]);

  const clearPathology = useCallback(() => {
    dispatch({
      type:'GET_PATHOLOGY',
      payload: null
    })
  },[dispatch]);

  useEffect(() => {
    setActivePage("pathology");
    getData();
    return () => {
      clearPathology();
    }
  }, [setActivePage , getData , clearPathology ]);

  useEffect(() => {
    setFilteredList(pathologiesList)
  },[pathologiesList]);

  useEffect(() => {
    if (pathology) {
      setPanelIsOpen(true);
    }
  },[pathology]);

  function closePathologyPanel() {
    setPanelIsOpen(false);
    dispatch({
      type:'GET_PATHOLOGY',
      payload: null
    })
  }


  function renderList() {
    let type = "pathology";
    return filteredList.map((item , i ) => <Card
      key={item._id}
      type={type}
      card={item}
      activeId={pathology && pathology._id}
      context={context}
      dispatch={dispatch}/> )
  }

  return (
    <>
      <div className="page-container">
        <div className={`grid-container ${panelIsOpen ? "panel-open" : ""}`}>
          <div className={`content cards-grid ${panelIsOpen ? "panel-open" : ""}`}>
            {filteredList && renderList()}
          </div>
        </div>

      </div>
      <PathologyPanel
        isOpen={panelIsOpen}
        close={() => closePathologyPanel()}
        />
    </>
  )
}
