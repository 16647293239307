import { postData , getData , putData , TOOL_ID } from './index';

import {
  ERROR_AUTH,
  LOGIN,
  USER_AUTH,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  PUT_USER_AUTH,
  ERROR_LOGIN,
} from "./types"

export async function loginAction(dispatch , obj){
  let url = "/auth/login";
  var login;

  await postData(LOGIN , ERROR_LOGIN, url, dispatch , { ...obj, tool: TOOL_ID } , false ).then((response)=>{
    login = response
  });

  localStorage.setItem("token" , login.token);

  dispatch({
    type : LOGIN ,
    payload : login.user
  });

};

export async function getUserAuthAction(dispatch) {
  let url = "/profile";
  var user;

  await getData(ERROR_AUTH, url, dispatch , true ).then((response)=>{
    user = response.user
  });

  dispatch({
    type : USER_AUTH,
    payload : user
  });

};

export async function putUserAuthAction(dispatch , data ) {
  let url = "/profile";
  var user;

  await putData(PUT_USER_AUTH, ERROR_AUTH ,  url , dispatch, data , true ).then((response)=>{
    user = response
  });

  dispatch({
    type : PUT_USER_AUTH,
    payload : user
  });

};

export async function forgotPasswordAction(dispatch , obj){
  let url = "/auth/forgot-password";
  var mailSend;

  await postData(FORGOT_PASSWORD , ERROR_AUTH, url, dispatch , obj, false ).then((response)=>{
    mailSend = response
  });

  dispatch({
    type : FORGOT_PASSWORD,
    payload : mailSend
  });
};

export async function resetPasswordAction(dispatch , obj){
  let url = "/auth/reset-password/" + obj.token;
  var reset;

  await postData(RESET_PASSWORD , ERROR_AUTH, url, dispatch , obj, false ).then((response)=>{
    reset = response
  });
  
  dispatch({
    type : RESET_PASSWORD,
    payload : reset
  });
  
};