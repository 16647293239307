import React , { useEffect } from 'react';
import { cloneDeep } from 'lodash';

import { useGlobalContext } from '../../../../../context/GlobalContext';
import { getPatientAction } from '../../../../../actions/patientsActions';
import * as d3 from 'd3';
import { truncate } from '../../../../../utils/utils';

export default function FamilyTree() {

  const [ context, dispatch ] = useGlobalContext();

  const { genealogies } = context.dataviz;
  const pathologiesList = context.pathology.pathologiesList;
  const { patient } = context.patient;

  const grey =  '#8C90A1';
  var width = 0;
  var height = 0;
  var rectHeight = 34;
  var rectWidth = 130;

  useEffect(() => {
    if (genealogies) initTree(genealogies);
    // eslint-disable-next-line
  }, [genealogies , patient ]);

  function handleChangeData(data) {
    const initLinks = cloneDeep(data.links);

    let nodes = [];
    let links = [];

    let olders = [];
    olders.push([data.nodes.find(node => node._id === patient._id )]);

    // FIND OLDERS PARENTS

    if (findList(patient._id).parents.length > 0 ) {
      olders.unshift(findList(patient._id).parents);
      let foundParents = true;
      do {
        let parentsList = []
        olders[0].forEach(parent => {
          if (findList(parent._id).parents.length > 0) {
            parentsList = [...parentsList, ...findList(parent._id).parents]
          }
        });
        if (parentsList.length === 0 ) {
          foundParents = false;
        } else {
          olders.unshift(parentsList)
        };
       } while (foundParents);
    }
    
    // FIND YOUNGERS FROM FAMILY

    let youngers = [];
    youngers[0] = olders[0];
    let foundChildren = true;

    do {
      let childrensList = []
      youngers[0].forEach(child => {
        if (findList(child._id).children.length > 0) {
          childrensList = [...childrensList, ...findList(child._id).children];
        }
      });
      if (childrensList.length === 0 ) {
        foundChildren = false;
      } else {
        youngers.unshift([...new Set(childrensList)])
      };

    } while (foundChildren);

    let family = [];
    family[0] = youngers[0];
    let foundChildrenParents = true;

    do {
      let chidrenParents = []
      family[0].forEach(child => {
        if (findList(child._id).parents.length > 0) {
          chidrenParents = [...chidrenParents, ...findList(child._id).parents];
        }
      });

      let chidrenBrothers = [...family[0]]
      family[0].forEach(child => {
        if (findList(child._id).brothers.length > 0) {
          chidrenBrothers = [...chidrenBrothers, ...findList(child._id).brothers];
        }
      });
      if (chidrenParents.length === 0 ) {
        foundChildrenParents = false;
      } else {
        family[0] = [...new Set(chidrenBrothers)]
        family.unshift([...new Set(chidrenParents)])
      };

    } while (foundChildrenParents);

    let complatedFamily = family

    family.forEach((level , index) => {
      level.forEach((p , i ) => {
        let oldersChilds = findList(p._id).children;
        oldersChilds.forEach(o => {
          if (findList(o._id).parents && complatedFamily[index + 1]) {
            complatedFamily[index] = [...new Set([...complatedFamily[index] , ...findList(o._id).parents ])];
          }
          if (findList(o._id).children && complatedFamily[index + 2]) {
            complatedFamily[index + 2] = [...new Set([...complatedFamily[index + 2] , ...findList(o._id).children ])];
          }
        })
      })
    });


    let offsetY = 100;
    let offsetTop = family.length > 1 ? (height  / family.length) - 40 : height / 2 ;

    family.forEach((level , levelIndex) => {
      level.forEach(person => {
        person.offsetY = (offsetY * levelIndex) + offsetTop;
        person.level = levelIndex;
        person.radius = 70
        let color = grey
        if (person.pathology && pathologiesList.find(item => item._id === person.pathology)) {
          color = pathologiesList.find(item => item._id === person.pathology).color
        }
        person.color = color 
        nodes.push(person);
      })
    });
    
    function findList(id) {
      var childList= [];
      initLinks.forEach(item => {
        if (item.target === id) {
          var child = data.nodes.find(node => node._id === item.source )
          if (child) childList.push(child); 
        }
      });
      var parentsList = [];
      initLinks.forEach(item => {
        if (item.source === id ) {
          parentsList.push(data.nodes.find(node => node._id === item.target )); 
        }
      });
      var brothers = [];
      if (parentsList) {
        parentsList.forEach(parent => {
          initLinks.forEach(item => {
            if (item.target === parent._id) {
              var child = data.nodes.find(node => node._id === item.source )
              if (child) brothers.push(child); 
            }
          });
        });
      }

      // console.log(
      //   {
      //     patient: data.nodes.find(node => node._id === id ),
      //     parents: parentsList,
      //     children: childList,
      //     brothers: brothers,
      //   }
      // )
      return {
        patient: data.nodes.find(node => node._id === id ),
        parents: parentsList,
        children: childList,
        brothers: brothers,
      }
    }

    initLinks.forEach(link => {
      let foundSource = nodes.find(node => node._id === link.source );
      let foundTarget = nodes.find(node => node._id === link.target );
      if (foundSource && foundTarget ) {
        let color = grey ;
        if (foundSource && foundTarget && foundSource.pathology && foundTarget.pathology &&  foundSource.pathology === foundTarget.pathology) {
          color = pathologiesList.find(item => item._id === foundTarget.pathology).color
        }
        link.color = color
        links.push(link);
      }
    });
    
    return {
      nodes: [...new Set(nodes)],
      links: links
    }

  };

  const initTree = (dataSet) => {

    if (d3.select('#svg-container')) d3.selectAll('#svg-container').remove();

    const container = document.getElementById('tree');

    width = container.getBoundingClientRect().width;
    height = container.getBoundingClientRect().height;

    const treeData = handleChangeData(dataSet);
    var transform = d3.zoomIdentity;
    const radius = 80;

    var svg = d3.select("#tree").append("svg")
      .attr('id', "svg-container")
      .attr("width", width)
      .attr("height", height)
      .style('cursor' , 'auto')

    var simulation = d3.forceSimulation()
      .force("center", d3.forceCenter(width / 2.5 , height / 2))
      .force("charge", d3.forceManyBody().strength(-50))
      .force("link", d3.forceLink().strength(1).id(function(d) { return d._id; }))
      .force('collision', d3.forceCollide().radius(d => d.radius))
      // .alphaTarget(0.2)
      // .alphaDecay(0.05)
  
    svg
      .call(d3.drag().subject(dragsubject).on("start", dragstarted).on("drag", dragged).on("end",dragended))
      .call(d3.zoom().scaleExtent([1 / 10, 8]).on("zoom", zoomed));

    function dragsubject(event) {
      var i,
      x = transform.invertX(event.x),
      y = transform.invertY(event.y),
      dx,
      dy;

      for (i = treeData.nodes.length - 1; i >= 0; --i) {
        let node = treeData.nodes[i];
        dx = x - node.x;
        dy = y - node.y;

        if (dx * dx + dy * dy < radius * radius) {

          node.x =  transform.applyX(node.x);
          node.y = transform.applyY(node.y);

          return node;
        }
      }
    }

    function dragstarted(event) {
      if (!event.active) simulation.alphaTarget(1).restart();
      event.subject.fx = transform.invertX(event.x);
      event.subject.fy = transform.invertY(event.y);
    }

    function dragged(event) {
      event.subject.fx = transform.invertX(event.x);
      event.subject.fy = transform.invertY(event.y);
    }

    function dragended(event) {
      if (!event.active) simulation.alphaTarget(0);
      event.subject.fx = null;
      event.subject.fy = null;
    }

    function zoomed(event) {
      transform = event.transform;
      g.attr("transform", transform)
    }


    
    simulation.nodes(treeData.nodes)
      .on("tick", simulationUpdate);
    
    simulation.force("link")
      .links(treeData.links).strength(0.5)
    
    var g = svg.append("g")
      .attr("id", "group")
      .attr("class", "group");

    var lineGenerator = d3.line()
      .curve(d3.curveStepAfter);

    var drawPath = (d) => {
      const points = [
        [d.source.x + (rectWidth / 2) , d.source.y + 24 ],
        [d.source.x + (rectWidth / 2) , d.source.y - 35 ],
        [d.target.x + (rectWidth / 2) , d.target.y + 24]
      ]
      return lineGenerator(points)
    }

    var path = g.selectAll("path")
      .data(treeData.links)
      .enter()
      .append("path")
      .attr('fill' , 'transparent')
      .attr('class' , d => d.color !== '#8C90A1' ? "pathology-link" : 'link' )
      .attr('stroke',(d) => d.color )
      .attr('stroke-width' , 2);

    var node = g.selectAll("rect")
        .data(treeData.nodes)
        .enter().append("rect")
        .attr('id' , d => 'node' + d._id )
        .attr('fill', d => d._id === patient._id  ? d3.hsl(d.color).brighter(1) : 'rgba(255, 255, 255, 1)' )
        .attr("height", rectHeight )
        .attr("width", rectWidth )
        .attr('stroke', (d) => d.color )
        .attr('rx' , 3 )
        .attr('ry' , 3 )
        .attr('stroke-width' , 2 )
        .style('cursor' , 'pointer')
        .on('click', ( e, d) => getPatientAction(dispatch , d._id))
        .on('mouseover' , (e, d) => {
          d3.select(`#node${d._id}`)
            .transition()
            .duration(200)
            .attr('fill', d => d3.hsl(d.color).brighter(1));
        })
        .on('mouseout' , (e, d) => {
          d3.select(`#node${d._id}`)
            .transition()
            .duration(200)
            .attr('fill', d => d._id === patient._id  ? d3.hsl(d.color).brighter(1) : 'rgba(255, 255, 255, 1)' );
        });

    var text = g.selectAll("text")
      .data(treeData.nodes)
      .enter()
      .append("text")
      .text( function (d) {return `${truncate(d.label , 10) } ${d.gender === "Homme" ? "H." : " F."}` })
      .attr("font-family", "AnyBody-SemiBold")
      .attr("font-size", "16px")
      .style("text-anchor", "middle")
      .attr("fill", "#192243")
      .style('cursor' , 'pointer')
      .on('click', ( e, d) => getPatientAction(dispatch , d._id))
      .on('mouseover' , (e, d) => {
        d3.select(`#node${d._id}`)
          .transition()
          .duration(200)
          .attr('fill', d => d3.hsl(d.color).brighter(1));
      })
      .on('mouseout' , (e, d) => {
        d3.select(`#node${d._id}`)
          .transition()
          .duration(200)
          .attr('fill', d => d._id === patient._id  ? d3.hsl(d.color).brighter(1) : 'rgba(255, 255, 255, 1)' );
      });

  
  // if (patient._id) {
  //   let group = document.getElementById('group');
  //   var bounds = group.getBoundingClientRect();
  //   var fullWidth = width,
  //       fullHeight = height;

  //   var midX = bounds.x + bounds.width / 2,
  //       midY = bounds.y + bounds.height / 2;
  //   if (width == 0 || height == 0) return; // nothing to fit
  //   var scale =  0.75 / Math.max(width / fullWidth, height / fullHeight);
  //   var translate = [fullWidth / 2 - scale * midX, fullHeight / 2 - scale * midY];
    
  //   console.log(fullHeight , translate , scale)
  //   // console.log(nodeFound, transform);
  //       // g.attr("transform", () => `translate(-${translate[0]},${translate[1]}) scale(${scale})`)
  // }

  function simulationUpdate() {

      treeData.nodes.forEach(d =>  {
        d.y = d.offsetY;
      })

      text
        .attr("x", function(d) { return d.x + (rectWidth / 2); })
        .attr("y", function(d) { return d.y + 24 });
    
      node
        .attr("x", function(d) { return d.x })
        .attr("y", function(d) { return d.y  });

      path
        .attr('d' , (d) => drawPath(d) )

    }

  }

  return (
    <div id="tree">
    </div>
  )
}
