import React, { useState } from 'react';
import Select from 'react-select';

export default function CustomSelect({ defaultValue , styles, options, isMulti, isClearable , handleChange , placeholder }) {

  const [ selected , setSelected ] = useState(defaultValue);

  function onChange(val) {
    setSelected(val);
    handleChange(val);
  }

  // console.log(defaultValue)

  return (
    <>
      <Select
        styles={styles}
        options={options}
        value={selected}
        isClearable={isClearable ? true : false }
        isMulti={isMulti ? true : false }
        placeholder={placeholder ? placeholder : "Séléctionner"}
        onChange={(val) => onChange(val)}
        />
    </>
  )
}
