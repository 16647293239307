import {
  GET_GENEALOGIES,
  GET_NODES_FILTERED,
  DELETE_PATIENT
} from "../actions/types";

const initialState = {
  genealogies: null,
  localistaions: null
};

export default function dataVizReducer(state = initialState, action) {
  switch (action.type) {
    case DELETE_PATIENT: 
      let nodesList = state?.genealogies
      if (nodesList) {
        nodesList.nodes = nodesList?.nodes.filter(n => n._id !== action.payload );
        nodesList.links = nodesList?.links.filter(n => n.source !== action.payload || n.target !== action.payload );
      }
      let localistaionsList = state?.localistaions
      if (localistaionsList) {
        localistaionsList = localistaionsList.filter(n => n._id !== action.payload );
      }
    return {...state , genealogies: nodesList , localistaions: localistaionsList }
    case GET_NODES_FILTERED: 
      return {...state , localistaions: action.payload }
    case GET_GENEALOGIES:
      return {...state , genealogies: action.payload }
    default:
      return {...state};
  }
}