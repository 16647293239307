export const  //AUTH ACTIONS
              IS_AUTH =  'IS_AUTH',
              ERROR_AUTH = 'ERROR_AUTH',
              ERROR_LOGIN = 'ERROR_LOGIN',
              USER_AUTH = 'USER_AUTH',
              FORGOT_PASSWORD = 'FORGOT_PASSWORD',
              RESET_PASSWORD ='RESET_PASSWORD',
              LOGIN = 'LOGIN',
              PUT_USER_AUTH = 'PUT_USER_AUTH',
              MESSAGE_AUTH = 'MESSAGE_AUTH',
              //PATHOLOGY ACTIONS
              MESSAGE_PATHOLOGY = 'MESSAGE_PATHOLOGY',
              ERROR_PATHOLOGY = 'ERROR_PATHOLOGY',
              GET_PATHOLOGIES = 'GET_PATHOLOGIES',
              GET_PATHOLOGY = 'GET_PATHOLOGY',
              PUT_PATHOLOGY = 'PUT_PATHOLOGY',
              //PATIENTS ACTIONS
              MESSAGE_PATIENT = 'MESSAGE_PATIENT',
              ERROR_PATIENT = 'ERROR_PATIENT',
              GET_PATIENTS = 'GET_PATIENTS',
              GET_PATIENT = 'GET_PATIENT',
              PUT_PATIENT = 'PUT_PATIENT',
              POST_PATIENT ='POST_PATIENT',
              DELETE_PATIENT = 'DELETE_PATIENT',
              GET_LIST= 'GET_LIST',
              POST_PATIENT_FILTERED ='POST_PATIENT_FILTERED',
              //DATAVIZ ACTIONS
              GET_GENEALOGIES = 'GET_GENEALOGIES',
              GET_NODES_FILTERED = 'GET_NODES_FILTERED',
              ERROR_DATA_VIZ = 'ERROR_DATA_VIZ';
