import React , { useState , useEffect , useRef } from 'react';
import { hexToRgba } from '../../../utils/utils';
import './yearsPicker.scss'

export default function YearsPicker({ data , yearsFilters , setYearsFilters }) {
  const [ allYears , setAllYears ] = useState();
  const olderYear = useRef();
  useEffect(() => {

    if (data?.length > 0) {
      let years = [];
      olderYear.current = new Date().getFullYear();
      data.forEach(patient => {
        if (new Date(patient[yearsFilters.type]).getFullYear() < olderYear.current) {
          olderYear.current = new Date(patient[yearsFilters.type]).getFullYear();
        }
      });

      do {
        let patientForYear = data.filter(patient => {
          if (new Date(patient[yearsFilters.type]).getFullYear() === olderYear.current) {
            return true
          } else {
            return false
          }
        });
        if (patientForYear.length > 0) {
          years.push({
            year: olderYear.current,
            patients: patientForYear.length
          });
        }
        olderYear.current = olderYear.current + 1

      } while (olderYear.current < new Date().getFullYear());

      setYearsFilters({...yearsFilters ,
        year: years[years.length -1]?.year ,
        years: years.map(item => item.year )
      });
      setAllYears(years)
    }
    // eslint-disable-next-line
  }, [data , yearsFilters.type ]);

  const Year = ({year ,  patientForYear }) => {

    const [labelisActive , setLabelIsActive ] = useState(false);

    useEffect(() => {
      if (yearsFilters.year  === year ) setLabelIsActive(true);
      // eslint-disable-next-line
    },[yearsFilters]);

    function setDisplayLabel(bool) {
      if (yearsFilters.year !== year) {
        setLabelIsActive(!bool);
      }
    }

    return (
      <div className="year"
        onClick={() => setYearsFilters({...yearsFilters , year: year })}
        onMouseOver={() => setDisplayLabel(labelisActive)}
        onMouseOut={() => setDisplayLabel(labelisActive)}>
        <div
          className="year-bar"
          style={{
            width: (patientForYear + 1) * 10 ,
            borderTop: `2px solid ${yearsFilters.year === year ? "#192243" : hexToRgba("#192243" , 0.3 )}`,
          }}>
          <div className="year-label bold"
            style={{
              color : `${yearsFilters.year === year ? "#192243" : hexToRgba("#192243" , 0.3 )}`,
              opacity: labelisActive? 1 : 0 ,
              transform: `translate(${labelisActive ? 10 + ((patientForYear + 1) * 10 ) : "0" }px , -16px)`
            }}
            >
            {year}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="years-picker">
      <div className="years">
        {allYears && allYears.map((item, i) => <Year key={'year' + i } year={item.year} patientForYear={item.patients} /> )}
      </div>
      <button className="type-toggle bold"
        onClick={() => setYearsFilters({...yearsFilters , type: yearsFilters.type === "birthDate" ? "diagnosticDate" : "birthDate"})}
        >
        <span className="type">{yearsFilters.type === "birthDate" ? "Date de naissance" : "Date de diagnostic"}</span><br/>
        <span className="not-active">{yearsFilters.type !== "birthDate" ? "Date de naissance" : "Date de diagnostic"}</span>
      </button>
    </div>
  )
}
