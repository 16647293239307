import React , { useState , useRef , useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import * as d3 from "d3";

import { useWindowSize } from '../../../../../utils/hooks/useWindowDimensions';
import { getPatientAction } from '../../../../../actions/patientsActions';
import { useGlobalContext } from '../../../../../context/GlobalContext';
import Tooltip from '../../../../partials/Tooltip/Tooltip';
import { hexToRgba } from '../../../../../utils/utils';

export default function TimeViz({ data , panelIsOpen , setYearsFilters , yearsFilters }) {

  const timeVizRef = useRef(null);
  const windowSize = useWindowSize();
  const dispatch = useGlobalContext()[1]

  const activeYear = useSpring({ number: yearsFilters.year , from: { number: yearsFilters?.years[0] || 0 } });

  let radius = 12;
  let dpr = window.devicePixelRatio || 1;
  let height;
  let width;
  let timelineCanvas;
  let simulation;
  let closeNode;

  const [ tooltipData , setTooltipData ] = useState({ x: windowSize[0] / 2 , y: windowSize[1] / 2 });

  useEffect(() => {
    let currentTimeViz = timeVizRef.current;
    return () => {
      d3.select(currentTimeViz).select('canvas').remove();
    }
    // eslint-disable-next-line
  },[]);

  useEffect(() => {
    if (data && data.length) {
      initTimelineCanvas(data)
    }
    // eslint-disable-next-line
  },[data , panelIsOpen , windowSize]);

  function changeYearOnWheel(e) {
    var index = yearsFilters.years.indexOf(yearsFilters.year);
    var nextYear = yearsFilters.years[index]
    if (e.deltaY > 4 ) {
      index++;
      if (index === yearsFilters.years.length ) {
        index = 0;
      }
      nextYear = yearsFilters.years[index]
      setYearsFilters({...yearsFilters , year: nextYear });
    }
    if (e.deltaY < -4 ) {
      index--;
      if (index === 0 ) {
        index = yearsFilters.years.length - 1;
      }
      nextYear = yearsFilters.years[index]
      setYearsFilters({...yearsFilters , year: nextYear });
    }

  }  


  function initTimelineCanvas(dataSet) {

    let transform = d3.zoomIdentity;

    var updatedData = dataSet
    
    height = timeVizRef.current.getBoundingClientRect().height * dpr;
    width =  timeVizRef.current.getBoundingClientRect().width * dpr;

    if (d3.select("#timeline-canvas")) d3.select("#timeline-canvas").remove();

    timelineCanvas = d3.select(timeVizRef.current).append('canvas')
    .classed('timeline-canvas', true)
    .attr('id' , "timeline-canvas")
    .attr('width', width + 'px')
    .attr('height', height + 'px')
    .node();

    let context = timelineCanvas.getContext('2d');

    simulation = d3.forceSimulation()

    simulation
      .force("center", d3.forceCenter( width / (2 * dpr) , height / (2 * dpr) ))
      .force("x", d3.forceX(width / (2 * dpr)).strength(1.2))
      .force("y", d3.forceY(height / (2 * dpr)).strength(1.2))
      .force("charge", d3.forceManyBody().strength(-40))
      .force('collision', d3.forceCollide().radius(radius * 1.5))
      .alphaTarget(0)
      .alphaDecay(0.05)
      
    simulation.nodes(updatedData)
      .on("tick", () => window.requestAnimationFrame(drawCanvas));

    d3.select(timelineCanvas)
        .on("click", function(event){
          closeNode = foundNode(event);
          if (closeNode?._id) {
            getPatientAction(dispatch , closeNode._id);
            drawCanvas();
          }
        })
        .on("mousemove", function(event){
          event.preventDefault();
          event.stopPropagation();
          closeNode = foundNode(event) ? foundNode(event) : null ;
          if (closeNode) {
            setTooltipData({...closeNode, x: event.offsetX  - 70 , y: event.offsetY - 80 });
          } else {
            setTooltipData({ x: event.offsetX  - 70 , y: event.offsetY - 120 });
          }
          drawCanvas();
        })
        .on("wheel.zoom", changeYearOnWheel )
        .call(d3.drag().subject(dragsubject).on("start", dragstarted).on("drag", dragged).on("end",dragended))
        // .call(d3.zoom().scaleExtent([1 / 10, 8]).on("zoom", zoomed));

    function foundNode(event) {
      let posX = event.offsetX 
      let posY = event.offsetY
      return simulation.find( posX , posY , radius );
    }
    
    function dragsubject(event) {
      var i,
      x = transform.invertX(event.x),
      y = transform.invertY(event.y),
      dx,
      dy;

      for (i = updatedData.length - 1; i >= 0; --i) {
        let node = updatedData[i];
        dx = x - node.x;
        dy = y - node.y;

        if (dx * dx + dy * dy < radius * radius) {

          node.x =  transform.applyX(node.x);
          node.y = transform.applyY(node.y);

          return node;
        }
      }
    }

    function dragstarted(event) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      event.subject.fx = transform.invertX(event.x);
      event.subject.fy = transform.invertY(event.y);
    }

    function dragged(event) {
      event.subject.fx = transform.invertX(event.x);
      event.subject.fy = transform.invertY(event.y);
    }

    function dragended(event) {
      if (!event.active) simulation.alphaTarget(0);
      event.subject.fx = null;
      event.subject.fy = null;
    }

    function drawCanvas(){

      context.save();
      context.clearRect(0, 0, width, height);
      context.scale(dpr , dpr);
  
      updatedData.forEach(function(d, i) {
        context.beginPath();
        context.arc(d.x , d.y, radius, 0, 2 * Math.PI, true);
        context.fillStyle = d.color;
        context.strokeStyle = "rgba(0, 0, 0, 1)";
        context.fill();
      });
  
      if (closeNode) {
        d3.select('.timeline-graph').style('cursor' , 'pointer');
        context.beginPath();
        context.arc(closeNode.x, closeNode.y, radius + 2, 0, 2 * Math.PI, true);
        context.strokeStyle = hexToRgba(closeNode.color , 0.4);
        context.lineWidth = 6;
        context.stroke();
      } else {
        d3.select('.timeline-graph').style('cursor' , 'auto');
      }
  
      context.restore();
  
    }
  

  }



  return (
    <div ref={timeVizRef} className="timeline-graph" >
      <h1 className={panelIsOpen ? "panelIsOpen": ""}><animated.span>{activeYear.number.interpolate(x => x.toFixed(0))}</animated.span></h1>
      <Tooltip data={tooltipData} type={"genealogy"}/>
    </div>
  )
}
