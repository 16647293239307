import React , { useState , useEffect } from 'react';
import DatePicker from '../../../../partials/DatePicker/DatePicker';
import CheckBox from '../../../../partials/Form/CheckBox/CheckBox';
import Select from '../../../../partials/Select/Select';
import { formSelect } from '../../../../partials/Select/styles';

export default function Inhibitor({ index , data  , color , handleChange , protocolsOptions }) {

  const [ inhibitor , setInhibitor ] = useState(data);

  useEffect(() => {
    if (inhibitor !== data && Object.keys(inhibitor).length !== 0) handleChange({ data: inhibitor , index: index });
    // eslint-disable-next-line
  }, [inhibitor])

  return (
    <div className="patient-infos">
      <div className="col-2">

        <div className="form-group">
          <p className="form-label">
            <label htmlFor="title">Titre</label>
          </p>
          <div className="form-content">
            <input
              type="text"
              value={inhibitor.title ? inhibitor.title : ''}
              onChange={(e) => setInhibitor({...inhibitor , title: e.target.value })} />
          </div>
        </div>

        <div className="form-group">
          <p className="form-label">
            <label htmlFor="protocol">Protocole</label>
          </p>
          <div className="form-content">
            <Select
              styles={formSelect}
              defaultValue={inhibitor.protocol ? protocolsOptions.find(item => item.value === inhibitor.protocol) : protocolsOptions[0] }
              handleChange={(opt) => setInhibitor({...inhibitor , protocol: opt.value })}
              options={protocolsOptions}
              />
          </div>
        </div>

        <div className="form-group">
          <CheckBox
            defaultValue={inhibitor.iti ? true : false}
            onChange={(value) => setInhibitor({...inhibitor , iti: value })}
            label={"iti"}/>
        </div>

        <button
          type="button"
          style={{ backgroundColor: color , margin: 0 }}
          onClick={() => handleChange({ data: null , index: index })}
          className="btn-primary">
          supprimer l'inhibiteur
        </button>

      </div>
      <div className="border"></div>
      <div className="col-2">

        <div className="form-group">
          <p className="form-label">
            <label htmlFor="apparitionDate">Jour d’apparition</label>
          </p>
          <div className="form-content">
            <DatePicker
              date={inhibitor.apparitionDate ? inhibitor.apparitionDate : new Date() }
              handleChange={(value) => setInhibitor({...inhibitor , apparitionDate: value })} />
          </div>
        </div>

        <div className="form-group">
          <p className="form-label">
            <label htmlFor="initiationDate">Date d’initiation</label>
          </p>
          <div className="form-content">
            <DatePicker
              date={inhibitor.initiationDate ? inhibitor.initiationDate : new Date() }
              handleChange={(value) => setInhibitor({...inhibitor , initiationDate: value })} />
          </div>
        </div>

        <div className="form-group">
          <p className="form-label">
            <label htmlFor="stopDate">Date d’arrêt</label>
          </p>
          <div className="form-content">
            <DatePicker
              date={inhibitor.stopDate ? inhibitor.stopDate: new Date() }
              handleChange={(value) => setInhibitor({...inhibitor , stopDate: value })} />
          </div>
        </div>

      </div>


    </div>
  )
}
