import { getData , postData } from './index';

import {
  GET_GENEALOGIES,
  GET_PATIENTS,
  GET_NODES_FILTERED,
  ERROR_DATA_VIZ
} from "./types"


export async function getGenealogiesAction(dispatch) {
  let url = "/dataviz/genealoviz/patient";
  var genealogies;

  await getData(GET_GENEALOGIES, url, dispatch , true ).then((response)=>{
    genealogies = response
  });

  dispatch({
    type : GET_PATIENTS,
    payload : genealogies.nodes.map(item => {
      item.code = item.label
      return item;
     })
  });
  
  dispatch({
    type : GET_GENEALOGIES,
    payload : genealogies
  });

};

export async function getNodesFiltredAction(dispatch , data ) {
  let url = `/dataviz/genealoviz/node`;
  var res;

  await postData(GET_NODES_FILTERED, ERROR_DATA_VIZ ,  url , dispatch, data , true ).then((response)=>{
    res = response
  });

  dispatch({
    type : GET_NODES_FILTERED,
    payload : res.nodes.map(item => {
      item.code = item.label
      return item;
     }),
  });
};


// function getDataSet() {
//   var fakeData = genRandomTree(2000);

//   fakeData.links.forEach((link , i ) => {
//     const a = fakeData.nodes[link.source];
//     const b = fakeData.nodes[link.target];
//     !a.neighbors && (a.neighbors = []);
//     !b.neighbors && (b.neighbors = []);
//     a.neighbors.push(b);
//     b.neighbors.push(a);

//     !a.links && (a.links = []);
//     !b.links && (b.links = []);
//     a.links.push(link);
//     b.links.push(link);
    
//     a.virus = i % 2 ? "virus 1" : "virus 2"
//     b.virus = i % 2 ? "virus 1" : "virus 2" 
//   });

//   return fakeData
// }

// function genRandomTree(N = 300, reverse = false) {
//   return {
//     nodes: [...Array(N).keys()].map(i => ({ _id: i })),
//     links: [...Array(N).keys()]
//     .filter(id => id)
//     .map(id => ({
//       [reverse ? 'target' : 'source']: id,
//       [reverse ? 'source' : 'target']: Math.round(Math.random() * (id-1))
//     }))
//   };
// }