import React , { useCallback , useEffect } from 'react';
import { Link } from 'react-router-dom';

import styles from './Legal.module.scss';

export default function Legal({ setActivePage }) {

  const initData = useCallback(() => {
    setActivePage("legal");
  }, [setActivePage]);

  useEffect(() => {
    initData();
  }, [initData]);

  return (
    <div className={`page-container ${styles.container}`}>
      <Link to="/login">
        <button className={`btn primary ${styles.btn}`}>
          Retour
        </button>
      </Link>
      <div className={styles.content}>
        <h1>CONDITIONS GENERALES D’UTILISATION <br/>SERVICE GENEALOVIZ – PHASE PILOTE</h1>

        <h3>1. Editeur de la plateforme</h3>

        <p>
          La Plateforme « OCTASMART » (ci-après   désignée   « Plateforme ») est éditée   par   la   société OCTAPHARMA France , S.A.S dont le siège social est situé au 62 bis, André Morizet 92100 Boulogne Billancourt]  et  immatriculée  au  Registre  du  Commerce  et  des  Société  de  Paris  sous  le  numéro 45132730800024  (ci-après  désignée  « la  Société »  ou  « OCTAPHARMA »),  représentée  par  Marie-Christine Borrelly.
        </p>
        <p>
          Directeur de la publication :  Marie-Christine Borrelly, Directeur Général
        </p>
        <p>
          Responsable de la rédaction : Marie-Christine Borrelly, Directeur Général
        </p>
        <p>
          Téléphone : 0141318000
        </p>

        <h3>2. Hébergeur de données de santé</h3>

        <p>
          Les  données  collectées  et  traitées  dans  le  cadre  du  Service  GENEALOVIZ  sont  hébergées  par  AZ NETWORK SAS , hébergeur agréé/certifié de données de santé au sens des dispositions de l’article L. 1111-8 du Code de la santé publique, dont le siège social est situé au 40 rue Ampère 61000 Alençon et qui est immatriculée au Registre du Commerce et des Sociétés sous le numéro B 423 880 061.  
        </p>


        <h3>3. Définitions</h3>

        <p>
          Les  termes  ci-après  définis  ont  le  sens  et  la  portée  donnés  dans  leur  définition  dans  le  cadre  de  la conclusion et l’exécution des présentes Conditions Générales d’Utilisation du Service.
        </p>

        <p>
          <span className={styles.bold}>« Compte Personnel » </span>désigne le compte créé par l’Utilisateur Médecin lors de sa première connexion à la Plateforme lui permettant d’accéder et d’utiliser les Services proposés au sein de la Plateforme de manière sécurisée ;
        </p>

        <p>
          <span className={styles.bold}>« Conditions  Générales  d’Utilisation »  ou  « CGU »</span> désigne  les  différentes  conditions  générales d’utilisation  destinées  à  encadrer  contractuellement  l’utilisation  de  la  plateforme  OCTASMART  ainsi que  tous  les  Services  qui  y  sont  accessibles.  L’Utilisateur  Médecin  reconnait  que  chaque  Service accessible  à  partir  de  la  Plateforme  OCTASMART  est  encadré  par  ses  propres  CGU.  Les  présentes Conditions Générales d’Utilisation ont pour objet d’encadrer contractuellement l’utilisation du service GENEALOVIZ par les Utilisateurs Médecins ;
        </p>

        <p>
          <span className={styles.bold}>« Contenu » </span> désigne l’ensemble des textes, photographies, vidéos, illustrations, fonctionnalités et plus largement tout élément existant au sein de la Plateforme et des Services publiés par OCTAPHARMA ; 
        </p>

        <p>
          <span className={styles.bold}>« Contrat d’expertise » </span> désigne   le   contrat   conclu   entre   l’Utilisateur   Médecin   et   la   société OCTAPHARMA  encadrant  la  mise  à  disposition  de  l’Utilisateur  Médecin  du  Service  GENEALOVIZ par OCTAPHARMA à des fins de test du dit Service.
        </p>

        <p>
          <span className={styles.bold}>« Documents contractuels » </span>désigne les CGU de la Plateforme OCTASMART, acceptées par l’Utilisateur Médecin lors de sa première connexion à la Plateforme, la Politique de confidentialité de la Plateforme ainsi  que,  s’agissant  du  Service  GENEALOVIZ  (i)  le  Contrat  d’expertise  et  (ii)  les  présentes  CGU, acceptées   par   l’Utilisateur   Médecin   lors   de   sa   première   connexion   au   Service   GENEALOVIZ. L’Utilisateur Médecin reconnait que chaque Service accessible à partir de la Plateforme OCTASMART est encadré par ses propres CGU ; 
        </p>

        <p>
          <span className={styles.bold}>« Plateforme »   ou   « Plateforme   OCTASMART » </span> désigne   les   applicatifs   conçus,   développés   et maintenus par OCTAPHARMA, destinés à permettre aux Utilisateur Médecins d’accéder et d’utiliser le Service GENEALOVIZ ;
        </p>

        <p>
          <span className={styles.bold}>« Réglementation »</span> désigne conjointement la Loi n° 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés modifiée (dite « Loi Informatique et Libertés ») et le Règlement UE 2016/679 relatif  à  la  protection  des  personnes  physiques  à  l’égard  du  traitement  des  données  à  caractère personnel et à la libre circulation de ces données du 27 avril 2016 (dit « RGPD ») ; 
        </p>

        <p>
          <span className={styles.bold}>« Services »</span> désigne  l’ensemble  des  Services  et  Contenus  fournis  par  OCTAPHARMA  accessibles  à partir de la Plateforme et incluant le Service GENEALOVIZ ; chaque Service est soumis à ses propres conditions générales d’utilisation ; 
        </p>

        <p>
          <span className={styles.bold}>« Service GENEALOVIZ » ou « Service » </span> désigne l’outil de visualisation des données de patients atteints d’hémophilie destiné aux Utilisateurs Médecins dans leur pratique médicale à des fins de test pendant la phase pilote ; Le Service GENEALOVIZ n’est pas un dispositif médical et ne permet pas la réalisation d’acte de télémédecine par l’Utilisateur Médecin.
        </p>

        <p>
          <span className={styles.bold}>« Structure de soins » </span> désigne l’établissement de santé au sein duquel l’Utilisateur Médecin exerce  et qui intervient en qualité de responsable de traitement des données des patients traitées dans le cadre du Service GENEALOVIZ ;
        </p>

        <p>
          <span className={styles.bold}>« Utilisateur Médecin » </span>désigne la personne physique exerçant la profession de médecin, titulaire d’un diplôme visé à l’article L. 4131-1 du Code de la santé publique, et dont l’accès à la Plateforme et au Service   GENEALOVIZ   est   subordonné   à   la   conclusion   d’un   Contrat   d’expertise   bénévole   avec OCTAPHARMA, à des fins de test du Service GENEALOVIZ pendant la phase pilote. En utilisant le Service GENEALOVIZ,  l’Utilisateur  Médecin  déclare  en  informer  sa  hiérarchie  au  sein  de  l’Etablissement  de santé où il exerce et notamment l’accord sur la protection des données conformément à l’article 28 du RGPD annexé aux présentes CGU.
        </p>
        
        <p>
          Sauf indication contraire, dans les présentes Conditions Générales d’Utilisation :
        </p>

        <ul>
          <li>Les mots au singulier incluent le pluriel et inversement ;</li>
          <li>Les mots au féminin incluent le masculin et inversement ;</li>
          <li>Une référence à un document, une norme, une disposition législative, une disposition réglementaire, code ou tout  autre  document  implique  toute modification  ou  mise à jour  de  ce  document,  norme,  disposition  législative,  disposition  réglementaire  ou code.</li>
        </ul>

        <h3>4. Documents contractuels</h3>

        <p>
          Chaque  Utilisateur  Médecin  a  conclu  avec  OCTAPHARMA  un  Contrat  d’expertise  encadrant  les conditions de mise à disposition du Service GENEALOVIZ dans le cadre de la phase pilote à des fins de test dudit Service. 
        </p>

        <p>
          En outre, chaque Utilisateur Médecin souscrit aux présentes Conditions Générales d’Utilisation de la Plateforme   et   aux   Conditions   Générales   d’Utilisation   du   Service   avant   d’accéder   au   Service GENEALOVIZ par le biais de cases à cocher.
        </p>

        <p>
          Chaque  Utilisateur  Médecin  reconnait  avoir  pris  connaissance  de  la  Politique  de  confidentialité <a href="https://octasmart.octapharma.bakasable.fr/data-policy" rel="noopener noreferrer" target="_blank">https://octasmart.octapharma.bakasable.fr/data-policy</a> et être informé du traitement de ses données à caractère personnel par OCTAPHARMA.
        </p>

        <p>
          Le Contrat d’expertise, les CGU de la Plateforme, la Politique de confidentialité et les CGU du Service forment ensemble les Documents contractuels.
        </p>

        <p>
          L’invalidité d’une disposition des présentes CGU ainsi jugée par un tribunal compétent n’en affecterait pas pour autant la validité des autres dispositions des présentes. 
        </p>

        <p>
          Toute renonciation à l’une des dispositions des CGU ou à d’autres dispositions des présentes ne peut valoir renonciation définitive à l’ensemble des CGU.
        </p>

        <h3>5. Objet</h3>

        <p>
          Les présentes CGU sont destinées à définir les conditions de mise à disposition du Service GENEALOVIZ pendant  la  phase  pilote,  et  notamment  d’encadrer  et  définir  les  règles  d’utilisation  du  Service GENEALOVIZ accessible via la Plateforme, ainsi que les droits et obligations des Utilisateurs Médecins.
        </p>


        <h3>6. Entrée en vigueur et durée des conditions générales</h3>

        <p>
          L’Utilisateur Médecin est invité à lire attentivement les CGU et dispose de la possibilité de les consulter à tout moment au sein de la Plateforme. 
        </p>

        <p>
          L’Utilisateur   Médecin   accepte   les   présentes   CGU   lors   de   sa   première   connexion   au   Service GENEALOVIZ.
        </p>

        <p>
          De manière générale, l’Utilisateur Médecin reconnait que l’expression de son consentement aux CGU est matérialisée par l’activation d’une case à cocher associée à une convention de preuve définie aux présentes CGU.
        </p>

        <p>
          Le Service ne peut être fournit qu’après acceptation des CGU.
        </p>

        <p>
          L’Utilisateur Médecin est informé que les présentes CGU entrent en vigueur après approbation de sa demande par OCTAPHARMA qui se chargera de lui donner accès au Service GENEALOVIZ une fois les formalités de soumission de la Convention de partenariat effectuées auprès de l’autorité compétente dans le respect des dispositions de la Loi anti-cadeaux.
        </p>

        <h3>7. Conditions d'accès et d'utilisation pendant la phase pilote</h3>

        <p>
          Le  Service  GENEALOVIZ  est  exclusivement  réservé  aux  Utilisateurs  Médecins  ayant  conclu  avec OCTAPHARMA un contrat d’expertise. 
        </p>

        <p>
          L'accès et l’utilisation du Service GENEALOVIZ par tout Utilisateur Médecin sont soumis aux présentes CGU,  aux  lois  en  vigueur  concernant  les  services  de  communication  électronique  au  public,  et  plus généralement au respect de toutes les lois applicables.
        </p>

        <p>
          Les  Utilisateurs  Médecins  ne  peuvent  utiliser  le  Service  GENEALOVIZ  fournis  dans  le  cadre  de  la Plateforme  à  d’autres  fins  que  pour  la  destination  définie  aux  présentes  CGU,  et  notamment, s’interdisent d’utiliser le Service, à toute fin publicitaire ou promotionnelle.
        </p>

        <p>
          Toute autre utilisation en fraude des droits de la Société expose l’Utilisateur Médecin à des poursuites.
        </p>

        <p>
          L’Utilisateur  Médecin  reconnaît  qu’il  accède  à  un  Service  mis  à  disposition  sur  une  Plateforme  aux modalités d’accès sécurisées. Il s’engage à ne pas communiquer ses éléments d’authentification à des tiers.
        </p>

        <p>
          Il reconnaît que l’utilisation du Service ne saurait l’exonérer de son obligation de prendre les décisions concernant le patient dans le strict respect des règles relatives à l’exercice de sa profession.
        </p>

        <p>
          L’Utilisateur Médecin reconnaît prendre les décisions relatives à ses patients conformément aux règles professionnelles et déontologiques de sa profession.
        </p>

        <p>
          L’Utilisateur Médecin reconnaît et accepte que l’intégralité des frais de connexion au réseau internet reste à sa charge exclusive. 
        </p>

        <p>
          L’Utilisateur Médecin s’engage à utiliser le Service en bon père de famille au sens des dispositions du code  civil  mais  également  de  la  jurisprudence  actuelle.  Toute  utilisation  manifestement  abusive  du Service  entraînera  la  possibilité  pour  OCTAPHARMA  de  limiter  ou  de  suspendre  tout  ou  partie  du Service.
        </p>

        <p>
          De   façon   générale,   toute   utilisation   non   conforme   aux   CGU   entraînera   la   possibilité   pour OCTAPHARMA de suspendre l’accès au Service.
        </p>

        <h4>7.1 Configuration requise</h4>

        <p>
          L’Utilisateur  Médecin  est  informé  que  l’utilisation  du  Service  nécessite  de  disposer  de  certaines configurations ci-après définies : une connexion internet suffisamment rapide et un ordinateur. 
        </p>

        <h4>7.2 Création du compte personnel</h4>

        <p>
          L’Utilisateur  Médecin  est  informé  que  l’accès  au  Service  GENEALOVIZ  est  subordonné  à  la  création d’un Compte Personnel sur la Plateforme selon les modalités décrites au sein de l’article 7.2 des CGU de la Plateforme accessible via le lien hypertexte suivant : 
          <br/>
          <a href="https://octasmart.octapharma.bakasable.fr/cgu" rel="noopener noreferrer" target="_blank">https://octasmart.octapharma.bakasable.fr/cgu</a>.
        </p>

        <h4>7.3 Connexion au compte personnel et accès au service GENEALOVIZ</h4>

        <p>
          L’Utilisateur Médecin est informé que l’accès au Service GENEALOVIZ nécessite qu’il se soit connecté préalablement à son Compte Personnel sur la Plateforme.
        </p>

        <p>
          Une fois connecté à son Compte Personnel, l’Utilisateur Médecin pourra accéder aux Contenus de la Plateforme ainsi qu’aux Services auxquels il aura obtenu l’accès auprès des équipes d’OCTAPHARMA.
        </p>

        <p>
          Pour  accéder  au  Service  GENEALOVIZ,  l’Utilisateur  Médecin  devra  saisir  une  nouvelle  fois  son identifiant et son mot de passe.
        </p>

        <p>
          L’Utilisateur Médecin reconnait qu’il est seul responsable de l'usage qu’il fait de son mot de passe et identifiant.
        </p>

        <p>
          L'identification  et  l’authentification  de  l’Utilisateur  Médecin  au  moyen  du  mot  de  passe  et  du  login (son  adresse  email)  à  l’occasion  de  l’accès  à  son  Compte  Personnel  vaut  de  manière  irréfragable imputabilité  à  l’Utilisateur  Médecin  des  opérations  effectuées  une  fois  connecté  à  son  Compte Personnel  au  moyen  de  ce  mot  de  passe  et  de  ce  login,  dans  les  conditions  définies  à  l’article « Convention de preuve » des présentes CGU.
        </p>

        <p>
          Autrement dit, toute action réalisée par l’Utilisateur Médecin via son Compte Personnel, à l’occasion de  son  utilisation  du  Service,  à  partir  de  ses  éléments  d’authentification  sera  réputée  avoir  été effectuée par l’Utilisateur Médecin et sous sa responsabilité exclusive.
        </p>

        <p>
          A ce  titre,  l’Utilisateur  Médecin  s’engage  à  garder  secrets  ses  éléments  d’authentification  étant entendu que OCTAPHARMA ne pourra être tenue pour responsable de toute perte ou tout dommage survenant en cas de manquement à cette obligation, toute utilisation des éléments précités étant faite sous l’entière responsabilité de l’Utilisateur Médecin.
        </p>

        <p>
          En cas de perte ou de vol de ses éléments d’authentification, ou encore de suspicion de leur utilisation par  un  tiers  non  autorisé,  l’Utilisateur  Médecin  s'engage  à  en  informer  sans  délai  OCTAPHARMA,  à l’adresse mail suivante : fr2dpo@octapharma.com
        </p>

        <p>
          L'opposition notifiée par l’Utilisateur Médecin en cas de perte ou de vol ou de suspicion d’utilisation par un tiers non autorisé de ses éléments d’authentification prendra effet immédiatement à compter de cette notification.
        </p>

        <p>
          Les    éléments    d’authentification    de    l’Utilisateur    Médecin    existants    seront    désactivés    par OCTAPHARMA, dans les meilleurs délais et de nouveaux éléments d’authentification provisoires seront adressés à l’Utilisateur Médecin. L’Utilisateur Médecin devra alors modifier son mot de passe dès la connexion suivante.
        </p>

        <p>
          Conformément aux recommandations de la CNIL, un dispositif technique incite l’Utilisateur Médecin à changer périodiquement de mot de passe, tous les 12 mois.
        </p>

        <p>
          Si l’Utilisateur Médecin oublie son mot de passe, il peut cliquer sur " Mot de passe oublié ?". Après avoir   renseigné   son   adresse   e-mail,   l’Utilisateur   Médecin   recevra   directement   par   courrier électronique un lien pour réinitialiser son mot de passe. 
        </p>

        <div className={styles.bloc}>
          <h4>Mise en garde :</h4>

          <p>
            L’UTILISATEUR  MÉDECIN  S’ENGAGE  À  SAISIR  ET  DÉCLARER  LES  DONNÉES  QUI  PERMETTENT  SON  IDENTIFICATION  SOUS  SES  ENTIÈRES RESPONSABILITÉ, CONTRÔLE ET DIRECTION ET S’ENGAGE À COMMUNIQUER DES INFORMATIONS COMPLÈTES, EXACTES ET ACTUALISÉES,ET À NE PAS USURPER L’IDENTITÉ D’UN TIERS.
          </p>

          <p>
            DANS L’HYPOTHÈSE   OÙ   L’UTILISATEUR  MÉDECIN   COMMUNIQUERAIT   DES   INFORMATIONS   FAUSSES,   INEXACTES,   TROMPEUSES,INCOMPLÈTES  OU  PÉRIMÉES, OCTAPHARMA  SE  RÉSERVE  LA  POSSIBILITÉ  DE  SUSPENDRE  OU  REFUSER  L’ACCÈS,  SANS  PRÉAVIS  NI INDEMNITÉ, DE FAÇON TEMPORAIRE OU DÉFINITIVE, À TOUT OU PARTIE DE LA PLATEFORME, ET DE SES SERVICES.EN OUTRE, DANS DE TELS CAS,OCTAPHARMA DÉCLINE TOUTE RESPONSABILITÉ DU FAIT DE L’UTILISATION DE LA PLATEFORME, ET DE SES SERVICES.
          </p>

        </div>

        <h3>8. Présentation du service GENEALOVIZ dans la phase pilote</h3>

        <p>
          Le Service GENEALOVIZ est un service permettant aux Utilisateurs Médecins de visualiser les données de leurs propres patients – qu’ils auront préalablement saisies sous leur entier contrôle, direction et responsabilité – dans le cadre de leur pratique médicale.
        </p>

        <p className={`${styles.bold} ${styles.underline}`}>
          L’Utilisateur  Médecin  reconnait  que  le  Service  GENEALOVIZ  n’est  pas  destiné  à  lui  permettre  de suivre ses patients en dehors de la phase pilote.
        </p>

        <p className={`${styles.bold} ${styles.underline}`}>
          L’Utilisateur Médecin garantit qu’il n’utilisera le Service GENEALOVIZ ainsi mis à sa disposition qu’à des fins de test dans le cadre de la phase pilote et dans le respect du Contrat d’expertise conclu avec OCTAPHARMA à titre bénévole.
        </p>

        <p className={styles.bold}>
          L’Utilisateur  Médecin  garantit  avoir  recueilli  le  consentement  auprès  du  patient  avant  la  collecte  et  le traitement  des  données  de  ses  patients  y  compris  au  sein  de  l’outil  digital  de  visualisation  des  données dénommé « GENEALOVIZ ». (Note d’information).
        </p>

        <p>
          Le Service est composé de trois modules :
        </p>

        <ul>
          <li><span className={styles.bold}>Un  module  maladie</span> qui  contient  le  descriptif  de  la  pathologie,  les  signes  cliniques,  que l’Utilisateur Médecin ajoute et les mutations éventuelles.</li>
          <li><span className={styles.bold}>Un module patient</span> qui contient des données concernant le patient, à savoir, notamment :
            <ul>
              <li>Sa date de naissance ;</li>
              <li>Son diagnostic ;</li>
              <li>Zone géographique (code postal) ; </li>
              <li>Son score hémorragique, qui devra être reporté manuellement ;</li>
              <li>La présence ou non d’inhibiteurs et le protocole utilisé le cas échéant ;</li>
              <li>a mise en place d’ITI ;</li>
              <li>Les dosages biologiques ;</li>
              <li>Les antécédents médicaux et personnels ; </li>
              <li>Les signes cliniques ;</li>
              <li>La généalogie.</li>
            </ul>
          </li>
          <li><span className={styles.bold}>Un module visualisation</span> qui permet à l’Utilisateur Médecin de visualiser les données de ses patients sous trois points de vus différents :
            <ul>
              <li>une vue généalogique, </li>
              <li>une vue géographique, </li>
              <li>une vue historique/chronologique. </li>
            </ul>
          </li>
        </ul>

        <p>
          L’Utilisateur  Médecin  est  informé  que  le  Service  GENEALOVIZ  ne  lui  permet  en  aucun  cas  d’accéder directement ou indirectement aux données d’autres patients qui ne seraient pas les siens.
        </p>

        <p>
          Le  Service  GENEALOVIZ  est  destiné  uniquement  à  l’Utilisateur  Médecin  qui  déclare  l’utiliser  dans  le champ  de  ses  obligations  légales,  professionnelles  et  déontologiques  pour  les  besoins  de  sa  propre patientèle et de sa pratique médicale exclusivement à des fins de test dans le cadre de la phase pilote conformément au contrat d’expertise conclu bénévolement avec OCTAPHARMA.
        </p>

        <p>
          L’Utilisateur  Médecin  est  informé  que  le  service  GENEALOVIZ  <span className={styles.bold}>n’est  pas  un  dispositif  médical</span> et  ne contient  aucune  fonctionnalité  qui  relèverait  de  la  qualification  de  dispositif  médical  au  sens  des dispositions du règlement européen sur les dispositifs médicaux et des dispositions du code de la santé publique.
        </p>

        <p>
          L’Utilisateur  Médecin  reconnait  que  le  Service  GENEALOVIZ  <span className={styles.bold}>n’est  pas  non  plus  un  service  de télémédecine</span> au  sens  des  dispositions  du  Code  de  la  santé  publique :    le  Service  GENEALOVIZ  ne permet  donc  pas  à  l’Utilisateur  Médecin  de  suivre  ses  patients  à  distance  et/ou  de  prendre  des décisions médicales à distance.
        </p>

        <p>
          L’Utilisateur  Médecin  qui  souscrit  au  Service  GENEALOVIZ  déclare  et  garantit  être  titulaire  d’un diplôme  lui  permettant  d’exercer  sa  profession  et  d’utiliser  le  Service  GENEALOVIZ  dans  son  cadre professionnel.
        </p>

        <p>
          En cas d’interdiction d’exercer sa profession, provisoire ou définitive, l’Utilisateur Médecin s’interdit d’utiliser le Service GENEALOVIZ.
        </p>

        <p>
          De manière générale, le Service GENEALOVIZ, y compris ses fonctionnalités, est proposé à l’Utilisateur Médecin  qui  reconnait  l’utiliser  sous  ses  seuls  contrôles,  direction  et  responsabilité  et  accepte  que l’utilisation qu’il en fait ne dilue ni totalement, ni partiellement l’ensemble de ses obligations légales et professionnelles et que le Service ne s’y substitue pas.
        </p>

        <p>
          Le  Service  GENEALOVIZ  n’est  pas  une  incitation  à  acheter,  prescrire,  promouvoir  ou  recommander, que  ce  soit  maintenant  ou  à  l’avenir,  des  produits  ou  services  d’OCTAPHARMA.  Le  Service  n’est  pas davantage une incitation à modifier le traitement actuel de patients au profit d’un autre traitement, et notamment de produits d’OCTAPHARMA.
        </p>

        <p>
          L’Utilisateur  Médecin  est  soumis  au  respect  de  l’intégralité  des  règles  déontologiques  présidant  à l'exercice de sa profession telles qu'elles sont définies dans le Code de déontologie des médecins et interprétées par le Conseil National de l’ordre des médecins dont il relève.
        </p>

        <p>
          Il déclare utiliser la Plateforme et le Service GENEALOVIZ dans le respect des principes déontologiques tels que notamment le principe d’indépendance et de libre choix des patients.
        </p>

        <div className={styles.bloc}>
          <h4>Mise en garde :</h4>

          <p>
            DE MANIERE GENERALE, L’UTILISATEUR MEDECIN RECONNAIT QUE L’UTILISATION QU’IL FAIT DES INFORMATIONS  ET  DES  FONCTIONNALITES  DU  SERVICE  GENEALOVIZ  SE  FAIT  SOUS  SES  SEULS CONTROLE, DIRECTION ET RESPONSABILITE.
          </p>

          <p>
            L’UTILISATEUR  MEDECIN  EST  SEUL  RESPONSABLE  DE  L’EXACTITUDE,  DE  LA  COHÉRENCE  ET  DE L'EXHAUSTIVITÉ DES DONNÉES QU’IL RENSEIGNE AU TITRE DE L’UTILISATION DU SERVICE GENEALOVIZ ET QUI SONT DETERMINANTES POUR LA QUALITE DE LA PRISE EN CHARGE DES PATIENTS. EN AUCUN CAS,  OCTAPHARMA  N’EFFECTUE  DES  VÉRIFICATIONS  OU  DES  CONTROLES  DE  COHERENCE, D’EXACTITUDE OU DE PERTINENCE (NOTAMMENT AU PLAN SCIENTIFIQUE, MÉDICAL OU SANITAIRE) DES DONNÉES SAISIES.
          </p>

          <p>
            DÈS LORS, LA RESPONSABILITÉ D’OCTAPHARMA NE POURRA EN AUCUN CAS ÊTRE ENGAGÉE DANSL'HYPOTHÈSE OU LES CONSEILS RENDUS SERAIENT ERRONÉS DU FAIT DE SAISIE DE DONNÉES INCOMPLÈTES,INSUFFISANTES OU ERRONÉES.
          </p>

          <p>
            EN TOUTES HYPOTHÈSES, OCTAPHARMA NE SAURAIT ÊTRE TENU RESPONSABLE DE L'INTERPRÉTATION OU   DE   L’UTILISATION   DES   CONTENUS   ET   INFORMATIONS   PAR   L’UTILISATEUR   MEDECIN COMMUNIQUÉS PAR L'INTERMÉDIAIRE DE LA PLATEFORME ET DU SERVICE GENEALOVIZ, NI DES CONSÉQUENCES QUI EN RESULTENT.
          </p>

        </div>

        <h3>9. Responsabilité</h3>

        <p>
          L’Utilisateur Médecin est responsable de l’utilisation qu’il fait du Service GENEALOVIZ et en particulier s’engage à effectuer toutes les vérifications nécessaires et opportunes. 
        </p>

        <p className={`${styles.bold} ${styles.underline}`}>
          L’Utilisateur Médecin reconnait et garantit utiliser le Service GENEALOVIZ qu’à des fins de test dans le  cadre  de  la  phase  pilote  et  dans  le  respect  du  Contrat  d’expertise  conclu  avec  OCTAPHARMA  à titre bénévole.
        </p>

        <p>
          L’Utilisateur Médecin demeure pleinement et personnellement responsable de l’utilisation du Service GENEALOVIZ  dans  les  conditions  de  droit  commun.  Il  s’engage  à  valider  l’adéquation  du  Service GENEALOVIZ  à  ses  besoins.  Il  reconnaît  qu’il  prend  seul  toute  décision  fondée  sur  le  Service GENEALOVIZ et Contenus fournis par le biais de la Plateforme.
        </p>

        <p>
          De manière générale, l’Utilisateur Médecin s’engage à utiliser le Service GENEALOVIZ :
        </p>

        <ul>
          <li>dans le respect des lois, règlementation et droits des tiers, notamment des droits de propriété intellectuelle et industrielle ;</li>
          <li>de manière loyale et conformément à sa destination.</li>
        </ul>

        <p>
          Il appartient notamment à l’Utilisateur Médecin :
        </p>

        <ul>
          <li>de satisfaire à ses obligations en matière de sécurité, conformément à l’article « Sécurité » des présentes CGU ;</li>
          <li>d’utiliser  le  Service  GENEALOVIZ  dans  le  respect  des  CGU  et  des  dispositions  légales  et règlementaires en vigueur ;</li>
          <li>de ne pas commercialiser tout ou partie du Service GENEALOVIZ accessible via la Plateforme ;</li>
          <li>de  s’assurer  qu’il  dispose  de  la  compétence  nécessaire  et  du  personnel  qualifié  pour l’utilisation du Service GENEALOVIZ accessible via la Plateforme.</li>
        </ul>

        <p>
          L’Utilisateur Médecin a été informé que :
        </p>

        <ul>
          <li>Des risques d’erreurs pouvaient survenir lors de sa première utilisation de la Plateforme et du Service ; </li>
          <li>En cas d’incidents dans l’utilisation de la Plateforme et du Service entraînant une dégradation partielle des Données et/ou des fichiers, leur reconstitution est une opération dont la réussite est  aléatoire,  et  que  cette  opération  ne  pourra  être  prise  en  charge  qu’après  un  accord spécifique souscrit auprès de OCTAPHARMA suite à un tel incident ;</li>
          <li>L’utilisation de la Plateforme et du Service ne le dispense pas de conserver sur tout support de son choix la copie des données à caractère personnel dans le cadre de ses obligations légales de conservation des données à caractère personnel relatives à ses patients ; </li>
          <li>OCTAPHARMA n’est pas responsable des conditions dans lesquelles les Utilisateurs Médecins utilisent le Service et la Plateforme.</li>
        </ul>

        <p>
          En outre, l’Utilisateur Médecin reconnait que :
        </p>

        <ul>
          <li>OCTAPHARMA assume la qualité d’éditeur au sens de la Loi n° 2004-575 du 21 juin 2004 pour la Confiance dans l’Economie Numérique modifiée pour les Contenus qu’elle détermine ;</li>
          <li>OCTAPHARMA  n’assume  en  aucun  cas  et  d’aucune  façon  que  ce  soit,  une  responsabilité médicale et déontologique.</li>
        </ul>

        <p>
          En  cas  de  non-respect  d’une  ou  plusieurs  dispositions  des  présentes  CGU,  l’accès  de  l’Utilisateur Médecin  au  Service  GENEALOVIZ  pourra  être,  unilatéralement,  de  plein  droit  et  sans  préavis, temporairement suspendu ou définitivement bloqué.
        </p>

        <p>
          OCTAPHARMA est tenue à une obligation de moyens dans le cadre de la mise à disposition du Service GENEALOVIZ  pour  ce  qui  la  concerne.  L’Utilisateur  Médecin  reconnaît  que  OCTAPHARMA  ne  pourra être  tenue  pour  responsable  de  tout  dommage  matériel  ou  immatériel,  direct  ou  indirect,  quelles qu’en  soient  les  causes  (y  compris  les  dommages  pouvant  être  causés  par  l’éventuelle  diffusion  de virus, par la fraude informatique, du fait des contraintes et limites du réseau internet ou encore de la perte, de la détérioration ou de l’altération de fichiers), ni des conséquences résultant :
        </p>

        <ul>
          <li>de son utilisation du Service GENEALOVIZ ;</li>
          <li>de  l’impossibilité  d’avoir  accès  au  Service  GENEALOVIZ,  à  l’exception  des  dommages  directs consécutifs à une faute lourde ou intentionnelle.</li>
        </ul>

        <p>
          En outre, il appartient notamment à l’Utilisateur Médecin de :
        </p>

        <ul>
          <li>Satisfaire  aux  obligations  découlant  de  la  Réglementation  et  en  particulier  l’enregistrement des  traitements  de  Données  sur  un  registre,  de  l’information  de  ses  patients  au  titre  de  la collecte,  du  traitement  et  de  l’hébergement  de  leurs  Données,  y  compris  leurs  données  de santé, de la mise en œuvre des mesures de sécurité pour ce qui le concerne et du recueil de leur consentement ;</li>
          <li>Garder strictement confidentiels ses mots de passe et identifiants et ne pas les dévoiler à un tiers, et, de manière générale, à des tiers ;</li>
          <li>Respecter la gestion des accès, en particulier, ne pas utiliser les mots de passe et code d'un autre Utilisateur Médecin, ni chercher à connaître ces informations ;</li>
          <li>Avertir  OCTAPHARMA  de  tout  dysfonctionnement  technique  constaté  et  de  toute  anomalie découverte ;</li>
          <li>Donner  toutes  les  informations  utiles,  nécessaires,  complètes  et  exactes  sur  sa  situation professionnelle  et  ses  qualifications  dans  le  cadre  de  son  utilisation  du  Service  et  de  la Plateforme.</li>
        </ul>

        <p>
          En  cas  de  mise  en  cause  de  la  responsabilité  d’OCTAPHARMA  du  fait  d’un  acte  quelconque  d’un Utilisateur  Médecin,  notamment  dans  ses  rapports  avec  ses  patients,  l’Utilisateur  Médecin  garantit OCTAPHARMA  contre  toute  condamnation,  indemnité,  somme  de  toute  nature  qui  serait  mise  à  sa charge, en ce compris les frais d’avocats éventuels et d’expertise.
        </p>

        <div className={styles.bloc}>
          <p>
            L’UTILISATION   DES   SERVICES   NE   DILUE,   NE   MODIFIE   OU   N’ATTENUE   NI   DIRECTEMENT,   NI INDIRECTEMENT  LA  RESPONSABILITE  ET  LES  OBLIGATIONS  DES  UTILISATEURS  MEDECINS.  LES UTILISATEURS MEDECINS EXERCENT LEUR PRATIQUE PROFESSIONNELLE EN TOUTE INDEPENDANCE, SELON  LEURS  OBLIGATIONS  DEONTOLOGIQUES,  LEGALES  ET  REGLEMENTAIRES  ET  SOUS  LEUR RESPONSABILITE EXCLUSIVE.
          </p>
        </div>

        <h3>10. Propriété intellectuelle</h3>

        <p>
          OCTAPHARMA est titulaire des droits de propriété intellectuelle et industrielle du Service GENEALOVIZ. L’utilisation  du  Service  GENEALOVIZ  ne  confère  en  aucun  cas  aux  Utilisateurs  Médecins  un  droit  de propriété sur le Service et Contenus accessibles par le biais de la Plateforme.
        </p>

        <h4>10.1 Marques, signes distinctifs et logos</h4>

        <p>
          Sauf précision contraire, toutes les marques utilisées dans le cadre du Service GENEALOVIZ et Contenus qui sont accessibles via la Plateforme sont la propriété de OCTAPHARMA.
        </p>
        
        <p>
          Sauf autorisation expresse et préalable de OCTAPHARMA, toute reproduction, (totale ou partielle) et usage de ces marques, figuratives ou non, appartenant à OCTAPHARMA expose l’Utilisateur Médecin à des poursuites judiciaires.
        </p>

        <h4>10.2 Bases de données</h4>

        <p>
          Conformément  aux  dispositions  du  Code  de  la  Propriété  intellectuelle  concernant  la  protection  des bases  de  données,  OCTAPHARMA  est  producteur  et  propriétaire  de  tout  ou  partie  des  bases  de données, leur structure et leurs contenus, composant le Service ou produites dans le cadre de la mise en œuvre du Service.
        </p>

        <p>
          En accédant au Service GENEALOVIZ, l’Utilisateur Médecin reconnaît que les données le composant ou produites  dans  le   cadre  de  la  mise  en  œuvre  du  Service  sont  légalement  protégées,  et  qu’il  lui  est interdit notamment d’extraire, réutiliser, stocker, reproduire, représenter ou conserver, directement ou indirectement, sur un support quelconque, par tout moyen et sous toute forme que ce soit, tout ou partie qualitativement ou quantitativement substantielle, du contenu des bases de données figurant au  sein  du  Service  auquel  il  accède,  ainsi  que  d’en  faire  l’extraction  ou  la  réutilisation  répétée  et systématique   de   parties   qualitativement   et   quantitativement   non   substantielles,   lorsque   ces opérations excèdent manifestement les conditions d’utilisation normale.
        </p>

        <h4>10.3 Service</h4>

        <p>
          Toute utilisation, reproduction, copie, diffusion du Service pour un usage autre que celui prévu au sein des présentes CGU est interdite.
        </p>

        <p>
          L’ensemble  du  Service,  incluant  de  manière  non  limitative,  le  nom  de  domaine,  les  textes,  la  charte graphique,  les  graphiques,  les  photographies,  les  dessins,  les  sons,  les  images,  audio  et  vidéo,  mais également l’arborescence, le plan de navigation, la conception et l’organisation de ses rubriques, leurs titres,  existants  ou  à  venir,  est  protégé  par  des  droits  de  propriété  intellectuelle,  détenus  ou revendiqués par OCTAPHARMA, avec l’autorisation des titulaires de ces droits, le cas échéant.
        </p>

        <p>
          Le Service est destiné à l’information et l’usage personnels de l’Utilisateur Médecin qui bénéficie d’un droit d’usage privé, non collectif et non exclusif.
        </p>

        <p>
          Sauf  autorisation  expresse  et  préalable  de  OCTAPHARMA  sont  interdites  toutes  reproductions, représentations   et   utilisations   par   l’Utilisateur   Médecin   autres   que   celles   visées   ci-dessus   et notamment :
        </p>

        <ul>
          <li>toute adaptation, mise à disposition du public à sa demande ou non, distribution, rediffusion sous quelque forme que ce soit, mise en réseau, communication publique, à titre gratuit ou onéreux, de tout ou partie des œuvres, prestations, et tous éléments protégés ou susceptibles de protection par le droit de la propriété intellectuelle reproduits au sein du Service ;</li>
          <li>tout  lien,  accès,  modification,  ajout,  suppression  qui  porte  sur  le  système  de  traitement automatisé  de  l’édition  en  ligne  et  qui  modifie  les  conditions  de  publication  ou  la  politique éditoriale.</li>
        </ul>

        <p>
          Tout   manquement   de   l’Utilisateur   Médecin   à   ces   obligations   constituerait   une   contrefaçon sanctionnée par les articles L. 335-2 et suivants du Code de la propriété intellectuelle.
        </p>
        
        <h3>11. Pharmacovigilance</h3>

        <p>
          Conformément aux obligations légales, réglementaires et déontologiques des Utilisateurs Médecins, il incombe à ces derniers de déclarer tout effet indésirable suspecté d'être dû à un médicament ou produit  mentionnés  à  l'article  L.  5121-1  dont  ils  ont  connaissance.  (Voir  en  particulier  l’article  L. 5121-25 Code de la santé publique) en adressant un email à fr2pharmacovigilance@octapharma.com.
        </p>

        <p>
          Tout signalement d’un événement sanitaire indésirable en ce compris les effets indésirables suspectés d'être  dû  à  un  médicament  ou  produit  mentionnés  à  l'article  L.  5121-1  peut  être  fait  sur  le  site  « signalement-sante.gouv.fr ».
        </p>

        <h3>12. Transparence</h3>

        <p>
          Conformément à l’article L. 1453-1 du Code de la santé publique, tel qu’il résulte de la loi n° 2011-2012 du  29  décembre  2011  relative  au  renforcement  de  la  sécurité  sanitaire  des  médicaments  et  des produits  de  santé,  et  aux  articles  D.  1453-1  et  R.  1453-2  à  R.  1453-9  du  Code  de  la  santé  publique, OCTAPHARMA  pourra  rendre  publique  les  informations  liées  à  la  mise  à  disposition  du  Service GENEALOVIZ à l’Utilisateur Médecin sur le site internet public visé à l’article R. 1453-4 du Code de la santé publique (www.transparence.sante.gouv.fr).
        </p>

        <p>
          Conformément à la loi du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés telle que modifiée, et le Règlement général sur la protection des données (RGPD), l’Utilisateur Médecin dispose de droits sur ses données à caractère personnel et notamment d’un droit d’accès et de rectification de ses données personnelles en contactant la personne responsable du site internet public sur lequel les informations    sont    publiées    visé    à    l’article    R.    1453-4    du    Code    de    la    santé    publique (www.transparence.sante.gouv.fr),  selon  les  procédures  prévues  par  le  site  internet  concerné. L’obligation de transparence étant une obligation légale, l’Utilisateur Médecin ne peut pas s’opposer à la publication de ses données personnelles.
        </p>

        <h3>13. Protection des données à caractère personnel</h3>

        <p>
          OCTAPHARMA   et   l’Etablissement   de   santé   /   l’Utilisateur   Médecin   s’engagent   à   respecter   les dispositions de la Règlementation en matière de protection des données à caractère personnel.
        </p>

        <h4>13.1 Les données à caractère personnel relatives aux utilisateurs médecins</h4>

        <p>
          OCTAPHARMA intervient en qualité de responsable de traitement des données à caractère personnel des  Utilisateurs  Médecins.  Les  informations  concernant  le  traitement  des  données  personnelles  des Utilisateurs   Médecins   sont   fournies   dans   la   politique   de   confidentialité   disponible   sur   ce lien :
          <br/>
          <a href="https://octasmart.octapharma.bakasable.fr/data-policy" rel="noopener noreferrer" target="_blank">https://octasmart.octapharma.bakasable.fr/data-policy</a>.
        </p>

        <h4>13.2 Les données à caractère personnel relatives aux patients des établissement de santé / utilisateurs médecins</h4>

        <p>
          L’Etablissement  de  santé  est  informé  par  l’Utilisateur  Médecin  de  l’utilisation  qu’il  fait  du  Service GENEALOVIZ et que cette utilisation implique un traitement de données à caractère personnel et qu’il doit à ce titre effectuer toutes les démarches nécessaires pour se conformer à la Règlementation au titre de la mise en œuvre de ce traitement y compris l’informations des patients.
        </p>

        <p>
          A cet égard, l’Etablissement de santé reconnaît qu’il est et demeure le Responsable de traitement de données à caractère personnel mis en œuvre dans le cadre de l’utilisation du Service GENEALOVIZ par l’Utilisateur Médecin et qu’il conserve l’entière maîtrise des données à caractère personnel relatives aux patients, OCTAPHARMA n’agissant qu’en qualité de sous-traitant au sens de la Réglementation.
        </p>

        <p>
          A  ce  titre,  l’Etablissement  de  santé  s’engage  à  respecter  les  obligations  qui  lui  incombent  en  cette qualité résultant notamment des dispositions de la Loi Informatique et Libertés et du RGPD.
        </p>

        <p>
          OCTAPHARMA, en sa qualité de sous-traitant de l’Etablissement de santé concernant la collecte et le traitement  des  données  à  caractère  personnel  mis  en  œuvre  pour  les  besoins  de  la  fourniture  du Service  GENEALOVIZ  dans  le  cadre  de  la  phase  pilote  à  des  fins  de  test,  s’engage  à  respecter  la Règlementation et à respecter l’accord sur la protection des données annexé aux présentes CGU.
        </p>

        <p>
          A  cet  effet,  l’Etablissement  de  santé  s’engage  à  assurer  l’information  des  patients  concernant  le traitement  de  leurs  données  et  de  recueillir  leur  consentement  éclairé,  explicite  et  préalable  si nécessaire.
        </p>

        <p>
          En application de la Règlementation, OCTAPHARMA met à disposition de l’Etablissement de santé via la Plateforme une notice d’information-consentement destinées aux patients, qui sera mise en ligne et rendues accessible par les Utilisateurs Médecins après validation de l’Etablissement de santé sous sa  responsabilité.  En  outre,  le  Service  GENEALOVIZ  dispose  d’une  case  à  cocher  permettant  à l’Utilisateur Médecin de tracer sur son interface le fait qu’il a bien informé son patient et qu’il a bien recueilli le consentement de ce dernier au titre du traitement de ses données génétiques à des fins de réalisation d’examen génétique par le biais d’une case à cocher.
        </p>

        <p>
          OCTAPHARMA  s’engage  à  collaborer  avec  l’Etablissement  de  santé  pour  les  besoins  de  la  mise  en conformité à la réglementation en vigueur dans les conditions définies à l’Annexe 1.
        </p>

        <p>
          En outre, OCTAPHARMA s’engage, en sa qualité de sous-traitant, à suivre et appliquer sans délai toutes les  instructions  que  pourrait  lui  adresser  l’Etablissement  de  santé  en  sa  qualité  de  Responsable  de traitement,  et  notamment  à  satisfaire  toute  demande  d’accès  et  de  suppression  de  Données susceptibles d’émaner d’un patient de l’Etablissement de santé.
        </p>

        <p>
          En outre, l’Etablissement de santé s’engage à effectuer régulièrement et conserver au moins une copie de sauvegarde de ses données. En conséquence, OCTAPHARMA n’encourra aucune responsabilité en cas  de  perte  ou  de  destruction  des  programmes  et/ou  données  qui  pourraient  survenir  lors  d’un incident  et/ou  d’une  anomalie  de  quelque  nature  que  ce  soit  dans  le  cadre  de  l’exécution  par OCTAPHARMA du Service.
        </p>

        <h3>14. Cas de force majeure</h3>

        <p>
          OCTAPHARMA ne sera pas tenue responsable en cas d’inaccessibilité de la Plateforme et/ou du Service causée  par  des  évènements  hors  de  son  contrôle  qui  ne  pouvaient  être  raisonnablement  prévus  et dont les effets ne peuvent être évités par des mesures appropriées, et empêchant l’exécution de ses obligations au sens de la définition légale figurant à l’article 1218 du Code civil (« Cas de Force Majeure »).
        </p>

        <p>
          L’exécution  des  CGU  sera  suspendue  tant  que  durera  le  Cas  de  Force  Majeure,  sauf  si  le  retard  en résultant  justifie  la  résiliation  des  présentes  CGU,  et  OCTAPHARMA  s’efforcera  dans  la  mesure  du possible de faire cesser le Cas de Force Majeure ou de trouver une solution lui permettant d'exécuter ses obligations contractuelles malgré le Cas de Force Majeure.
        </p>

        <p>
          Si l’empêchement est définitif, l’inscription de l’Utilisateur Médecin sera résiliée.
        </p>

        <h3>15. Modification des conditions générales d'utilisation et évolution du service</h3>

        <p>
          OCTAPHARMA se réserve le droit de modifier à tout moment les présentes CGU encadrant l’utilisation du Services GENEALOVIZ.
        </p>

        <p>
          OCTAPHARMA se réserve en outre le droit de faire évoluer le Service. Des modifications techniques pourront intervenir sans préavis de la part de OCTAPHARMA.
        </p>

        <p>
          L’Utilisateur  Médecin  sera  informé  de  la  mise  en  ligne  au  sein  de  la  Plateforme  de  chaque  nouvelle version des présentes CGU et pourra être invité à les accepter à nouveau.
        </p>

        <p>
          En  toutes  hypothèses,  le  fait  de  continuer  à  utiliser  le  Service  après  toute  modification  des  CGU entraîne l’acceptation des modifications des CGU.
        </p>

        <p>
          Enfin,  OCTAPHARMA  se  réserve  le  droit  de  suspendre  provisoirement  ou  définitivement  l’accès  au Service, sans délai, ni contrepartie de quelque nature que ce soit en cas de force majeure ou de faits techniques ou règlementaires indépendants de sa volonté.
        </p>

        <h3>16. Résiliation de l'inscription de l'utilisateur médecin au service</h3>

        <h4>16.1 A l'initiative de OCTAPHARMA</h4>

        <p>
          Chaque Utilisateur Médecin accepte que OCTAPHARMA résilie immédiatement son accès au Service sans préavis ni mise en demeure ni indemnité de quelque nature que ce soit en cas de manquement à l’une  quelconque  des  obligations  décrites  aux  présentes  CGU,  ou  à  la  législation  en  vigueur.  Sans limitation de ce qui précède, sont notamment susceptibles de fonder une résiliation de l’inscription de l’Utilisateur Médecin : 
        </p>

        <ul>
          <li>les infractions ou violations des présentes CGU ou tout autre Document contractuel y compris le Contrat d’expertise ;</li>
          <li>le non-respect d’une disposition légale ou réglementaire en vigueur ;</li>
          <li>une tentative de connexion non autorisée, par une utilisation frauduleuse du système ou par une usurpation des éléments d’authentification ;</li>
          <li>un empêchement définitif ;</li>
          <li>l’arrêt du Service et/ou de la Plateforme par OCTAPHARMA.</li>
        </ul>

        <h4>16.2 A l'initiative de l'utilisateur médecin</h4>

        <p>
          À tout moment, l’Utilisateur Médecin a la possibilité de clôturer son Compte Personnel, en adressant une demande à l’adresse électronique suivante :
          <br/>
          <a href="mailto:contact.webocta@octapharma.com">contact.webocta@octapharma.com</a>.
        </p>

        <p>
          L’Utilisateur  Médecin  est  informé  que  la  fermeture  de  son  Compte  Personnel  n’entraînera  pas  la suppression automatique des données à caractère personnel le concernant. Elles seront conservées, dans le respect des exigences posées par la Règlementation, ce qu’accepte expressément l’Utilisateur Médecin.
        </p>
        
        <p>
          Son  attention  est  également  attirée  sur  le  fait  qu’à  l’issue  de  cette  action  il  ne  sera  plus  en  mesure d’accéder à la Plateforme et au Service.
        </p>

        <h3>17. Cession</h3>

        <p>
          OCTAPHARMA  peut  céder  les  CGU  à  tout  moment  à  une  filiale  ou  à  un  successeur,  quelle  que  soit l’opération.
        </p>

        <p>
          L’Utilisateur Médecin n’est pas autorisé à céder les droits dont il dispose en application des CGU à un tiers, sans l’autorisation expresse et préalable de OCTAPHARMA.
        </p>

        <h3>18. Confidentialité et sécurité</h3>

        <p>
          De  manière  générale,  la  préservation  de  la  sécurité  du  Compte  Personnel  impose  aux  Utilisateurs Médecins :
        </p>

        <ul>
          <li>de respecter les consignes de sécurité et notamment les règles relatives à la définition et au changement de ses éléments d’authentification ;</li>
          <li>de respecter la gestion des accès, en particulier, ne pas utiliser les éléments d’authentification d'un autre Utilisateur Médecin, ni chercher à connaître ces informations ;</li>
          <li>de garder strictement confidentiels ses éléments d’authentification et ne pas les dévoiler à un tiers, quelles que soient ses qualités et activités professionnelles ;</li>
          <li>d'avertir OCTAPHARMA de tout dysfonctionnement technique constaté et de toute anomalie découverte, telle que les intrusions.</li>
        </ul>

        <p>
          Notamment, il appartient à l’Utilisateur Médecin de prendre toutes les mesures appropriées de façon à  protéger  ses  propres  données  et  matériels  de  la  contamination  par  des  virus  ou  autres  formes d’attaques circulant éventuellement via la Plateforme.
        </p>

        <p>
          Les Utilisateurs Médecins sont informés que des interventions techniques sur le Service s’effectuent dans le respect des dispositions de la Règlementation et de l'ensemble des dispositions du Code de la santé publique.
        </p>

        <p>
          Les   Utilisateurs   Médecins   reconnaissent   l’existence   de   risques   inhérents   à   l'utilisation   des télécommunications et ce, y compris même en présence d'un accès sécurisé tel qu'il est mis en œuvre dans le cadre du Service, et tout particulièrement en termes de :
        </p>

        <ul>
          <li>défaut de fiabilité du réseau Internet ;</li>
          <li>continuité non garantie dans l'accès à la Plateforme et à ses contenus et Services ;</li>
          <li>performances non garanties, compte tenu notamment de la propagation de virus ;</li>
          <li>toutes autres contraintes techniques qui ne sont pas sous le contrôle et la responsabilité de OCTAPHARMA.</li>
        </ul>

        <p>
          En aucun cas, OCTAPHARMA ne saurait être tenue responsable de ces risques et de leurs conséquences préjudiciables, quelle qu’en soit l’étendue, pour l’Utilisateur Médecin.
        </p>

        <h3>19. Convention de preuve - signature électronique</h3>

        <p>
          Les  registres  informatisés  conservés  dans  les  systèmes  informatiques  de  OCTAPHARMA  seront considérés  comme  les  preuves  des  communications  et  des  différentes  transmissions  d’écrits  et  de documents électroniques entre les Utilisateurs Médecins et OCTAPHARMA.
        </p>

        <p>
          L’Utilisateur Médecin reconnaît et accepte que la preuve de l’acceptation des CGU est caractérisée par l’activation du clic (J’ai lu et j’accepte les conditions générales d’utilisation).
        </p>

        <p>
          L’Utilisateur  Médecin  reconnaît  et  accepte,  qu’après  son  identification,  toute  manifestation  de volonté par le biais de l’utilisation du Service, et en particulier l’acceptation des CGU, constitue une signature  électronique  au  sens  des  dispositions  des  articles  1366  et  suivants  du  Code  civil,  et manifeste son consentement en caractérisant sa preuve. 
        </p>

        <p>
          Conformément  aux  dispositions  des  articles  1366  et  suivants  du  Code  civil,  la  mise  en  œuvre  d’une signature électronique, sur la base d’un procédé fiable d'identification garantissant son lien avec l'acte auquel elle s'attache, est considérée comme une signature valable et comme une preuve au sens des dispositions précitées.
        </p>

        <p>
          L’Utilisateur  Médecin  ne  pourra  pas  contester  la  recevabilité,  la  validité  ou  la  force  probante  des éléments sous format ou support électronique précités, sur le fondement de quelque disposition légale que ce soit et qui spécifierait que certains documents doivent être écrits ou signés pour constituer une preuve. Ainsi, les éléments considérés constituent des preuves et, s’ils sont produits comme moyens de preuve par OCTAPHARMA dans toute procédure contentieuse ou autre, seront recevables, valables et opposables de la même manière, dans les mêmes conditions et avec la même force probante que tout document qui serait établi, reçu ou conservé par écrit.
        </p>

        <p>
          À  tout  moment,  l’Utilisateur  Médecin  a  la  faculté  d’imprimer,  de  télécharger,  et  de  conserver  sur support papier et électronique un exemplaire des présentes CGU.
        </p>

        <h3>Loi applicable</h3>

        <p>
          Les  CGU  sont  régies  par  la  loi  française  et  seront  exécutées  et  interprétées  conformément  au  droit français.
        </p>

        <p>
          En cas de différend survenant entre les Parties au sujet de l’interprétation ou de l’exécution des CGU, et en dehors de cas de non-respect des délais de règlement, les Parties s’efforceront de trouver une solution amiable à leur différend dans les meilleurs délais. 
        </p>

        <p>
          A  défaut  de  règlement  amiable,  tout  litige  en  relation  avec  le  Service  sera  soumis  aux  tribunaux français,  quel  que  soit  le  lieu  de  résidence  de  l’Utilisateur  Médecin  et/ou  le  lieu  duquel  a  été sélectionné le Service.
        </p>
    
        <div className={styles.end}>
        </div>

      </div>
    </div>
  )
}
