import React, { useState } from 'react';
import CreatableSelect from 'react-select/creatable';

export default function CustomCreatable({ defaultValue , styles, options,  handleChange , placeholder , formatCreateLabel }) {

  const [ selected , setSelected ] = useState(defaultValue);

  function onChange(val , action ) {
    setSelected(val);
    handleChange(val , action );
  }

  // console.log(defaultValue)

  return (
    <>
      <CreatableSelect
        isClearable
        styles={styles}
        options={options}
        value={selected}
        formatCreateLabel={() => formatCreateLabel}
        placeholder={placeholder ? placeholder : "Séléctionner"}
        onChange={onChange}
        />
    </>
  )
}
