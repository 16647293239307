import React ,{ useEffect , useState , useCallback } from 'react';

import useDimensions from 'react-use-dimensions';

import { useGlobalContext } from '../../../../context/GlobalContext';

import PatientPanel from '../../patients/partial/PatientPanel';
import Filters from '../../../partials/Filters/Filters';

import { getPathologiesAction } from '../../../../actions/pathologyActions';
import { getNodesFiltredAction } from '../../../../actions/dataVizActions';

import martiniqueMap from './Viz/geojson/communes-martinique.json';
import guyaneMap from './Viz/geojson/communes-guyane.json';
import guadeloupeMap from './Viz/geojson/communes-guadeloupe.json';

import iconMartinique from '../../../../assets/images/martinique.svg';
import iconGuyane from '../../../../assets/images/guyane.svg';
import iconGuadeloupe from '../../../../assets/images/guadeloupe.svg';

import MapViz from './Viz/MapViz';
import MapNav from './Viz/MapNav/MapNav';

import "./geography.scss"

export default function GeographyViz({ setActivePage }) {
  
  const [ context, dispatch ] = useGlobalContext();

  const [ geographyRef, geographySize ] = useDimensions();
  const grey =  "#D8D8D8";

  const mapOptions = [
    { 
      label: "Martinique",
      geojson: martiniqueMap,
      icon: iconMartinique
    },
    { 
      label: "Guyane",
      geojson: guyaneMap,
      icon: iconGuyane
    },
    { 
      label: "Guadeloupe",
      geojson: guadeloupeMap,
      icon: iconGuadeloupe
    },
  ]

  const { localistaions } = context.dataviz;
  const pathologiesList = context.pathology.pathologiesList;
  const patient = context.patient.patient;

  const [ filters , setFilters ] = useState({});
  const [ selectedMaps , setSelectedMaps ] = useState(mapOptions);
  const [ panelIsOpen , setPanelIsOpen ] = useState(false);
  const [ isCreate , setIsCreate ] = useState(false);
  const [ mapData , setMapData ] = useState();

  const getFiltredNodes = useCallback(() => {
    getNodesFiltredAction(dispatch , filters);
  },[dispatch , filters])

  const getData = useCallback(() => {
    getPathologiesAction(dispatch);
    dispatch({
      type:'GET_PATIENT',
      payload: null
    })
  },[dispatch]);

  useEffect(() => {
    setActivePage("dataviz-geography");
    getData();
    getFiltredNodes();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (localistaions && pathologiesList.length > 0) {
      localistaions.forEach(node => {
        let color = grey
        let pathologyName = "pas de pathologie";
        if (node.pathology && pathologiesList.find(item => item._id === node.pathology)) {
          color = pathologiesList.find(item => item._id === node.pathology).color
          pathologyName = pathologiesList.find(item => item._id === node.pathology).name
        }
        // if (node.city === "Trois-Rivières") {
        //   console.log(node)
        // }
        node.color = color;
        node.pathologyName = pathologyName
      })
      setMapData(localistaions);
    }
  },[localistaions , pathologiesList]);

  useEffect(() => {
    if (patient) {
      setPanelIsOpen(true);
    } else {
      setPanelIsOpen(false)
    }
  },[patient]);

  useEffect(() => {
    getFiltredNodes();
  },[filters , getFiltredNodes])

  function closePatientPanel() {
    setPanelIsOpen(false);
    setIsCreate(false);
    dispatch({
      type:'GET_PATIENT',
      payload: null
    })
  }

  return (
    <>
      <div className="page-container geography">
        <Filters filters={filters} setFilters={setFilters} isDisabled={patient ? true : false } />
        {mapData &&
          <>
            <div ref={geographyRef} className="maps-container"> 
              {geographySize.width && selectedMaps.length > 0 && selectedMaps.map((map, i) => <MapViz key={"map" + i} data={mapData} mapKey={map.label} geojson={map.geojson} mapCount={selectedMaps.length} mapWidth={geographySize.width / selectedMaps.length } mapHeight={geographySize.height} />)}
            </div>
            <MapNav
              selectedMaps={selectedMaps}
              setSelectedMaps={setSelectedMaps}
              mapOptions={mapOptions}
              />
          </>
        }
      </div>
      <PatientPanel
        isCreate={isCreate}
        setIsCreate={setIsCreate}
        isDataViz={true}
        isOpen={panelIsOpen}
        close={() => closePatientPanel()}
        />
    </>
  )
}
