import {
  MESSAGE_PATHOLOGY,
  ERROR_PATHOLOGY,
  GET_PATHOLOGIES,
  GET_PATHOLOGY,
  PUT_PATHOLOGY
} from "../actions/types";

const initialState = {
  pathologiesList: [],
  pathology: null,
  error: null,
  message: null,
};

export default function pathologyReducer(state = initialState, action) {
  let updatedList = state.pathologiesList
  switch (action.type) {
    case PUT_PATHOLOGY:
        updatedList[updatedList.findIndex(item => item._id === action.payload.pathology._id)] = action.payload.pathology;
      return {...state , pathology: action.payload.pathology , message: action.payload.message  , pathologiesList: updatedList }
    case GET_PATHOLOGY:
      return {...state , pathology: action.payload}
    case GET_PATHOLOGIES:
      return {...state , pathologiesList: action.payload}
    case ERROR_PATHOLOGY:
      return {...state , error: action.payload}
    case MESSAGE_PATHOLOGY:
      return {...state , message : action.payload}
    default:
      return {...state};
  }
}