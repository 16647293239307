import 'date-fns';
import React , { useState , useEffect } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import frLocale from "date-fns/locale/fr";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker ,
} from '@material-ui/pickers';

import './datePicker.scss'

export default function CustomDatePicker({ label , handleChange , date , error }) {

  const [selectedDate, setSelectedDate] = useState(date ? date : null );

  useEffect(() => {
    if (date) setSelectedDate(date);
  }, [date])

  const darkColor = '#192243';

  const materialTheme = createMuiTheme({
    overrides: {
      MuiInputLabel: {
        shrink: false
      },
      MuiInputUnderline : {
        display: 'none'
      },
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: darkColor,
        },
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
          // backgroundColor: lightBlue.A200,
          // color: "white",
        },
      },
      MuiPickersDay: {
        daySelected: {
          backgroundColor: darkColor,
        },

      },
      MuiPickersModal: {
        dialogAction: {
          color: darkColor,
        },
      },
    },
  });

  const handleDateChange = (date) => {
    setSelectedDate(date);
    handleChange(date)
  };

  return (
    <div className="date-picker">
      <ThemeProvider theme={materialTheme}>
        <MuiPickersUtilsProvider locale={frLocale} utils={DateFnsUtils}>
          <KeyboardDatePicker
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            id="date-picker-inline"
            value={selectedDate}
            InputProps={{
              disableUnderline: true,
              // disableAnimation:true
            }}
            invalidDateMessage={"la date est invalide"}
            
            onChange={handleDateChange}
          />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </div>
  );
}