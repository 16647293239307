import React, { useEffect , useState } from "react";
import { useGlobalContext } from '../../context/GlobalContext';
import { Switch , Route , useLocation , useHistory } from "react-router-dom";
import { useTransition, animated } from 'react-spring';
import { setVhUnit } from '../../utils/utils';

//PARTIALS
import Toaster from "../partials/toaster/Toaster";
import Nav from "../partials/nav/Nav";
import NavViz from "../partials/NavViz/NavViz"
//PAGES
import Account from "../pages/account/Account";
import Login from "../pages/auth/Login";
import ResetPassword from "../pages/auth/ResetPassword";
import Legal from "../pages/Legal/Legal";

import Pathologies from "../pages/pathology/Pathologies";
import Patients from "../pages/patients/Patients";
import GenealogyViz from "../pages/dataviz/Genealogy/GenealogyViz";
import GeographyViz from "../pages/dataviz/Geography/GeographyViz";
import TimelineViz from "../pages/dataviz/Timeline/TimelineViz";

export default function GlobalRouter() {

  const [ context, dispatch ] = useGlobalContext();

  const location = useLocation();
  const history = useHistory();

  const transitions = useTransition(location, location => location.pathname, {
    from: { opacity: 0, transform: 'translate3d(-70%, 0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(70%, 0 ,0)'},
  });

  const [ activePage , setActivePage ] = useState("login");

  useEffect(() => {
    setVhUnit();
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(context.auth.error === 401) {
      logout();
    }
  // eslint-disable-next-line 
  }, [context.auth.error]);

  function logout() {
    history.push("/login");
    localStorage.removeItem('token');
    dispatch({
      type : 'IS_AUTH',
      payload : false
    });
    dispatch({
      type : 'USER_AUTH',
      payload : null
    });
  };

  return (
    <>
      <Nav
        logout={logout}
        activePage={activePage}
        />
      {transitions.map(({ item: location, props, key }) => (
        <animated.div key={key} style={props}>

          <Switch location={location}>
            
             <Route exact path="/login" render={
              (props) => <Login
              logout={logout}
              context={context}
              dispatch={dispatch}
              setActivePage={setActivePage}
              {...props}
              />
            }/>

            <Route exact path="/reset-password/:token" render={
              (props) => <ResetPassword
              context={context}
              dispatch={dispatch}
              setActivePage={setActivePage}
              {...props}
              />
            }/>

            <Route exact path="/account" render={
              (props) => <Account
              setActivePage={setActivePage}
              {...props}
              />
            }/>

            <Route exact path="/pathology" render={
              (props) => <Pathologies
              setActivePage={setActivePage}
              {...props}
              />
            }/>

            <Route exact path="/dataviz-genealogy" render={
              (props) => <GenealogyViz
              setActivePage={setActivePage}
              {...props}
              />
            }/>

            <Route exact path="/dataviz-geography" render={
              (props) => <GeographyViz
              setActivePage={setActivePage}
              {...props}
              />
            }/>

            <Route exact path="/dataviz-timeline" render={
              (props) => <TimelineViz
              setActivePage={setActivePage}
              {...props}
              />
            }/>

            <Route exact path="/" render={
              (props) => <Patients
              setActivePage={setActivePage}
              {...props}
              />
            }/>

            <Route exact path="/legal" render={
              (props) => <Legal
              setActivePage={setActivePage}
              {...props}
              />
            }/>    

          </Switch>
        </animated.div>
      ))}
      <NavViz activePage={activePage}/>
      <Toaster toast={context.auth.toast} dispatch={dispatch}/>
    </>
  )
}
