import React , { useState , useEffect , useCallback , useRef } from 'react';
import { useForm , Controller } from "react-hook-form";
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { VscChromeClose } from "react-icons/vsc";
import { BiNetworkChart } from "react-icons/bi";

import constentement from '../../../../assets/files/CONSENTEMENT.rtf'
import notice from '../../../../assets/files/NOTICE-INFORMATION.rtf'

import DatePicker from '../../../partials/DatePicker/DatePicker';
import Select from '../../../partials/Select/Select';
import Creatable from '../../../partials/Select/Creatable';
import EditGenalogy from './EditGenealogy/EditGenealogy';
import CheckBox from '../../../partials/Form/CheckBox/CheckBox';
import Inhibitor from '../partial/Inhibitor/Inhibitor';

import { formSelect } from '../../../partials/Select/styles';

import { calcAge } from '../../../../utils/utils';

import { useGlobalContext } from '../../../../context/GlobalContext';

import { putPatientAction , postPatientAction , getPatientListsAction , deletePatientAction } from '../../../../actions/patientsActions';
import { getGenealogiesAction } from '../../../../actions/dataVizActions';

export default function PatientPanel({ isOpen , close , isCreate  , isDataViz , setIsCreate}) {

  const { register, errors, handleSubmit , control  , setValue } = useForm();

  const primaryColor = '#bdbdbd'

  const autoSaveTimer = useRef();

  const [ context, dispatch ] = useGlobalContext();
  const [ prevPatient , setPrevPatient ] = useState()

  const { patient } = context.patient;
  const { pathologiesList } = context.pathology

  const [ formData , setFormData ] = useState({});
  const [ color , setColor ] = useState(primaryColor);
  
  const [ isEdit , setIsEdit ] = useState(false);
  const [ mutationsList , setMutationsList ] = useState();

  const [ patientDosages , setPatientDosages] = useState();
  const [ addDosage , setAddDosage ] = useState();
  const [ newDosage , setNewDosage ] = useState({});

  const [ patientAntecedents , setPatientAntecedents ] = useState();
  const [ addAntecedents , setAddAntecedents ] = useState(false);

  const [ patientFamilialAntecedents , setPatientFamilialAntecedents ] = useState();
  const [ addFamilialAntecedents , setAddFamilialAntecedents ] = useState(false);

  const [ patientSymptoms , setPatientSymptoms ] = useState();
  const [ addSymptom , setAddSymptom ] = useState(false);

  const [ patientTreatments , setPatientsTreatments] = useState([]);
  const [ addTreatments , setAddTreatments ] = useState(false);
  const [ patientCurrentTreatments , setPatientsCurrentTreatments] = useState([]);
  const [ addCurrentTreatments , setAddCurrentTreatments ] = useState(false);
  const [ isDelete , setIsDelete ] = useState(false);


  const [ patientComments , setPatientComments ] = useState([]);
  const [ comment , setComment ] = useState();
  const [ addComment , setAddComment ] = useState(false);
 
  const [ score , setScore ] = useState();
  const [ inhibitors , setInhibitors ] = useState([]);
  const localisationOptions = context.patient?.lists?.cities?.map(c => ({ value: c , label: `${c.city}` }));
  const pathologiesOptions = pathologiesList.map(item => ({ value: item._id , label: item.name }));
  const scoreList = [1 , 2 , 3, 4 , 5 , 6 ];
  const minirinOptions = ["Bon répondeur" , "Mauvais répondeur"].map(m => ({ value: m , label: m}));
  const genderOptions = context.patient?.lists?.genders?.map(g => ({ value: g , label: g}));
  const familialAntecedentsOptions = context.patient?.lists?.familialAntecedents?.map(g => ({ value: g , label: g}));
  const antecedentsOptions = context.patient?.lists?.antecedents?.map(a => ({ value: a , label: a}));
  const treatmentsOptions = context.patient?.lists?.treatments?.map(t => ({ value: t , label: t}));
  const protocolsOptions = context.patient?.lists?.protocols;
  const symptomsOptions = context.patient?.lists?.symptoms?.map(s => ({ value: s , label: s }));
  const pathologieTypes = pathologiesList?.find(item => item._id === patient?.pathology )?.types;
  const typesOptions = pathologieTypes?.map((t) => ({ value: t._id, label: t.name }));
  const pathologieSubtypes = pathologiesList?.find(item => item._id === patient?.pathology )?.types?.find((t) => t._id === patient?.type)?.subtypes;
  
  const subtypesOptions = pathologieSubtypes?.map((t) => ({ value: t._id, label: t.name }));
  
  console.log('types', subtypesOptions);
  const getLists = useCallback(() => {
    getPatientListsAction(dispatch);
  },[dispatch]);

  useEffect(() => {
    getLists();
  },[getLists])

  useEffect(() => {
    register("diagnosticDate");
    register("gender");
    register("localisation");
    register("birthDate");
    register("pathology");
    register("type");
    register("subtype");
    register("mutation");
    register("symptoms");
    register("antecedents");
    register("dosages");
    register("conductor");
    register("informationNote");
    register("consent");
    register("currentTreatment");
    register("score");
    register("inhibitors");
    register("familialAntecedents");
    register("treatments");
  }, [register])

  useEffect(() => {
    if (!isOpen) setIsEdit(false);
  }, [isOpen]);

  useEffect(() => {
    if (isCreate) {
      setIsEdit(true);
      setFormData({})
      setColor(primaryColor);
    }
  }, [isCreate]);

  useEffect(() => {
    if (patient && prevPatient !== patient._id) {
      setFormData({});
      setIsEdit(false);
      setPrevPatient(patient._id);
      if (patient.diagnosticDate) setValue("diagnosticDate" , patient.diagnosticDate );
      if (patient.gender) setValue("gender" , patient.gender );
      if (patient.localisation) setValue("localisation" , patient.localisation );
      if (patient.birthDate) setValue("birthDate" , patient.birthDate );
      if (patient.pathology) setValue("pathology" , patient.pathology );
      if (patient.type) setValue("type" , patient.type );
      if (patient.subtype) setValue("subtype" , patient.subtype );
      if (patient.symptoms) setPatientSymptoms(patient.symptoms);
      if (patient.antecedents) setPatientAntecedents(patient.antecedents);
      if (patient.dosages) setPatientDosages(patient.dosages);
      if (patient.comments) setPatientComments(patient.comments);
      if (patient.code) setValue("code" , patient.code );
      if (patient.score) setScore(patient.score);
      if (patient.inhibitors) setInhibitors(patient.inhibitors);
      if (patient.familialAntecedents) setPatientFamilialAntecedents(patient.familialAntecedents);
      if (patient.treatments) setPatientsTreatments(patient.treatments);
      if (patient.currentTreatment) setPatientsCurrentTreatments(patient.currentTreatment);
    }
    
    if(!patient){
      setFormData({})
      setPrevPatient();
      setValue("diagnosticDate" , null );
      setValue("gender" , null );
      setValue("localisation" , null );
      setValue("birthDate" , null );
      setValue("pathology" , null );
      setPatientSymptoms([]);
      setPatientAntecedents([]);
      setPatientDosages([]);
      setPatientFamilialAntecedents([]);
      setPatientsTreatments([]);
      setPatientsCurrentTreatments([])
      setScore(null);
      setAddAntecedents(false);
      setAddComment(false);
      setAddDosage(false);
      setAddFamilialAntecedents(false);
      setAddSymptom(false);
      setAddTreatments(false);
      setAddCurrentTreatments();

    }
    
    // eslint-disable-next-line
  }, [patient]);

  useEffect(() => {
    if (patient && patient.pathology && findPathologie(patient.pathology)) {
      setColor(findPathologie(patient.pathology).color)
      let mutations = findPathologie(patient.pathology).mutations.map(item => ({ value: item , label: item }));
      setMutationsList(mutations);

      if (patient.mutation) setValue("mutation" , patient.mutation );

    }
    if (formData && formData.pathology && findPathologie(formData.pathology)) {
      setColor(findPathologie(formData.pathology).color)
      let mutations = findPathologie(formData.pathology).mutations.map(item => ({ value: item , label: item }))
      setMutationsList(mutations);
    }
    // if (patient && patient.pathology  && patient.subtype && findPathologie(patient.pathology)) {
    //   setColor(findPathologie(patient.pathology).color)
    //   let mutations = findPathologie(patient.pathology)?.types?.find((t) => t._id === patient.type || t._id === '62f125fcea2e075ca6f9775e' )?.subtypes?.find((t) => t._id === patient.subtype )?.mutations.map(item => ({ value: item , label: item }));
    //   setMutationsList(mutations);

    //   if (patient.mutation) setValue("mutation" , patient.mutation );

    // }
    // if (formData && formData.pathology && patient.subtype && findPathologie(formData.pathology)) {
    //   setColor(findPathologie(formData.pathology).color)
    //   let mutations = findPathologie(formData.pathology)?.types?.find((t) => t._id === formData.type || t._id === '62f125fcea2e075ca6f9775e' )?.subtypes?.find((t) => t._id === formData.subtype )?.mutations.map(item => ({ value: item , label: item }));
    //   setMutationsList(mutations);
    // }


    if (patient && formData  && !formData.pathology && !patient.pathology ) {
      setMutationsList();
      setColor(primaryColor)
    }
    // eslint-disable-next-line
  }, [pathologiesList , patient , formData]);

  useEffect(() => {
    if (context.patient.message === "Patient updated") {
      isDataViz && getGenealogiesAction(dispatch);
      dispatch({
        type: 'SET_TOAST',
        payload: {
          message: "Le patient a été modifié",
          type: "success"
        }
      });
      dispatch({
        type:'MESSAGE_PATIENT',
        payload: null
      });
    }

    if (context.patient.message === "Patient saved") {
      close();
      setColor("#4744BA");
      dispatch({
        type: 'SET_TOAST',
        payload: {
          message: "Le patient a été crée",
          type: "success"
        }
      });
      
      dispatch({
        type:'MESSAGE_PATIENT',
        payload: null
      });
    }
    // eslint-disable-next-line
  }, [context.patient.message]);

  function findPathologie(pathologyId) {
    let foundPathology = pathologiesList.find(item => item._id === pathologyId)
    if (foundPathology) {
      return foundPathology
    }
  }

  function saveWithTimer(data) {
    clearTimeout(autoSaveTimer.current);
    autoSaveTimer.current = setTimeout(handleSubmit((d) => putPatientAction(dispatch , patient._id , data )) , 3000)
  }

  function createPatient() {
    clearTimeout(autoSaveTimer.current);
    autoSaveTimer.current = setTimeout(handleSubmit((d) => postPatientAction(dispatch , formData )) , 100)
  }

  function handleChange(key , value ) {
    let datas = {...formData}
    switch (key) {
      
      case "originCountry":
        setValue("originCountry" , value )
        datas.originCountry = value;
        break;

      case "bloodRhesus":
        setValue("bloodRhesus" , value )
        datas.bloodRhesus = value;
        break;
      
      case "bloodGroup":
        setValue("bloodGroup" , value )
        datas.bloodGroup = value;
        break;

      case "code":
        setValue("code" , value )
        datas.code = value;
        break;

      case "diagnosticDate":
        setValue("diagnosticDate" , value )
        datas.diagnosticDate = value;
        break;

      case "gender":
        setValue("gender" , value )
        datas.gender = value;
        break;

      case "localisation":
        setValue("localisation" , value )
        datas.localisation = value;
        break;

      case "birthDate":
        setValue("birthDate" , value )
        datas.birthDate = value;
        break;

      case "type":
        let selectedType = value && value.value ? value.value : null
        setValue("type" , selectedType )
        setMutationsList();
        datas.mutation = null;
        datas.type = selectedType;
        datas.subtype = null;
        break;

      case "subtype":
        let selectedSubType = value && value.value ? value.value : null
        setValue("subtype" , selectedSubType )
        setMutationsList();
        datas.mutation = null;
        datas.subtype = selectedSubType;
        break;

      case "pathology":
        let selected = value && value.value ? value.value : null
        setValue("pathology" , selected )
        setMutationsList();
        datas.mutation = null;
        datas.pathology = selected;
        break;

      case "mutation":
        let mutations = []
        if (value) mutations = value.map(item => item.label);
        setValue("mutation" , mutations )
        datas.mutation = mutations;
        break;

      case "symptoms":
        setValue("symptoms" , value );
        setPatientSymptoms(value)
        setAddSymptom(false)
        datas.symptoms = value;
        break;

      case "currentTreatment":
        setValue("currentTreatment" , value );
        setPatientsCurrentTreatments(value)
        setAddCurrentTreatments(false)
        datas.currentTreatment = value;
        break;

      case "treatments":
        setValue("treatments" , value );
        setPatientsTreatments(value)
        setAddTreatments(false)
        datas.treatments = value;
        break;
      
      case "conductor":
        setValue("conductor" , value );
        datas.conductor = value;
        break;

      case "informationNote":
        setValue("informationNote" , value );
        datas.informationNote = value;
        break;

      case "consent":
        setValue("consent" , value );
        datas.consent = value;
        break;

      case "comments":
        setValue("comments" , value );
        setPatientComments(value);
        setComment();
        setAddComment(false);
        datas.comments = value;
        break;

      case "score":
        if (value === score) {
          setValue("score" , null )
          setScore(null)
          datas.score = null;
        } else {
          setValue("score" , value )
          setScore(value)
          datas.score = value;
        }
        break;

      case "inhibitors":
          let inhibitorsList = inhibitors;
          if (value.data) {
            inhibitorsList[value.index] = value.data
          } else {
            inhibitorsList = inhibitorsList.filter((item , i ) => i !== value.index);
          }
          setValue("inhibitors" , value.data )
          setInhibitors(inhibitorsList);
          datas.inhibitors = inhibitorsList;
          break;

      case "parents":
        setValue("parents" , value )
        datas.parents = value;
        break;

      case "antecedents":
        setValue("antecedents" , value );
        setPatientAntecedents(value)
        setAddAntecedents(false)
        datas.antecedents = value;
        break;

      case "familialAntecedents":
        setValue("familialAntecedents" , value );
        setPatientFamilialAntecedents(value)
        setAddFamilialAntecedents(false)
        datas.familialAntecedents = value;
        break;

      case "dosages":
        setValue("dosages" , value );
        setPatientDosages(value)
        setAddDosage(false)
        datas.dosages = value;
        break;

      case "minirin":
        setValue("minirin" , value )
        datas.minirin = value;
        break;
        
      default:
        return;
    }
    setFormData(datas);
    if (!isCreate) saveWithTimer(datas);
  }

  function handleChangeDosages(value , action ) {
    var dosages = [...patientDosages];
    switch (action) {

      case "remove":
        dosages = dosages.filter(item => item !== value);
        break;

      case "add":
        setNewDosage({});
        dosages.push(value);
        break;
    
      default:
        break;
    }
    handleChange("dosages" , dosages );
  }

  function handleChangeFamilialAntecedents(value , action ) {
    var familialAntecedents = [...patientFamilialAntecedents];

    switch (action) {

      case "remove":
        familialAntecedents = familialAntecedents.filter(item => item !== value);
        break;

      case "select-option":
        familialAntecedents.push(value.value);
        break;

      case "create-option":
        familialAntecedents.push(value.value);
        break;
    
      default:
        break;
    }
    handleChange("familialAntecedents" , familialAntecedents );
  }

  function handleChangeAntecedents(value , action ) {
    var antecedents = [...patientAntecedents];
    switch (action) {

      case "remove":
        antecedents = antecedents.filter(item => item !== value);
        break;
      
      case "select-option":
        antecedents.push(value.value);
        break;

      case "create-option":
        antecedents.push(value.value);
        break;
    
      default:
        break;
    }
    handleChange("antecedents" , antecedents );
  }

  function handleChangeSymptoms(value , action ) {
    var symptoms = [...patientSymptoms];
    switch (action) {

      case "remove":
        symptoms = symptoms.filter(item => item !== value);
        break;

      case "select-option":
        symptoms.push(value.value);
        break;

      case "create-option":
        symptoms.push(value.value);
        break;
    
      default:
        break;
    }
    handleChange("symptoms" , symptoms );
  }

  function handleChangeTreatments(value , action ) {
    var treatments = [...patientTreatments];
    switch (action) {

      case "remove":
        treatments = treatments.filter(item => item !== value);
        break;

      case "select-option":
        treatments.push(value.value);
        break;

      case "create-option":
        treatments.push(value.value);
        break;
    
      default:
        break;
    }
    handleChange("treatments" , treatments );
  }

  function handleChangeCurrentTreatments(value , action ) {
    var treatments = [...patientCurrentTreatments];
    switch (action) {

      case "remove":
        treatments = treatments.filter(item => item !== value);
        break;

      case "select-option":
        treatments.push(value.value);
        break;

      case "create-option":
        treatments.push(value.value);
        break;
    
      default:
        break;
    }
    handleChange("currentTreatment" , treatments );
  }

  function handleChangeComment(value , action ) {
    var comments = [...patientComments];
    switch (action) {
      case "remove":
        comments = comments.filter(item => item !== value);
        break;

      case "add":
        comments.push(value);
        break;
    
      default:
        break;
    }
    handleChange("comments" , comments );
  }

  function renderPreview() {
    return (
      <>
        <div className="header patient">

          <div>
            <h2>{patient.code}</h2>
            {findPathologie(patient.pathology) && <div className="color-label" style={{ backgroundColor: findPathologie(patient.pathology).color }}></div>}

            {patient.diagnosticDate && <p className="date">Date de diagnostic : {format(new Date(patient.diagnosticDate), 'dd/MM/yyyy')}</p>}
            <Link to="/dataviz-genealogy">
              <BiNetworkChart size={20} /> Voir la généalogie
            </Link>
          </div>

          <div>
            <button className="btn-edit" onClick={() => setIsEdit(true)} >
              éditer
            </button>
          </div>
   
        </div>

        <div className="panel-content patient">

          <div className="patient-infos">

            <div className="col-2">

              <div className="form-group">
                <p className="form-label">
                  <label>Genre</label>
                </p>
                <div className="form-content">
                  <p>{patient.gender} {patient.conductor && "(conductrice)"}</p>
                </div>
              </div>

              <div className="form-group">
                <p className="form-label">
                  <label>Localisation</label>
                </p>
                <div className="form-content">
                  <p>{patient.localisation && patient.localisation.city }</p>
                </div>
              </div>

              {patient.originCountry &&
                <div className="form-group">
                  <p className="form-label">
                    <label>Pays d'origine</label>
                  </p>
                  <div className="form-content">
                    <p>{patient.originCountry}</p>
                  </div>
                </div>
              }

              <div className="form-group">
                <p className="form-label">
                  <label>Age</label>
                </p>
                <div className="form-content">
                  <p>{patient.birthDate && calcAge(patient.birthDate)} ans</p>
                </div>
              </div>

            </div>

            <div className="border"></div>

            <div className="col-2">

            {patient.pathology && findPathologie(patient.pathology) &&
              <>
                <div className="form-group">
                  <p className="form-label">
                    <label>Maladie</label>
                  </p>
                  <div className="form-content">
                    <p>{findPathologie(patient.pathology).name }</p>
                  </div>
                </div>

                <div className="form-group">
                  <p className="form-label">
                    <label>Type</label>
                  </p>
                  <div className="form-content">
                    <p>{typesOptions.find(item => item.value === patient?.type)?.label}</p>
                  </div>
                </div>

                <div className="form-group">
                  <p className="form-label">
                    <label>Sous-type</label>
                  </p>
                  <div className="form-content">
                    <p>{subtypesOptions.find(item => item.value === patient?.subtype)?.label}</p>
                  </div>
                </div>

                {patient?.mutation &&
                  <div className="form-group">
                    <p className="form-label">
                      <label>Mutations</label>
                    </p>
                    <div className="form-content">
                      <p>{patient.mutation && patient.mutation.map((item , i) => <span key={'mut' + i}>{item}<br/></span>) }</p>
                    </div>
                  </div>
                }

                {patient?.bloodGroup &&
                  <div className="form-group">
                    <p className="form-label">
                      <label>Groupe sanguin</label>
                    </p>
                    <div className="form-content">
                      <p>{patient?.bloodGroup}</p>
                    </div>
                  </div>
                }

                {patient?.bloodRhesus &&
                  <div className="form-group">
                    <p className="form-label">
                      <label>Rhésus</label>
                    </p>
                    <div className="form-content">
                      <p>{patient?.bloodRhesus}</p>
                    </div>
                  </div>
                }

              </>
            }

            </div>
          </div>

          {/* Score  Hémorragique  */}
          {patient.score &&
            <div className="form-group score">
              <label>Score Hémorragique</label>
              <div className="score-list">
                {scoreList.map((num , i ) => 
                  <div
                    key={"score" + i }
                    style={{ backgroundColor : patient.score === num ?  color : "#EBECED" }}
                    className="btn-score">
                    {num <= 5 ? num : '>5' }
                  </div>
                )}
              </div>
            </div>
          }

          {patient?.minirin &&
            <div className="form-group minirin">
              <p className="form-label">
                <label>Test minirin</label>
              </p>
              <div className="form-content">
                <p>{patient?.minirin}</p>
              </div>
            </div>
          }

          {/* Inhibitors  */}
          {patient.inhibitors.length > 0 &&
            <div className="form-group inhibitors">
              <div className="title">
                <label>Présence Inhibiteurs ({patient.inhibitors.length})</label>
              </div>
              {patient.inhibitors.map((inhibitor , i) => (
                <div key={"inhibitor" + i } className="patient-infos">

                  <div className="col-2">

                    {inhibitor.title &&
                      <div className="form-group">
                        <p className="form-label">
                          <label>titre</label>
                        </p>
                        <div className="form-content">
                          <p>{inhibitor.title}</p>
                        </div>
                      </div>
                    }

                    {inhibitor.protocol &&
                      <div className="form-group">
                        <p className="form-label">
                          <label>Protocole</label>
                        </p>
                        <div className="form-content">
                          <p>{inhibitor.protocol}</p>
                        </div>
                      </div>
                    }
      
                    {inhibitor.iti &&
                      <div className="form-group">
                        <p className="form-label">
                          <label>iti</label>
                        </p>
                      </div>
                    }
      
                  </div>
      
                  <div className="border"></div>
      
                  <div className="col-2">

                    {inhibitor.apparitionDate &&
                      <div className="form-group">
                        <label>Jour d’apparition</label><span>{format(new Date(inhibitor.apparitionDate), 'dd/MM/yyyy')}</span>
                      </div>
                    }

                    {inhibitor.initiationDate &&
                      <div className="form-group">
                        <label>Date d’initiation</label><span>{format(new Date(inhibitor.initiationDate), 'dd/MM/yyyy')}</span>
                      </div>
                    }

                    {inhibitor.stopDate &&
                      <div className="form-group">
                        <label>Jour de fin</label><span>{format(new Date(inhibitor.stopDate), 'dd/MM/yyyy')}</span>
                      </div>
                    }

                  </div>
                </div>
              ))}

            </div>
          }

          {patient.currentTreatment?.length > 0 &&
            <div className="form-group">
              <p className="form-label">
                <label>Traitements<br/>en cours</label>
              </p>
              <div className="form-content">
                <div className="text-list">
                  {patient.currentTreatment && patient.currentTreatment.map((treatment, i) => {
                    return(
                      <div key={"treatment" + i } className="list-item">
                        <p className="text">{treatment}</p>
                      </div>
                    )}
                  )}
                </div>
              </div>
            </div>
          }

          {patient.treatments && patient.treatments.length > 0 &&
            <div className="form-group">

              <p className="form-label">
                <label>Traitements</label>
              </p>

              <div className="form-content">
                <div className="text-list">
                  {patient.treatments && patient.treatments.map((treatment, i) => {
                    return(
                      <div key={"treatment" + i } className="list-item">
                        <p className="text">{treatment}</p>
                      </div>
                    )}
                  )}
                </div>
              </div>

            </div>
          }

          {patient.dosages && patient.dosages.length > 0 &&
            <div className="form-group">

              <p className="form-label">
                <label>Dosages</label>
              </p>

              <div className="form-content">
                <div className="text-list">
                  {patient.dosages && patient.dosages.map((dose , i) => {
                    return(
                      <div key={"dosage" + i } className="list-item">
                        <p className="text">{dose.name} {dose.value} {dose.unit}</p>
                      </div>
                    )}
                  )}
                </div>
              </div>

            </div>
          }

          {patient.antecedents && patient.antecedents.length > 0 &&
            <div className="form-group">

              <p className="form-label">
                <label>Antécedents personnels</label>
              </p>

              <div className="form-content">
                <div className="text-list">
                  {patient.antecedents && patient.antecedents.map((antecedent, i) => {
                    return(
                      <div key={"antecedent" + i } className="list-item">
                        <p className="text">{antecedent}</p>
                      </div>
                    )}
                  )}
                </div>
              </div>

            </div>
          }

          {patient.familialAntecedents && patient.familialAntecedents.length > 0 &&
            <div className="form-group">

              <p className="form-label">
                <label>Antécedents familiaux</label>
              </p>

              <div className="form-content">
                <div className="text-list">
                  {patient.familialAntecedents && patient.familialAntecedents.map((antecedent, i) => {
                    return(
                      <div key={"antecedent" + i } className="list-item">
                        <p className="text">{antecedent}</p>
                      </div>
                    )}
                  )}
                </div>
              </div>

            </div>
          }

          {patient.symptoms && patient.symptoms.length > 0 &&
            <div className="form-group">

              <p className="form-label">
                <label>Symptomes</label>
              </p>

              <div className="form-content">
                <div className="text-list">
                  {patient.symptoms && patient.symptoms.map((antecedent, i) => {
                    return(
                      <div key={"antecedent" + i } className="list-item">
                        <p className="text">{antecedent}</p>
                      </div>
                    )}
                  )}
                </div>
              </div>

            </div>
          }


          {patient.parents && patient.parents.length > 0 &&
            <div className="form-group">

              <p className="form-label">
                <label htmlFor="parents">Généalogie</label>
              </p>

              <div className="form-content">
                <EditGenalogy
                  patient={patient}
                  patientsList={context.patient.patientsList}
                  color={color}
                  dispatch={dispatch}
                  setIsCreate={setIsCreate}
                  handleChange={(val) => handleChange("parents" , val )}
                  findPathologie={findPathologie}
                  isPreview={true}/>
              </div>

            </div>
          }
          
          {patient.comments && patient.comments.length > 0 &&
            <div className="form-group">

              <p className="form-label">
                <label>Commentaires</label>
              </p>

              <div className="form-content">
                <div className="text-list">
                  {patient.comments && patient.comments.map((comment, i) => {
                    return(
                      <div key={"comment" + i } className="list-item">
                        <p className="text">{comment}</p>
                      </div>
                    )}
                  )}
                </div>
              </div>

            </div>
          }

          {patient.informationNote || patient.consent ?
            <div className="form-group legal">
              <p className="form-label">
                <label>Légal</label>
              </p>
              <div className="form-content">
                {patient.informationNote && <a href={notice} download><p>Note d’information donnée au patient</p></a>}
                {patient.consent && <a href={constentement} download><p>Consentement obtenu pour la génétique</p></a>}
                <p className="note">Modèle de notice d’information et de consentement que vous pouvez remettre au patient après validation et personnalisation du contenu par vos soins en exécution de votre obligation d’information en tant que responsable de traitement. L’information et le recueil du consentement doivent avoir lieu avant la collecte et le traitement des données de vos patients y compris au sein de l’outil digital de visualisation des données dénommé « GENEALOVIZ »</p>
              </div>
            </div>
            :
            <></>
          }

        </div>
      </>
    )
  }

  function renderEdit() {
    return (
      <form>
        <div className="header patient">
          <div>
            <div style={{ marginBottom: 10 }}>
              <label htmlFor="code">Numero du patient</label>
              <input
                name="code"
                ref={register({ required: "le code patient est requis" })}
                onChange={(e) => handleChange('code', e.target.value)}
                className="code"
                defaultValue={patient.code ? patient.code : ""} />
              {errors.code && errors.code.message ? <p className="error">{errors.code.message}</p> : null }
            </div>

            <label style={{ marginBottom : 5 , display: 'block'}} htmlFor="diagnosticDate">Date de diagnostic</label>
            
            <Controller
              name="diagnosticDate"
              control={control}
              // rules={{ required: "la date de diagnostic est requise" }}
              render={props =>
                <DatePicker
                  date={patient.diagnosticDate}
                  handleChange={(value) => handleChange("diagnosticDate", value ) } />
              }
              defaultValue={patient.diagnosticDate ? patient.diagnosticDate : new Date() }
            />
            {errors.diagnosticDate && errors.diagnosticDate.message ? <p className="error">{errors.diagnosticDate.message}</p> : null }

          </div>

          <div>
            <button className="btn-edit" onClick={() => setIsEdit(false)} >
              retour
            </button>
          </div>
   
        </div>

        <div className="panel-content patient">
          
          {/* LES INFOS PATIENT  */}
          <div className="patient-infos">

            <div className="col-2">

              <div className="form-group">
                <p className="form-label">
                  <label htmlFor="gender">Genre</label>
                </p>
                <div className="form-content">
                  <Controller
                    name="gender"
                    rules={{ required: "le genre est requis" }}
                    control={control}
                    render={props =>
                      <Select
                        styles={formSelect}
                        defaultValue={patient.gender ? genderOptions.find(item => item.value === patient.gender) : genderOptions[0] }
                        handleChange={(opt) => handleChange('gender' , opt.value )}
                        options={genderOptions}
                        />
                    }
                    defaultValue={patient.gender ? genderOptions.find(item => item.value === patient.gender) : genderOptions[0] }
                  />
                  {errors.gender && errors.gender.message ? <p className="error">{errors.gender.message}</p> : null }
                
                  {patient.gender === "Femme" && findPathologie(patient.pathology) && findPathologie(patient.pathology).name === "Hemophilie A" &&  
                    <div style={{ marginTop: 10 }}>
                      <Controller
                        name="conductor"
                        control={control}
                        defaultValue={patient.conductor ? true : false}
                        render={props =><CheckBox
                          defaultValue={patient.conductor ? true : false}
                          onChange={(val) => handleChange('conductor' , val )}
                          label={"Conductrice"}/> 
                        }
                      />
                    </div>
                  } 
                </div>
              </div>

              <div className="form-group">
                <p className="form-label">
                  <label htmlFor="localisation">Localisation</label>
                </p>
                <div className="form-content">
                  <Controller
                    name="localisation"
                    rules={{ required: "la localisation est requise" }}
                    control={control}
                    render={props =>
                      <Select
                        styles={formSelect}
                        defaultValue={patient.localisation ? localisationOptions.find(item => item.label === patient.localisation.city ) : null}
                        handleChange={(opt) => handleChange('localisation' , opt.value )}
                        options={localisationOptions}
                        />
                    }
                    defaultValue={patient.localisation ? localisationOptions.find(item => item.label === patient.localisation.city ) : null}
                  />
                  {errors.localisation && errors.localisation.message ? <p className="error">{errors.localisation.message}</p> : null }
                </div>
              </div>

              <div className="form-group">
                <p className="form-label">
                  <label htmlFor="originCountry">Pays d'origine</label>
                </p>
                <div className="form-content">
                <input
                  name="originCountry"
                  ref={register}
                  onChange={(e) => handleChange('originCountry', e.target.value)}
                  defaultValue={patient.originCountry ? patient.originCountry : ""} />
                  {errors.originCountry && errors.originCountry.message ? <p className="error">{errors.originCountry.message}</p> : null }
                </div>
              </div>

              <div className="form-group">
                <p className="form-label">
                  <label htmlFor="birthDate">Date de naissance</label>
                </p>
                <div className="form-content">
                  <Controller
                    name="birthDate"
                    rules={{ required: "la date de naissance est requise" }}
                    control={control}
                    render={props =>
                      <DatePicker
                        date={patient.birthDate ? patient.birthDate : new Date() }
                        handleChange={(value) => handleChange("birthDate", value ) } />
                    }
                    defaultValue={patient.birthDate ? patient.birthDate : new Date()  }
                  />
                  {errors.birthDate && errors.birthDate.message ? <p className="error">{errors.birthDate.message}</p> : null }
                </div>

              </div>

            </div>

            <div className="border"></div>

            <div className="col-2">

              <div className="form-group">
                <p className="form-label">
                  <label htmlFor="pathology">Maladie</label>
                </p>
                <div className="form-content">
                  <Controller
                    name="pathology"
                    control={control}
                    render={props =>
                      <Select
                        styles={formSelect}
                        isClearable
                        defaultValue={patient.pathology ? pathologiesOptions.find(item => item.value === patient.pathology ) : null}
                        handleChange={(opt) => handleChange('pathology' , opt )}
                        options={pathologiesOptions}
                        />
                    }
                    defaultValue={patient.pathology ? pathologiesOptions.find(item => item.value === patient.pathology ) : null}
                  />
                  {errors.pathology && errors.pathology.message ? <p className="error">{errors.pathology.message}</p> : null }
                </div>
              </div>

              <div className="form-group">
                <p className="form-label">
                  <label htmlFor="type">Type</label>
                </p>
                <div className="form-content">
                  <Controller
                    name="type"
                    control={control}
                    render={props =>
                      <Select
                        styles={formSelect}
                        isClearable
                        defaultValue={patient.type ? typesOptions.find(item => item.value === patient.type ) : null}
                        handleChange={(opt) => handleChange('type' , opt )}
                        options={typesOptions || []}
                        />
                    }
                    defaultValue={patient.type ? typesOptions.find(item => item.value === patient.type ) : null}
                  />
                  {errors.type && errors.type.message ? <p className="error">{errors.type.message}</p> : null }
                </div>
              </div>
                    
              <div className="form-group">
                <p className="form-label">
                  <label htmlFor="subtype">Sous-type</label>
                </p>
                <div className="form-content">
                  <Controller
                    name="subtype"
                    control={control}
                    render={props =>
                      <Select
                        styles={formSelect}
                        isClearable
                        defaultValue={patient.subtype ? subtypesOptions?.find(item => item.value === patient?.subtype ) : null}
                        handleChange={(opt) => handleChange('subtype' , opt )}
                        options={subtypesOptions || []}
                        />
                    }
                    defaultValue={patient.subtype ? subtypesOptions?.find(item => item.value === patient?.subtype ) : null}
                  />
                  {errors.subtype && errors.subtype.message ? <p className="error">{errors.subtype.message}</p> : null }
                </div>
              </div>


              {mutationsList &&
                <div className="form-group">
                  <p className="form-label">
                    <label htmlFor="mutation">Mutation</label>
                  </p>
                  <div className="form-content">
                    <Controller
                      name="mutation"
                      control={control}
                      render={props =>
                        <Select
                          styles={formSelect}
                          isMulti
                          defaultValue={patient.mutation ? patient.mutation.map(item =>({ value: item , label: item })) : null}
                          handleChange={(value) => handleChange('mutation' , value )}
                          options={mutationsList}
                          />
                      }
                      defaultValue={patient.mutation ? patient.mutation.map(item =>({ value: item , label: item })) : null}
                      />
                    {errors.mutation && errors.mutation.message ? <p className="error">{errors.mutation.message}</p> : null }
                  </div>
                </div>
              }

                <div className="form-group">
                  <p className="form-label">
                    <label htmlFor="bloodGroup">Groupe sanguin</label>
                  </p>
                  <div className="form-content">
                  <input
                    name="bloodGroup"
                    ref={register}
                    onChange={(e) => handleChange('bloodGroup', e.target.value)}
                    defaultValue={patient.bloodGroup ? patient.bloodGroup : ""} />
                    {errors.bloodGroup && errors.bloodGroup.message ? <p className="error">{errors.bloodGroup.message}</p> : null }
                  </div>
                </div>

                <div className="form-group">
                  <p className="form-label">
                    <label htmlFor="bloodRhesus">Rhésus</label>
                  </p>
                  <div className="form-content">
                  <input
                    name="bloodRhesus"
                    ref={register}
                    onChange={(e) => handleChange('bloodRhesus', e.target.value)}
                    defaultValue={patient.bloodRhesus ? patient.bloodRhesus : ""} />
                    {errors.bloodRhesus && errors.bloodRhesus.message ? <p className="error">{errors.bloodRhesus.message}</p> : null }
                  </div>
                </div>
              
            </div>
           
          </div>

          {/* Score  Hémorragique  */}
          <div className="form-group score">
            <label>Score Hémorragique</label>
            <div className="score-list">
              {scoreList.map((num , i ) => 
                <button
                  key={"score" + i }
                  type='button'
                  onClick={() => handleChange('score' , num )}
                  className={`btn-score${score  && score === num ? " isActive" : ""}`}>
                  {num <= 5 ? num : '>5' }
                </button>
              )}
            </div>
          </div>

          <div className="form-group minirin">
            <label>Test minirin</label>
            <div className="select">
              <Controller
                name="minirin"
                control={control}
                render={props =>
                  <Select
                    styles={formSelect}
                    isClearable
                    defaultValue={patient.minirin ? minirinOptions.find(item => item.value === patient.minirin ) : null}
                    handleChange={(opt) => handleChange('minirin' , opt.value )}
                    options={minirinOptions}
                    />
                }
                defaultValue={patient.minirin ? minirinOptions.find(item => item.value === patient.minirin ) : null}
              />
            </div>
          </div>

          {/* INHIBITTORS */}
          <div className="form-group inhibitors">
            <div className="title">
              <label>Présence Inhibiteurs ({inhibitors.length})</label>
              <button
                onClick={() => setInhibitors([...inhibitors , {} ])}
                type="button"
                style={{ backgroundColor: color , margin: 0 }}
                className="btn-primary">
                ajouter un inhibiteur
              </button>
            </div>
            {inhibitors.length && inhibitors.map((item , i ) => 
              <Inhibitor
                key={'inhibitor' + i }
                data={item}
                index={i}
                protocolsOptions={protocolsOptions ? protocolsOptions : []}
                handleChange={(val) => handleChange('inhibitors' , val )}
                color={"#5F657C"} />
            )}
          </div>

          {/* TRAITEMENT EN COURS  */}
          <div className="form-group">
            <p className="form-label">
              <label htmlFor="currentTreatment">Traitements en cours</label>
            </p>
            <div className="form-content">
              <div className="text-list">
                {patientCurrentTreatments && patientCurrentTreatments.map((t , i) => {
                  return(
                    <div key={t + i } className="list-item">
                      <p className="text">{t}</p> 
                      <button
                        type="button"
                        onClick={() => handleChangeCurrentTreatments(t , "remove")}>
                        <VscChromeClose size={14} color={"#FFFFFF"}/>
                      </button>
                    </div>
                  )}
                )}
              </div>
              {addCurrentTreatments ?
                <Controller
                  name="currentTreatment"
                  control={control}
                  render={props =>
                    <Creatable
                      styles={formSelect}
                      placeholder={"Ajouter un traitement"}
                      formatCreateLabel={"Créer un nouveau traitement"}
                      handleChange={(val, action ) => handleChangeCurrentTreatments(val , action.action ) }
                      options={treatmentsOptions ? treatmentsOptions : []}
                      />
                  }
                />
                 :
                <button
                  onClick={() => setAddCurrentTreatments(true)}
                  type="button"
                  style={{ backgroundColor: color , margin: 0 }}
                  className="btn-primary">
                  ajouter un traitement en cours
                </button>
              }
            </div>
          </div>

           {/* LISTE DES TRAITEMENTS  */}
           <div className="form-group">
            <p className="form-label">
              <label htmlFor="treatments">Traitements</label>
            </p>
            <div className="form-content">
              <div className="text-list">
                {patientTreatments && patientTreatments.map((t , i) => {
                  return(
                    <div key={t + i } className="list-item">
                      <p className="text">{t}</p> 
                      <button
                        type="button"
                        onClick={() => handleChangeTreatments(t , "remove")}>
                        <VscChromeClose size={14} color={"#FFFFFF"}/>
                      </button>
                    </div>
                  )}
                )}
              </div>
              {addTreatments ?
                <Controller
                  name="treatments"
                  control={control}
                  render={props =>
                    <Creatable
                      styles={formSelect}
                      placeholder={"Ajouter un traitement"}
                      formatCreateLabel={"Créer un nouveau traitement"}
                      handleChange={(val, action ) => handleChangeTreatments(val , action.action ) }
                      options={treatmentsOptions ? treatmentsOptions : []}
                      />
                  }
                />
                 :
                <button
                  onClick={() => setAddTreatments(true)}
                  type="button"
                  style={{ backgroundColor: color , margin: 0 }}
                  className="btn-primary">
                  ajouter un traitement
                </button>
              }
            </div>
          </div>
          
          {/* LES DOSAGES  */}
          <div className="form-group">

            <p className="form-label">
              <label htmlFor="dosages">Dosages</label>
            </p>

            <div className="form-content">
              <div className="text-list">
                {patientDosages && patientDosages.map((dose , i) => {
                  return(
                    <div key={dose + i } className="list-item">
                      <p className="text">{dose.name} {dose.value} {dose.unit}</p> 
                      <button
                        type="button"
                        onClick={() => handleChangeDosages(dose , "remove")}>
                        <VscChromeClose size={14} color={"#FFFFFF"}/>
                      </button>
                    </div>
                  )}
                )}
              </div>
              {addDosage ?
                <Controller
                  name="dosages"
                  control={control}
                  render={() =>
                    <>
                      <div className="new-dosage-row">

                        <input
                          placeholder="nom"
                          value={newDosage.name ? newDosage.name : null }
                          onChange={(e) => setNewDosage({...newDosage  , name : e.target.value})}
                          />
                        <input
                          placeholder="quantité"
                          type="number"
                          value={newDosage.value ? newDosage.value : null }
                          onChange={(e) => setNewDosage({...newDosage  , value : e.target.value})}
                          />

                        <input
                          placeholder="unité"
                          value={newDosage.unit ? newDosage.unit : null }
                          onChange={(e) => setNewDosage({...newDosage  , unit : e.target.value})}
                          />

                      </div>
                      <button
                        onClick={() => handleChangeDosages(newDosage , "add")}
                        type="button"
                        style={{ backgroundColor: color , margin: 0 }}
                        className="btn-primary">
                        ajouter le dosage
                      </button>
                    </>   
                  }
                />
                :
                <button
                  onClick={() => setAddDosage(true)}
                  type="button"
                  style={{ backgroundColor: color , margin: 0 }}
                  className="btn-primary">
                  ajouter un dosage
                </button>
              }
            </div>
          </div>
          
          
          {/* Antecédents personnels  */}
          <div className="form-group">

            <p className="form-label">
              <label htmlFor="antecedents">Antecédents personnels</label>
            </p>

            <div className="form-content">
              <div className="text-list">
                {patientAntecedents && patientAntecedents.map((antecedent , i) => {
                  return(
                    <div key={antecedent + i } className="list-item">
                      <p className="text">{antecedent}</p> 
                      <button
                        type="button"
                        onClick={() => handleChangeAntecedents(antecedent , "remove")}>
                        <VscChromeClose size={14} color={"#FFFFFF"}/>
                      </button>
                    </div>
                  )}
                )}
              </div>
              {addAntecedents ?
                <Controller
                  name="antecedents"
                  control={control}
                  render={props =>
                    <Creatable
                      styles={formSelect}
                      placeholder={"Ajouter un antécedent"}
                      formatCreateLabel={"Créer un nouvel antécendent"}
                      handleChange={(val, action ) => handleChangeAntecedents(val , action.action ) }
                      options={antecedentsOptions? antecedentsOptions : []}
                      />
                  }
                />
                 :
                <button
                  onClick={() => setAddAntecedents(true)}
                  type="button"
                  style={{ backgroundColor: color , margin: 0 }}
                  className="btn-primary">
                  ajouter un antecedent
                </button>
              }
            </div>

          </div>

          {/* Antecédents familiaux  */}
          <div className="form-group">

            <p className="form-label">
              <label htmlFor="familialAntecedents">Antecédents familiaux</label>
            </p>

            <div className="form-content">
              <div className="text-list">
                {patientFamilialAntecedents && patientFamilialAntecedents.map((antecedent , i) => {
                  return(
                    <div key={antecedent + i } className="list-item">
                      <p className="text">{antecedent}</p> 
                      <button
                        type="button"
                        onClick={() => handleChangeFamilialAntecedents(antecedent , "remove")}>
                        <VscChromeClose size={14} color={"#FFFFFF"}/>
                      </button>
                    </div>
                  )}
                )}
              </div>
              {addFamilialAntecedents ?
                <Controller
                  name="familialAntecedents"
                  control={control}
                  render={props =>
                    <Creatable
                      styles={formSelect}
                      placeholder={"Ajouter un antécedent"}
                      formatCreateLabel={"Créer un nouvel antécendent"}
                      handleChange={(val, action ) => handleChangeFamilialAntecedents(val , action.action ) }
                      options={familialAntecedentsOptions ? familialAntecedentsOptions : []}
                      />
                  }
                />
                 :
                <button
                  onClick={() => setAddFamilialAntecedents(true)}
                  type="button"
                  style={{ backgroundColor: color , margin: 0 }}
                  className="btn-primary">
                  ajouter un antecedent familial
                </button>
              }
            </div>

          </div>
          
          {/* SYPMTOMS  */}
          <div className="form-group">
            <p className="form-label">
              <label htmlFor="symptoms">Symptomes</label>
            </p>
            <div className="form-content">
              <div className="text-list">
                {patientSymptoms && patientSymptoms.map((symptom , i) => {
                  return(
                    <div key={symptom + i } className="list-item">
                      <p className="text">{symptom}</p> 
                      <button
                        type="button"
                        onClick={() => handleChangeSymptoms(symptom , "remove")}>
                        <VscChromeClose size={14} color={"#FFFFFF"}/>
                      </button>
                    </div>
                  )}
                )}
              </div>
              {addSymptom ?
                <Controller
                  name="symptoms"
                  control={control}
                  render={props =>
                    <Creatable
                      styles={formSelect}
                      placeholder={"Ajouter un symptome"}
                      formatCreateLabel={"Créer un nouveau symptome"}
                      handleChange={(val, action ) => handleChangeSymptoms(val , action.action ) }
                      options={symptomsOptions ? symptomsOptions : []}
                      />
                  }
                />
                 :
                <button
                  onClick={() => setAddSymptom(true)}
                  type="button"
                  style={{ backgroundColor: color , margin: 0 }}
                  className="btn-primary">
                  ajouter un symptome
                </button>
              }
            </div>
          </div>


          {/* GENEALOGIE  */}
          <div className="form-group">

            <p className="form-label">
              <label htmlFor="parents">Généalogie</label>
            </p>

            <div className="form-content">
              <EditGenalogy
                patient={patient}
                patientsList={context.patient.patientsList}
                color={color}
                dispatch={dispatch}
                setIsCreate={setIsCreate}
                handleChange={(val) => handleChange("parents" , val )}
                findPathologie={findPathologie}
                isPreview={false}/>
            </div>

          </div>

           {/* COMMENTS  */}
          <div className="form-group">
            <p className="form-label">
              <label htmlFor="symptoms">Commentaires</label>
            </p>
            <div className="form-content">
              <div className="text-list">
                {patientComments && patientComments.length && patientComments.map((comment, i) => {
                  return(
                    <div key={comment+ i } className="list-item">
                      <p className="text">{comment}</p> 
                      <button
                        type="button"
                        onClick={() => handleChangeComment(comment, "remove")}>
                        <VscChromeClose size={14} color={"#FFFFFF"}/>
                      </button>
                    </div>
                  )}
                )}
              </div>
              {addComment ?
                  <>
                    <textarea
                      value={comment ? comment : ""}
                      onChange={(e) => setComment(e.target.value)}
                      />
                    <button
                      onClick={() => handleChangeComment(comment, "add") }
                      type="button"
                      style={{ backgroundColor: color , margin: 0 }}
                      className="btn-primary">
                      valider
                    </button>
                  </>
                 :
                  <button
                    onClick={() => setAddComment(true)}
                    type="button"
                    style={{ backgroundColor: color , margin: 0 }}
                    className="btn-primary">
                    ajouter un commentaire
                  </button>
              }
            </div>
          </div>

          {/* Legal  */}
          <div className="form-group legal">

            <p className="form-label">
              <label htmlFor="parents">Légal</label>
            </p>

            <div className="form-content">
              <Controller
                name="informationNote"
                control={control}
                defaultValue={patient.informationNote ? true : false}
                render={props =><CheckBox
                  defaultValue={patient.informationNote ? true : false}
                  onChange={(val) => handleChange('informationNote' , val )}
                  label={"Note d’information donnée au patient"}/> 
                }
              />
              <Controller
                name="consent"
                control={control}
                defaultValue={patient.consent ? true : false}
                render={props =><CheckBox
                  defaultValue={patient.consent ? true : false}
                  onChange={(val) => handleChange('consent' , val )}
                  label={"Consentement obtenu pour la génétique"}/> 
                }
              />
              <p className="note">Modèle de notice d’information et de consentement que vous pouvez remettre au patient après validation et personnalisation du contenu par vos soins en exécution de votre obligation d’information en tant que responsable de traitement. L’information et le recueil du consentement doivent avoir lieu avant la collecte et le traitement des données de vos patients y compris au sein de l’outil digital de visualisation des données dénommé « GENEALOVIZ »</p>
            </div>
          </div>
          <div className="form-group delete">
            {!isDelete ?
              <button
                type="button"
                className="btn-edit" onClick={() => setIsDelete(true)} >
                supprimer le patient
              </button>
              :
              <>
                <button
                  type="button"
                  className="btn-edit delete" onClick={() => deletePatientAction(dispatch , patient._id )} >
                  confirmer la suppression
                </button>
                <button
                  type="button"
                  className="btn-edit" onClick={() => setIsDelete(false)} >
                  annuler
                </button>
              </>
            }
          </div>

        </div>
      </form>
    )
  }

  function renderCreate() {
    return (
      <form>
        <div className="header patient">
 
          <div>
            <div style={{ marginBottom: 10 }}>
              <label htmlFor="code">Numero du patient</label>
              <input
                name="code"
                ref={register({ required: "le code patient est requis" })}
                onChange={(e) => handleChange('code', e.target.value)}
                className="code"
                defaultValue={""} />
              {errors.code && errors.code.message ? <p className="error">{errors.code.message}</p> : null }
            </div>

            <label style={{ marginBottom : 5 , display: 'block'}} htmlFor="diagnosticDate">Date de diagnostic</label>
            
            <Controller
              name="diagnosticDate"
              control={control}
              // rules={{ required: "la date de diagnostic est requise" }}
              render={props =>
                <DatePicker
                  date={formData.diagnosticDate ? formData.diagnosticDate  : null }
                  handleChange={(value) => handleChange("diagnosticDate", value ) } />
              }
              defaultValue={formData.diagnosticDate ? formData.diagnosticDate  : null }
            />
            {errors.diagnosticDate && errors.diagnosticDate.message ? <p className="error">{errors.diagnosticDate.message}</p> : null }

          </div>

          <div>
            <button
              type="button"
              className="btn-edit" onClick={(e) => createPatient(e)} >
              valider
            </button>
          </div>
   
        </div>

        <div className="panel-content patient">
          
          {/* LES INFOS PATIENT  */}
          <div className="patient-infos">

            <div className="col-2">

              <div className="form-group">
                <p className="form-label">
                  <label htmlFor="gender">Genre</label>
                </p>
                <div className="form-content">
                  <Controller
                    name="gender"
                    rules={{ required: "le genre est requis" }}
                    control={control}
                    render={props =>
                      <Select
                        styles={formSelect}
                        defaultValue={null}
                        handleChange={(opt) => handleChange('gender' , opt.value )}
                        options={genderOptions}
                        />
                    }
                    defaultValue={null}
                  />
                  {errors.gender && errors.gender.message ? <p className="error">{errors.gender.message}</p> : null }
                
                    
                  {formData.gender === "Femme" && findPathologie(formData.pathology) && findPathologie(formData.pathology).name === "Hemophilie A" && 
                    <div style={{ marginTop: 10 }}>
                      <Controller
                        name="conductor"
                        control={control}
                        defaultValue={formData.conductor ? true : false}
                        render={props => <CheckBox
                          defaultValue={formData.conductor ? true : false}
                          onChange={(val) => handleChange('conductor' , val )}
                          label={"Conductrice"}/> 
                        }
                      />
                    </div>
                  } 
                </div>
              </div>

              <div className="form-group">
                <p className="form-label">
                  <label htmlFor="localisation">Localisation</label>
                </p>
                <div className="form-content">
                  <Controller
                    name="localisation"
                    rules={{ required: "la localisation est requise" }}
                    control={control}
                    render={props =>
                      <Select
                        styles={formSelect}
                        defaultValue={null}
                        handleChange={(opt) => handleChange('localisation' , opt.value )}
                        options={localisationOptions}
                        />
                    }
                    defaultValue={null}
                  />
                  {errors.localisation && errors.localisation.message ? <p className="error">{errors.localisation.message}</p> : null }
                </div>
              </div>

              <div className="form-group">
                <p className="form-label">
                  <label htmlFor="originCountry">Pays d'origine</label>
                </p>
                <div className="form-content">
                <input
                  name="originCountry"
                  ref={register}
                  onChange={(e) => handleChange('originCountry', e.target.value)}
                  defaultValue={formData.originCountry ? formData.originCountry : ""} />
                  {errors.originCountry && errors.originCountry.message ? <p className="error">{errors.originCountry.message}</p> : null }
                </div>
              </div>

              <div className="form-group">
                <p className="form-label">
                  <label htmlFor="birthDate">Date de naissance</label>
                </p>
                <div className="form-content">
                  <Controller
                    name="birthDate"
                    rules={{ required: "la date de naissance est requise" }}
                    control={control}
                    render={props =>
                      <DatePicker
                        date={formData.birthDate ? formData.birthDate :  null} 
                        handleChange={(value) => handleChange("birthDate", value ) } />
                    }
                    defaultValue={formData.birthDate ? formData.birthDate :  null} 
                  />
                  {errors.birthDate && errors.birthDate.message ? <p className="error">{errors.birthDate.message}</p> : null }
                </div>

              </div>

            </div>

            <div className="border"></div>

            <div className="col-2">

              <div className="form-group">
                <p className="form-label">
                  <label htmlFor="pathology">Maladie</label>
                </p>
                <div className="form-content">
                  <Controller
                    name="pathology"
                    // rules={{ required: "la maladie est requise" }}
                    control={control}
                    render={props =>
                      <Select
                        styles={formSelect}
                        defaultValue={formData.pathology ? pathologiesOptions.find(item => item.value === formData.pathology ) : null}
                        handleChange={(opt) => handleChange('pathology' , opt )}
                        options={pathologiesOptions}
                        />
                    }
                    defaultValue={formData.pathology ? pathologiesOptions.find(item => item.value === formData.pathology ) : null}
                  />
                  {errors.pathology && errors.pathology.message ? <p className="error">{errors.pathology.message}</p> : null }
                </div>
              </div>

              {mutationsList &&
                <div className="form-group">
                  <p className="form-label">
                    <label htmlFor="mutation">Mutation</label>
                  </p>
                  <div className="form-content">
                    <Controller
                      name="mutation"
                      control={control}
                      render={props =>
                        <Select
                          styles={formSelect}
                          isMulti
                          defaultValue={formData.mutation ? mutationsList.find(item => item.value === formData.mutation ) : null}
                          handleChange={(opt) => handleChange('mutation' , opt.value )}
                          options={mutationsList}
                          />
                      }
                      defaultValue={formData.mutation ? mutationsList.find(item => item.value === formData.mutation ) : null}
                    />
                    {errors.mutation && errors.mutation.message ? <p className="error">{errors.mutation.message}</p> : null }
                  </div>
                </div>
              }

                <div className="form-group">
                  <p className="form-label">
                    <label htmlFor="bloodGroup">Groupe sanguin</label>
                  </p>
                  <div className="form-content">
                  <input
                    name="bloodGroup"
                    ref={register}
                    onChange={(e) => handleChange('bloodGroup', e.target.value)}
                    defaultValue={formData.bloodGroup ? formData.bloodGroup : ""} />
                    {errors.bloodGroup && errors.bloodGroup.message ? <p className="error">{errors.bloodGroup.message}</p> : null }
                  </div>
                </div>

                <div className="form-group">
                  <p className="form-label">
                    <label htmlFor="bloodRhesus">Rhésus</label>
                  </p>
                  <div className="form-content">
                  <input
                    name="bloodRhesus"
                    ref={register}
                    onChange={(e) => handleChange('bloodRhesus', e.target.value)}
                    defaultValue={formData.bloodRhesus ? formData.bloodRhesus : ""} />
                    {errors.bloodRhesus && errors.bloodRhesus.message ? <p className="error">{errors.bloodRhesus.message}</p> : null }
                  </div>
                </div>
              
            </div>
           
          </div>

          {/* Score  Hémorragique  */}
          <div className="form-group score">
            <label>Score Hémorragique</label>
            <div className="score-list">
              {scoreList.map((num , i ) => 
                <button
                  key={"score" + i }
                  type='button'
                  onClick={() => handleChange('score' , num )}
                  className={`btn-score${score  && score === num ? " isActive" : ""}`}>
                  {num <= 5 ? num : '>5' }
                </button>
              )}
            </div>
          </div>

          <div className="form-group minirin">
            <label>Test minirin</label>
            <div className="select">
              <Controller
                name="minirin"
                control={control}
                render={props =>
                  <Select
                    styles={formSelect}
                    isClearable
                    defaultValue={formData.minirin ? minirinOptions.find(item => item.value === formData.minirin ) : null}
                    handleChange={(opt) => handleChange('minirin' , opt.value )}
                    options={minirinOptions}
                    />
                }
                defaultValue={formData.minirin ? minirinOptions.find(item => item.value === formData.minirin ) : null}
              />
            </div>
          </div>

          {/* INHIBITTORS */}
          <div className="form-group inhibitors">
            <div className="title">
              <label>Présence Inhibiteurs ({inhibitors.length})</label>
              <button
                onClick={() => setInhibitors([...inhibitors , {} ])}
                type="button"
                style={{ backgroundColor: color , margin: 0 }}
                className="btn-primary">
                ajouter un inhibiteur
              </button>
            </div>
            {inhibitors.length && inhibitors.map((item , i ) => 
              <Inhibitor
                key={'inhibitor' + i }
                data={item}
                index={i}
                protocolsOptions={protocolsOptions ? protocolsOptions : []}
                handleChange={(val) => handleChange('inhibitors' , val )}
                color={"#5F657C"} />
            )}
          </div>

          {/* TRAITEMENT  */}
          <div className="form-group">
            <p className="form-label">
              <label htmlFor="currentTreatment">Traitements en cours</label>
            </p>
            <div className="form-content">
              <div className="text-list">
                {patientCurrentTreatments && patientCurrentTreatments.map((t , i) => {
                  return(
                    <div key={t + i } className="list-item">
                      <p className="text">{t}</p> 
                      <button
                        type="button"
                        onClick={() => handleChangeCurrentTreatments(t , "remove")}>
                        <VscChromeClose size={14} color={"#FFFFFF"}/>
                      </button>
                    </div>
                  )}
                )}
              </div>
              {addCurrentTreatments ?
                <Controller
                  name="currentTreatment"
                  control={control}
                  render={props =>
                    <Creatable
                      styles={formSelect}
                      placeholder={"Ajouter un traitement"}
                      formatCreateLabel={"Créer un nouveau traitement"}
                      handleChange={(val, action ) => handleChangeCurrentTreatments(val , action.action ) }
                      options={treatmentsOptions ? treatmentsOptions : []}
                      />
                  }
                />
                 :
                <button
                  onClick={() => setAddCurrentTreatments(true)}
                  type="button"
                  style={{ backgroundColor: color , margin: 0 }}
                  className="btn-primary">
                  ajouter un traitement en cours
                </button>
              }
            </div>
          </div>

           {/* LISTE DES TRAITEMENTS  */}
           <div className="form-group">
            <p className="form-label">
              <label htmlFor="treatments">Traitements</label>
            </p>
            <div className="form-content">
              <div className="text-list">
                {patientTreatments && patientTreatments.map((t , i) => {
                  return(
                    <div key={t + i } className="list-item">
                      <p className="text">{t}</p> 
                      <button
                        type="button"
                        onClick={() => handleChangeTreatments(t , "remove")}>
                        <VscChromeClose size={14} color={"#FFFFFF"}/>
                      </button>
                    </div>
                  )}
                )}
              </div>
              {addTreatments ?
                <Controller
                  name="treatments"
                  control={control}
                  render={props =>
                    <Creatable
                      styles={formSelect}
                      placeholder={"Ajouter un traitement"}
                      formatCreateLabel={"Créer un nouveau traitement"}
                      handleChange={(val, action ) => handleChangeTreatments(val , action.action ) }
                      options={treatmentsOptions ? treatmentsOptions : []}
                      />
                  }
                />
                 :
                <button
                  onClick={() => setAddTreatments(true)}
                  type="button"
                  style={{ backgroundColor: color , margin: 0 }}
                  className="btn-primary">
                  ajouter un traitement
                </button>
              }
            </div>
          </div>
          
          {/* LES DOSAGES  */}
          <div className="form-group">

            <p className="form-label">
              <label htmlFor="dosages">Dosages</label>
            </p>

            <div className="form-content">
              <div className="text-list">
                {patientDosages && patientDosages.map((dose , i) => {
                  return(
                    <div key={dose + i } className="list-item">
                      <p className="text">{dose.name} {dose.value} {dose.unit}</p> 
                      <button
                        type="button"
                        onClick={() => handleChangeDosages(dose , "remove")}>
                        <VscChromeClose size={14} color={"#FFFFFF"}/>
                      </button>
                    </div>
                  )}
                )}
              </div>
              {addDosage ?
                <Controller
                  name="dosages"
                  control={control}
                  render={props =>
                    <>
                    <div className="new-dosage-row">

                      <input
                        placeholder="nom"
                        value={newDosage.name ? newDosage.name : null }
                        onChange={(e) => setNewDosage({...newDosage  , name : e.target.value})}
                        />
                      <input
                        placeholder="quantité"
                        type="number"
                        value={newDosage.value ? newDosage.value : null }
                        onChange={(e) => setNewDosage({...newDosage  , value : e.target.value})}
                        />

                      <input
                        placeholder="unité"
                        value={newDosage.unit ? newDosage.unit : null }
                        onChange={(e) => setNewDosage({...newDosage  , unit : e.target.value})}
                        />

                    </div>
                    <button
                      onClick={() => handleChangeDosages(newDosage , "add")}
                      type="button"
                      style={{ backgroundColor: color , margin: 0 }}
                      className="btn-primary">
                      ajouter le dosage
                    </button>
                    </>   
                  }
                />
                :
                <button
                  onClick={() => setAddDosage(true)}
                  type="button"
                  style={{ backgroundColor: color , margin: 0 }}
                  className="btn-primary">
                  ajouter un dosage
                </button>
              }
            </div>
          </div>
          
          
          {/* Antecédents personnels */}
          <div className="form-group">

            <p className="form-label">
              <label htmlFor="antecedents">Antecédents personnels</label>
            </p>

            <div className="form-content">
              <div className="text-list">
                {patientAntecedents && patientAntecedents.map((antecedent , i) => {
                  return(
                    <div key={antecedent + i } className="list-item">
                      <p className="text">{antecedent}</p> 
                      <button
                        type="button"
                        onClick={() => handleChangeAntecedents(antecedent , "remove")}>
                        <VscChromeClose size={14} color={"#FFFFFF"}/>
                      </button>
                    </div>
                  )}
                )}
              </div>
              {addAntecedents ?
                <Controller
                  name="antecedents"
                  control={control}
                  render={props =>
                    <Creatable
                      styles={formSelect}
                      placeholder={"Ajouter un antécedent"}
                      formatCreateLabel={"Créer un nouvel antécendent"}
                      handleChange={(val, action ) => handleChangeAntecedents(val , action.action ) }
                      options={antecedentsOptions? antecedentsOptions : []}
                      />
                  }
                />
                 :
                <button
                  onClick={() => setAddAntecedents(true)}
                  type="button"
                  style={{ backgroundColor: color , margin: 0 }}
                  className="btn-primary">
                  ajouter un antecedent
                </button>
              }
            </div>

          </div>

          {/* Antecédents familiaux  */}
          <div className="form-group">

            <p className="form-label">
              <label htmlFor="familialAntecedents">Antecédents familiaux</label>
            </p>

            <div className="form-content">
              <div className="text-list">
                {patientFamilialAntecedents && patientFamilialAntecedents.map((antecedent , i) => {
                  return(
                    <div key={antecedent + i } className="list-item">
                      <p className="text">{antecedent}</p> 
                      <button
                        type="button"
                        onClick={() => handleChangeFamilialAntecedents(antecedent , "remove")}>
                        <VscChromeClose size={14} color={"#FFFFFF"}/>
                      </button>
                    </div>
                  )}
                )}
              </div>
              {addFamilialAntecedents ?
                <Controller
                  name="familialAntecedents"
                  control={control}
                  render={props =>
                    <Creatable
                      styles={formSelect}
                      placeholder={"Ajouter un antécedent"}
                      formatCreateLabel={"Créer un nouvel antécendent"}
                      handleChange={(val, action ) => handleChangeFamilialAntecedents(val , action.action ) }
                      options={familialAntecedentsOptions ? familialAntecedentsOptions : []}
                      />
                  }
                />
                :
                <button
                  onClick={() => setAddFamilialAntecedents(true)}
                  type="button"
                  style={{ backgroundColor: color , margin: 0 }}
                  className="btn-primary">
                  ajouter un antecedent familial
                </button>
              }
            </div>

          </div>
          
          
          {/* LES SYPMTOMS  */}
          <div className="form-group">
            <p className="form-label">
              <label htmlFor="symptoms">Symptomes</label>
            </p>
            <div className="form-content">
              <div className="text-list">
                {patientSymptoms && patientSymptoms.map((symptom , i) => {
                  return(
                    <div key={symptom + i } className="list-item">
                      <p className="text">{symptom}</p> 
                      <button
                        type="button"
                        onClick={() => handleChangeSymptoms(symptom , "remove")}>
                        <VscChromeClose size={14} color={"#FFFFFF"}/>
                      </button>
                    </div>
                  )}
                )}
              </div>
              {addSymptom ?
                <Controller
                  name="symptoms"
                  control={control}
                  render={props =>
                    <Creatable
                      styles={formSelect}
                      placeholder={"Ajouter un symptome"}
                      formatCreateLabel={"Créer un nouveau symptome"}
                      handleChange={(val, action ) => handleChangeSymptoms(val , action.action ) }
                      options={symptomsOptions ? symptomsOptions : []}
                      />
                  }
                />
                 :
                <button
                  onClick={() => setAddSymptom(true)}
                  type="button"
                  style={{ backgroundColor: color , margin: 0 }}
                  className="btn-primary">
                  ajouter un symptome
                </button>
              }
            </div>
          </div>

          {/* Legal  */}
          <div className="form-group legal">

            <p className="form-label">
              <label htmlFor="parents">Légal</label>
            </p>

            <div className="form-content">
              <Controller
                name="informationNote"
                control={control}
                defaultValue={formData.informationNote ? true : false}
                render={props => <CheckBox
                  defaultValue={formData.informationNote ? true : false}
                  onChange={(val) => handleChange('informationNote' , val )}
                  label={"Note d’information donnée au patient"}/> 
                }
              />
              <Controller
                name="consent"
                control={control}
                defaultValue={formData.consent ? true : false}
                render={props => <CheckBox
                  defaultValue={formData.consent ? true : false}
                  onChange={(val) => handleChange('consent' , val )}
                  label={"Consentement obtenu pour la génétique"}/> 
                }
              />
            </div>

            </div>

        </div>
      </form>
    )
  }

  return (
    <aside className={`panel ${isOpen ? "isOpen" : ""} ${isEdit ? "isEdit" : ""}`}>
      <button className="btn-close" onClick={() => close()} >
        <VscChromeClose size={22} color={isEdit ? "#FFFFFF":"#000000"}/>
      </button>
      {!isCreate && patient && <>{isEdit ? renderEdit() : renderPreview()}</>}
      {isCreate && renderCreate()}
    </aside>
  )
}
