import React , { useState , useEffect , useRef } from 'react';
import { HexColorPicker } from "react-colorful";
import "react-colorful/dist/index.css";

import { useGlobalContext } from '../../../../context/GlobalContext';
import { VscChromeClose } from "react-icons/vsc";
import { putPathologyAction } from "../../../../actions/pathologyActions";
import EditMutations from './EditMutations';
import { brighterColorScale } from '../../../../utils/utils';

export default function PathologyPanel({ isOpen , close }) {

  const [ context, dispatch ] = useGlobalContext();

  const { pathology } = context.pathology;
  const [ prevPathology , setPrevPathology ] = useState()

  const [ formData , setFormData ] = useState({ color : "#4744BA" });
  
  const [ isEdit , setIsEdit ] = useState(false);

  const autoSaveTimer = useRef();

  useEffect(() => {
    if (pathology) {
      if(pathology.color) {
        setFormData({...formData , color: pathology.color })
      }
      if (pathology && prevPathology !== pathology._id) {
        setIsEdit(false);
        setPrevPathology(pathology._id);
      }
    }
    // eslint-disable-next-line
  }, [pathology]);

  useEffect(() => {
    if (!isOpen) setIsEdit(false);
  }, [isOpen]);

  useEffect(() => {
    if (context.pathology.message === "Pathology updated") {
      dispatch({
        type: 'SET_TOAST',
        payload: {
          message: "La maladie a été modifiée",
          type: "success"
        }
      });
      dispatch({
        type:'MESSAGE_PATHOLOGY',
        payload: null
      });
    }
     // eslint-disable-next-line
  }, [context.pathology.message])

  function handleChange(key , value ) {
    clearTimeout(autoSaveTimer.current)
    autoSaveTimer.current = setTimeout(() => putPathologyAction(dispatch , pathology._id , formData ), 3000)
    switch (key) {
      case "color":
        return setFormData({...formData , color: value });
      case "description":
        return setFormData({...formData , description: value });
      case "mutations":
        return setFormData({...formData , mutations: value });
      default:
        return;
    }
  }

  function renderPreview() {
    return (
      <>
        <div className="header">

          <div>
            <h3 className="grey-label">{pathology.category} - {pathology.subcategory}</h3>
            <h2>{pathology.name}</h2>
            <div className="color-label" style={{ backgroundColor: pathology.color ? pathology.color : "#4744BA" }}></div>
          </div>

          <div>
            {/* <button className="btn-edit"
              onClick={() => setIsEdit(true)} >
              éditer
            </button> */}
          </div>
   
        </div>

        <div className="panel-content">

          <div className="form-group row">
            <p><label>Sous-type </label></p> <p>{pathology.type}</p>
          </div>

          <div className="form-group row">
            <p><label>Nombre de patients </label></p> <p>0</p>
          </div>

          <div className="form-group col">
            <p className="form-label">
              <label>Description</label>
            </p>
            <div className="form-content">
              <p>{pathology.description}</p>
            </div>
          </div>

          <div className="form-group mutations">
            <p className="form-label">
              <label><span className="number">{pathology.mutations.length}</span> Mutations</label>
            </p>
            <div className="form-content">
              <div className="text-list">
                {pathology.mutations.map((mutation , i) => <p
                  key={mutation + i }
                  style={{ backgroundColor: brighterColorScale(pathology.color ? pathology.color : "#4744BA" , pathology.mutations.length , i )}}
                  >{mutation}</p>
                )}
              </div>
            </div>
          </div>
        
        </div>
      </>
    )
  }

  function renderEdit() {
    return (
      <form>
        <div className="header">

          <div>
            <h3 className="grey-label">{pathology.category} - {pathology.subcategory}</h3>
            <h2>{pathology.name}</h2>
            <div className="color-label" style={{ backgroundColor: pathology.color ? pathology.color : "#4744BA"  }}></div>
          </div>

          <div>
            <button
              className="btn-edit"
              onClick={() => setIsEdit(false)} >
              retour
            </button>
          </div>
   
        </div>

        <div className="panel-content">

          <div className="form-group">
            <p className="form-label">
              <label>Description</label>
            </p>
            <div className="form-content">
              <textarea
                onChange={(e) => handleChange("description" , e.target.value)}
                defaultValue={pathology.description}  
              />
            </div>
          </div>

          <div className="form-group">
            <p className="form-label">
              <label>Mutations</label>
            </p>
            <div className="form-content">
              <EditMutations
                color={pathology.color}
                mutations={pathology.mutations}
                handleChange={handleChange}
                />
            </div>
          </div>

          <div className="form-group">
            <p className="form-label">
              <label>Couleur</label>
            </p>
            <div className="form-content">
              <HexColorPicker color={formData.color} onChange={(color) => handleChange("color" , color )} />
            </div>
          </div>

        </div>
      </form>
    )
  }

  return (
    <aside className={`panel ${isOpen ? "isOpen" : ""} ${isEdit ? "isEdit" : ""}`}>
      <button className="btn-close" onClick={() => close()} >
        <VscChromeClose size={22} color={isEdit ? "#FFFFFF":"#000000"}/>
      </button>
      {pathology && <>{isEdit ? renderEdit() : renderPreview()}</>}
    </aside>
  )
}
