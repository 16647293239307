import React from 'react';
import { getPathologyAction } from '../../../actions/pathologyActions';
import { getPatientAction } from '../../../actions/patientsActions';
import './card.scss';

export default function Card({ type , card , dispatch , context , setIsCreate , activeId }) {
  
  const { pathologiesList } = context.pathology;

  function getPatient() {
    setIsCreate(false);
    getPatientAction(dispatch , card._id)
    dispatch({
      type:'GET_PATIENT',
      payload: null
    })
  }

  function renderCard(type) {
    switch (type) {
      case "pathology":

        return (
          <div>
            <div className="color-label" style={{ backgroundColor: card.color ? card.color : "#4744BA" }}></div>
            <div
              onClick={() => getPathologyAction(dispatch , card._id)}
              className={`card pathology ${activeId && activeId === card._id ? "isActive" : ""}`}>

              <div>
                <p className="category grey-label">{card.category} - {card.subcategory}</p>
                <h3>{card.name}</h3>
              </div>
              
              <div>
                <div className="info">
                  <p className="label">Mutations</p>
                  <p className="value">{card.mutations.length}</p>
                </div>

                <div className="info">
                  <p className="label">Patients</p>
                  <p className="value">{card.patientsCount}</p>
                </div>

              </div>

            </div>
          </div>
        );

      case "patient":
        var pathology = pathologiesList && pathologiesList.find(item => item._id === card.pathology);
        return (
          <div>
            <div className="color-label" style={{ backgroundColor: pathology && pathology.color ? pathology.color : "transparent"}}></div>
            <div
              onClick={() => getPatient()}
              className={`card patient ${activeId && activeId === card._id ? "isActive" : ""}`}>
              <div className="code-row">
                <h3>{card.code}</h3> <p>{card.gender}</p>
              </div>
              
              <div>
                <div className="info">
                  <p className="label">Localistation</p>
                  <p className="value">{card.localisation && card.localisation.city}</p>
                </div>

                <div className="info">
                  <p className="label">Maladie</p>
                  <p className="value">{pathology && pathology.name ? `${pathology.name}` :  "pas de maladie" }</p>
                </div>

                {card.mutation && card.mutation.length > 0 &&
                  <div className="info">
                    <p className="label">Mutations</p>
                    <p className="value">{card.mutation.length > 1 ? <span>{card.mutation.length} mutations</span> : card.mutation  }</p>
                  </div>
                }
              </div>

            </div>
          </div>
        );
    
      default:
        return null;
    }
  } 
  return (
    <>
     {renderCard(type)}
    </>
  )
}
