import React , { useEffect , useCallback , useState } from 'react';
import { useGlobalContext } from '../../../context/GlobalContext';
import { getUserAuthAction } from '../../../actions/authActions';
import { postPatientFiltredAction } from '../../../actions/patientsActions';
import { getPathologiesAction } from '../../../actions/pathologyActions';
import Card from '../../partials/Card/Card';
import PatientPanel from './partial/PatientPanel';
import Filters from '../../partials/Filters/Filters';

export default function Patients({ setActivePage }) {

  const [ context, dispatch ] = useGlobalContext();
  const patientsByPage = 12

  const [ panelIsOpen , setPanelIsOpen ] = useState(false);
  const [ isCreate , setIsCreate ] = useState(false);
  const [ page , setPage ] = useState(1);
  const [ filters , setFilters ] = useState({})

  const { patientsList , patient , count } = context.patient

  const getData = useCallback(() => {
    getUserAuthAction(dispatch);
    postPatientFiltredAction(dispatch , { page:  page , number: patientsByPage });
    getPathologiesAction(dispatch);
  },[dispatch , page]);

  const handleChangeFilters = useCallback(() => {
    setPage(1);
    postPatientFiltredAction(dispatch , {...filters, page:  1 , number: patientsByPage });
  }, [dispatch , filters])

  useEffect(() => {
    setActivePage("patient");
    getData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (patient) {
      setPanelIsOpen(true);
    }
  },[patient]);

  useEffect(() => {
    handleChangeFilters();
  }, [filters , handleChangeFilters]) 

  function handleScroll(e) {
    let element = e.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight && patientsList.length < count ) {
      let nextPage = page + 1
      setPage(nextPage);
      postPatientFiltredAction(dispatch , {...filters, page:  nextPage , number: patientsByPage });
    }
  }

  function closePatientPanel() {
    setPanelIsOpen(false);
    setIsCreate(false);
    dispatch({
      type:'GET_PATIENT',
      payload: null
    })
  }

  function createPatient() {
    setPanelIsOpen(true);
    setIsCreate(true);
    dispatch({
      type:'GET_PATIENT',
      payload: null
    })
  }

  function renderList() {
    let type = "patient";
    return patientsList.map((item , i ) => <Card
      key={item._id}
      type={type}
      context={context}
      setIsCreate={setIsCreate}
      card={item}
      activeId={patient && patient._id}
      dispatch={dispatch}/> )
  }

  return (
    <>
      <div onScroll={(e) => handleScroll(e)} className="page-container">
        {!panelIsOpen && <Filters filters={filters} setFilters={setFilters} />}
        <div className={`grid-container ${panelIsOpen ? "panel-open" : ""}`}>
          <div className={`content cards-grid ${panelIsOpen ? "panel-open" : ""}`}>
            <div>
              <button
                onClick={() => createPatient()}
                className="add-card">
                <h3>
                  NOUVEAU<br/>
                  PATIENT
                </h3>
              </button>
            </div>

            {patientsList && renderList()}
          </div>
        </div>

      </div>
      <PatientPanel
        isCreate={isCreate}
        setIsCreate={setIsCreate}
        isOpen={panelIsOpen}
        close={() => closePatientPanel()}
        />
    </>
  )
}
