import React , { useState } from 'react';
import { VscChromeClose } from "react-icons/vsc";

export default function EditMutations({ mutations , handleChange , color }) {

  const [ mutationsForm , setMutationForm ] = useState(mutations);

  function changeMutation(value , i) {
    let mutationList = [...mutationsForm];
    mutationList[i] = value
    setMutationForm(mutationList);
    handleChange("mutations" , mutationList);
  }

  function addMutation() {
    let mutationList = [...mutationsForm];
    mutationList.push("");
    setMutationForm(mutationList);
    handleChange("mutations" , mutationList);
  }

  function removeMutation(value) {
    let mutationList = mutationsForm.filter(item => item !== value );
    setMutationForm(mutationList);
    handleChange("mutations" , mutationList);
  }

  return (
    <div>
      {mutationsForm.map((item , i) => (
        <div
          key={"mutation" + i}
          className="edit-mutation-row">
          <input defaultValue={item} onChange={(e) => changeMutation(e.target.value , i ) }/>
          <button
            onClick={() => removeMutation(item)}
            type="button"
            >
            <VscChromeClose size={22} color={"#FFFFFF"}/>
          </button>
        </div>
        )
      )}
      <button
        onClick={() => addMutation()}
        type="button"
        style={{ backgroundColor: color }}
        className="btn-primary">
        ajouter une mutation
      </button>
    </div>
  )
}
