import React ,{ useEffect , useState , useCallback } from 'react';

import { useGlobalContext } from '../../../../context/GlobalContext';

import PatientPanel from '../../patients/partial/PatientPanel';
import Filters from '../../../partials/Filters/Filters';
import Graph from './viz/Graph';

import { getPathologiesAction } from '../../../../actions/pathologyActions';
import { getGenealogiesAction } from '../../../../actions/dataVizActions';
import { getPatientAction } from '../../../../actions/patientsActions';

import './genealogy.scss';

export default function GenealogyViz({ setActivePage }) {

  const [ context, dispatch ] = useGlobalContext();

  const data = context.dataviz.genealogies;
  const pathologiesList = context.pathology.pathologiesList;
  const patient = context.patient.patient

  const [ filters , setFilters ] = useState({});
  const [ panelIsOpen , setPanelIsOpen ] = useState(false);
  const [ isCreate , setIsCreate ] = useState(false);

  const getData = useCallback(() => {
    getGenealogiesAction(dispatch);
    getPathologiesAction(dispatch);
  },[dispatch]);

  useEffect(() => {
    setActivePage("dataviz-genealogy");
    getData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (filters.search) {
      let found = data.nodes.find(item => item.code.toLowerCase().includes(filters.search.toLowerCase()));
      if (found) getPatientAction(dispatch , found._id)
    }
    // eslint-disable-next-line
  },[filters]);

  // useEffect(() => {
  //   if(context.auth.toast?.message === "Le patient a été supprimé") {
  //     closePatientPanel()
  //   }
  //   // eslint-disable-next-line
  // }, [context.auth.toast?.message])

  useEffect(() => {
    if (patient) {
      setPanelIsOpen(true);
    } else {
      setPanelIsOpen(false);
    }
  },[patient]);

  function closePatientPanel() {
    setPanelIsOpen(false);
    setIsCreate(false);
    dispatch({
      type:'GET_PATIENT',
      payload: null
    })
  }

  return (
    <>
      <div className="page-container genealogy">
        <Filters filters={filters} setFilters={setFilters} onlySearchPatient={true} isDisabled={false } />
        <div className={`graph-container ${panelIsOpen ? "panelIsOpen" : "" }`}>
          {data && pathologiesList && <Graph data={data} pathologiesList={pathologiesList} patient={patient} dispatch={dispatch} panelIsOpen={panelIsOpen}/>}
        </div>
      </div>
      <PatientPanel
        isCreate={isCreate}
        setIsCreate={setIsCreate}
        isDataViz={true}
        isOpen={panelIsOpen}
        close={() => closePatientPanel()}
        />
    </>
  )
}
