import React, { useState , useEffect } from 'react';
import { BsFilterLeft } from "react-icons/bs";
import { RiArrowLeftSLine } from "react-icons/ri";
import { BiSearch } from "react-icons/bi";

import { useGlobalContext } from '../../../context/GlobalContext';
// import { localisationDatas } from '../../../utils/data';

import './filters.scss';
import FilterList from './partials/FilterList';

export default function Filters({ filters , setFilters , isDisabled , onlySearchPatient }) {

  const context = useGlobalContext()[0];

  const localisationOptions = context.patient?.lists?.cities?.map(c => ({ city: c.city }));
  const [ filterIsOpen , setFiltersIsOpen ] = useState(false);
  const [ search , setSearch ] = useState();

  useEffect(() => {
    if (isDisabled) {
      setFiltersIsOpen(false);
    }
  }, [isDisabled])

  function countFilters() {
    let count = 0;
    if (filters) {
      if (filters.search) count++;
      if (filters.gender) count++;
      if (filters.cities) count++;
      if (filters.pathologies) count++;
      if (filters.mutations) count++;
    }
    return <div className="count"><p>{count}</p></div>
  }

  function getClassNames() {
    let classNames = 'filters'
    if (isDisabled) {
      classNames += ' disabled';
      return classNames
    }
    if (filterIsOpen) {
      classNames += ' isOpen';
      if (onlySearchPatient) classNames += ' onlySearch'
      if (filters.pathologies && filters.pathologies.length > 0) classNames += ' mutations'
    } else {
      if (onlySearchPatient) classNames += ' onlySearch'
    }
    return classNames
  }

  return (
    <div className={getClassNames()}>
      <button className="btn-filters" onClick={() => setFiltersIsOpen(!filterIsOpen)}>
      {filterIsOpen? <RiArrowLeftSLine size={28} color={"#FFFFFF"}/>  : <BsFilterLeft size={30} color={"#FFFFFF"}/> }
      </button>
      {!filterIsOpen && !onlySearchPatient && countFilters()}
      {filterIsOpen &&
        <>

          <div className={`filter${filterIsOpen ? " isOpen" : ""}${!onlySearchPatient ? " border" : ""}`}>
            <input
              value={search ? search :  "" }
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Rechercher"
              />
            <button
              className="btn-search"
              onClick={() => setFilters({...filters , search: search ? search : "" })}>
              <BiSearch size={18} color={"#FFFFFF"}/>
            </button>
          </div>

          {!onlySearchPatient &&
        
            <>
              <div className={`filter ${filterIsOpen ? "isOpen border" : ""}`}>
                <FilterList
                  name={"Genre"}
                  list={["Homme" , "Femme"]}
                  activeList={filters && filters.gender ? filters.gender : []}
                  setActiveList={(val) => setFilters({...filters , gender: val })}
                  />
              </div>

              <div className={`filter ${filterIsOpen ? "isOpen border" : ""}`}>
                <FilterList
                  name={"Localisation"}
                  list={localisationOptions}
                  activeList={filters && filters.cities ? filters.cities : []}
                  setActiveList={(val) => setFilters({...filters , cities: val })}
                  />
              </div>

              <div className={`filter ${filterIsOpen ? "isOpen border" : ""}`}>
                <FilterList
                  name={"Maladie"}
                  list={context.pathology.pathologiesList}
                  activeList={filters && filters.pathologies ? filters.pathologies : []}
                  setActiveList={(val) => setFilters({...filters , pathologies: val })}
                  />
              </div>

              {filters.pathologies && filters.pathologies.length > 0 &&
                <div className={`filter ${filterIsOpen ? "isOpen" : ""}`}>
                  <FilterList
                    name={"Mutations"}
                    list={context.pathology.pathologiesList.filter(item => filters.pathologies.find(pathology => pathology === item._id))}
                    activeList={filters && filters.mutations ? filters.mutations : []}
                    setActiveList={(val) => setFilters({...filters , mutations: val })}
                    />
                  </div>
                } 
              </>
            }
        </>
      }
    </div>
  )
}
