import React , { useRef , useEffect , useState } from 'react';
import * as d3 from "d3";
import { cloneDeep } from 'lodash';

import { useWindowSize } from '../../../../../utils/hooks/useWindowDimensions';
import { getPatientAction } from '../../../../../actions/patientsActions';
import FamilyTree from './FamilyTree';
import Tooltip from '../../../../partials/Tooltip/Tooltip';
import { hexToRgba } from '../../../../../utils/utils';

export default function Graph({ data , pathologiesList , dispatch ,  patient }) {

  const graphContainer = useRef(null);
  const grey =  "#D8D8D8";
  const windowSize = useWindowSize();

  const [ tooltipData , setTooltipData ] = useState({ x: windowSize[0] / 2 , y: windowSize[1] / 2 });

  useEffect(() => {
    var currentGraph = graphContainer.current
    return () => {
      d3.select(currentGraph).select('canvas').remove();
      dispatch({
        type:'GET_GENEALOGIES',
        payload: null
      });
    }
    // eslint-disable-next-line
  },[]);

  useEffect(() => {
    if (data && pathologiesList) {

      const dataSet = cloneDeep(data);

      dataSet.nodes.forEach(node => {
        let color = grey;
        let pathologyName = "pas de pathologie";
        if (node.pathology && pathologiesList.find(item => item._id === node.pathology)) {
          color = pathologiesList.find(item => item._id === node.pathology).color
          pathologyName = pathologiesList.find(item => item._id === node.pathology).name
        }
        node.color = color
        node.pathologyName = pathologyName
        console.log(node)
      })

      if (!patient) {
        initGraph(dataSet);
        setTooltipData({ x: windowSize[0] / 2 , y: windowSize[1] / 2 })
      } else {
        displayFamily(dataSet);
        setTooltipData({ x: windowSize[0] / 2 , y: windowSize[1] / 2 })
      }
    }
    // eslint-disable-next-line
  },[data , pathologiesList , patient , windowSize ]);

  function initGraph(initData){
    var tempData = {...initData}
    var radius = 12;
    var dpr = window.devicePixelRatio || 1;
    var height = graphContainer.current.getBoundingClientRect().height * dpr;
    var width =  graphContainer.current.getBoundingClientRect().width * dpr;

    setTooltipData({ x: width / 2 , y: height / 2 });


    if (d3.select(graphContainer.current).select('canvas')) d3.select(graphContainer.current).select('canvas').remove();

    var graphCanvas = d3.select(graphContainer.current).append('canvas')
      .classed('graphCanvas', true)
      .attr('id' , "canvas")
      .attr('width', width + 'px')
      .attr('height', height + 'px')
      .node();
    
    var context = graphCanvas.getContext('2d');

    context.scale(dpr , dpr);

    var simulation = d3.forceSimulation()
      .force("center", d3.forceCenter( (width / 2 ) / dpr , (height / 2) / dpr ))
      .force("x", d3.forceX(width / 2).strength(0.1))
      .force("y", d3.forceY(height / 2).strength(0.1))
      .force("charge", d3.forceManyBody().strength(-50))
      .force("link", d3.forceLink().id(function(d) { return d._id; }))
      .force('collision', d3.forceCollide().radius(radius * 3))
      .alphaTarget(0)
      .alphaDecay(0.05)
    
    var transform = d3.zoomIdentity;

    function zoomed(event) {
      transform = event.transform;
      simulationUpdate();
    }

    d3.select(graphCanvas)
        .call(d3.drag().subject(dragsubject).on("start", dragstarted).on("drag", dragged).on("end",dragended))
        .call(d3.zoom().scaleExtent([1 / 10, 8]).on("zoom", zoomed));

    function dragsubject(event) {
      var i,
      x = transform.invertX(event.x),
      y = transform.invertY(event.y),
      dx,
      dy;

      for (i = tempData.nodes.length - 1; i >= 0; --i) {
        let node = tempData.nodes[i];
        dx = x - node.x;
        dy = y - node.y;

        if (dx * dx + dy * dy < radius * radius) {

          node.x =  transform.applyX(node.x);
          node.y = transform.applyY(node.y);

          return node;
        }
      }
    }

    function dragstarted(event) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      event.subject.fx = transform.invertX(event.x);
      event.subject.fy = transform.invertY(event.y);
    }

    function dragged(event) {
      event.subject.fx = transform.invertX(event.x);
      event.subject.fy = transform.invertY(event.y);
    }

    function dragended(event) {
      if (!event.active) simulation.alphaTarget(0);
      event.subject.fx = null;
      event.subject.fy = null;
    }

    simulation.nodes(tempData.nodes)
              .on("tick", simulationUpdate);

    simulation.force("link")
              .links(tempData.links).strength(2);
  
    function simulationUpdate(){
  
      context.save();
      context.clearRect(0, 0, width, height);
      context.translate(transform.x, transform.y);
      context.scale(transform.k , transform.k);

      tempData.links.forEach(function(d) {
        context.beginPath();
        context.moveTo(d.source.x, d.source.y);
        context.lineTo(d.target.x, d.target.y);
        context.lineWidth = 2.5;
        context.strokeStyle = grey; 
        context.stroke();
      });

      tempData.nodes.forEach(function(d, i) {
        context.beginPath();
        context.arc(d.x , d.y, radius, 0, 2 * Math.PI, true);
        context.fillStyle = d.color;
        context.strokeStyle = "rgba(0, 0, 0, 1)";
        context.fill();
      });

      if (closeNode) {
        d3.select('.graph').style('cursor' , 'pointer');
        context.beginPath();
        context.arc(closeNode.x, closeNode.y, radius + 2, 0, 2 * Math.PI, true);
        context.strokeStyle = hexToRgba(closeNode.color , 0.4);
        context.lineWidth = 6;
        context.stroke();
      } else {
        d3.select('.graph').style('cursor' , 'auto');
      }

      context.restore();

    }

    var closeNode;
    
    d3.select(graphCanvas)
      .on("click", function(event){
        closeNode = foundNode(event);
        if (closeNode) {
          getPatientAction(dispatch , closeNode._id);
          simulationUpdate();
        }
      })
      .on("mousemove", function(event){
        event.preventDefault();
        event.stopPropagation();
        closeNode = foundNode(event) ? foundNode(event) : null ;
        if (closeNode) {
          setTooltipData({...closeNode, x: event.x - 70 , y: event.y - 80 });
        } else {
          setTooltipData({ x: event.x - 70 , y: event.y - 120 });
        }
        simulationUpdate();
      })
    
    function foundNode(event) {
      let posX = (event.x - transform.x) / transform.k;
      let posY = (event.y - 80 - transform.y) / transform.k ;
      return simulation.find( posX , posY , 10 );
    }

  }

  function displayFamily() {
    d3.select(graphContainer.current).select('canvas').remove();
  }

  return (
    <div ref={graphContainer} className="graph">
      {patient && <FamilyTree patient={patient}/>}
      <Tooltip data={tooltipData} type={"genealogy"}/>
    </div>
  )
}
