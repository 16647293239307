import React , { useEffect , useState ,useRef } from 'react';
import * as d3 from 'd3';

import { useGlobalContext } from '../../../../../context/GlobalContext';
import { getPatientAction } from '../../../../../actions/patientsActions';
import { useWindowSize } from '../../../../../utils/hooks/useWindowDimensions';

import Tooltip from '../../../../partials/Tooltip/Tooltip';

export default function MapViz({ data , geojson , mapCount , mapWidth , mapHeight , mapKey }) {

  const dispatch = useGlobalContext()[1];
  const windowSize = useWindowSize();
  const mapRef = useRef(null);

  const [ tooltipData , setTooltipData ] = useState({ x: -200 , y: -200 });

  useEffect(() => {
    if(data && mapWidth) {
      drawMap(geojson);
    }
    // eslint-disable-next-line
  }, [data , mapWidth , windowSize]);

  function drawMap(geojsonData) {

    var tempData = data;

    var simulation = d3.forceSimulation();
    setTooltipData({ x: mapWidth / 2 , y: mapHeight / 2 })
    var dpr = window.devicePixelRatio || 1;
    var height = mapHeight * dpr;
    var width = mapWidth * dpr;

    var closeNode;

    if (d3.select(`#${mapKey}`).select('canvas')) d3.select(`#${mapKey}`).select('canvas').remove();

    var scaleExtent = mapCount === 1 ? [1 /10 , 8] : [1 , 1]

    var canvas = d3.select(`#${mapKey}`)
      .append('canvas')
      .attr('id' , `canvas-${mapKey}` )
      .attr('width' , width + 'px' )
      .attr('height' , height + 'px')
      .call(d3.zoom().scaleExtent(scaleExtent).on("zoom", zoomed))
      .node();

    var transform = d3.zoomIdentity
    // transformState;

    function zoomed(event) {
      transform = event.transform;
      ticked();
    }

    //DRAW MAP

    var center = d3.geoCentroid(geojsonData);
    var scale  = 10
    var offset = [width/2, (height/2 )];
    var radius = 10;

    var projection = d3.geoMercator()
      .scale(scale)
      .center(center)
      .translate(offset);

    var geoGenerator = d3.geoPath()
      .projection(projection)

    var bounds  = geoGenerator.bounds(geojsonData);
    var hscale  = scale * width / (bounds[1][0] - bounds[0][0]);
    var vscale  = scale * height / (bounds[1][1] - bounds[0][1]);
    scale = (hscale < vscale) ? hscale : vscale;
    offset  = [width- (bounds[0][0] + bounds[1][0])/2, height - 50 - (bounds[0][1] + bounds[1][1])/2];

    projection = d3.geoMercator().center(center)
      .scale(scale / 1.5).translate(offset);
    
    var context = canvas.getContext('2d');

    mapRef.current.addEventListener("mousemove" , mouseMoveEvent );

    mapRef.current.addEventListener("click" , clickEvent );

    function mouseMoveEvent(event) {
      event.preventDefault();
      event.stopPropagation();
      closeNode = foundNode(event) ? foundNode(event) : null ;
      if (closeNode) {
        mapRef.current.style.cursor = "pointer";
        setTooltipData({...closeNode, x: event.offsetX - 70 , y: event.offsetY - 80 })
      } else {
        if (mapRef.current) mapRef.current.style.cursor = "move";
        setTooltipData({ x: event.offsetX - 70 , y: event.offsetY - 80 })
      }
    }

    function clickEvent(event) {
      event.preventDefault();
      event.stopPropagation();
      closeNode = foundNode(event) ? foundNode(event) : null ;
      if (closeNode && closeNode._id) {
        getPatientAction(dispatch , closeNode._id);
      }
    };

    function foundNode(e) {
      var cursor = {
        x: ((e.offsetX * dpr ) - transform.x ) / transform.k,
        y: ((e.offsetY * dpr ) - transform.y) / transform.k 
      };
      return simulation.find( cursor.x, cursor.y , radius / transform.k );
    }

    geoGenerator = geoGenerator.projection(projection)
      .context(context);
    //DRAW NODES

    simulation.nodes(tempData)
      .force("center", null )
      .force("charge", null)
      .force("x", d3.forceX(d => {
        var pos = projection([d.lon, d.lat]);
        return pos[0]
      }))
      .force("y", d3.forceY(d => {
        var pos = projection([d.lon, d.lat]);
        return pos[1]
      }))
      .force('collision', d3.forceCollide().radius(radius / transform.k ).strength(1))

    simulation.alpha(1).stop();

    var numberOfTicks = Math.ceil(Math.log(simulation.alphaMin()) / Math.log(1 - simulation.alphaDecay()));
    for (var i = 0, n = numberOfTicks ; i < n; ++i) {
      simulation.tick();
    }
    
    update();
    
    function ticked() {
      simulation
        .force('collision', d3.forceCollide().radius(radius / transform.k ).strength(1))
        .alpha(1).stop();

      var numberOfTicks = Math.ceil(Math.log(simulation.alphaMin()) / Math.log(1 - simulation.alphaDecay()))
      for (var i = 0, n = numberOfTicks; i < n; ++i) {
        simulation.tick();
      }

      window.requestAnimationFrame(update);

    }

    function update() {

      context.clearRect(0, 0, width, height)
      context.save();
      context.translate(transform.x, transform.y);
      context.scale(transform.k, transform.k);

      context.lineWidth = 0.5;
      context.strokeStyle = '#192243';
      context.fillStyle = '#192243'

      context.beginPath();
      geoGenerator(geojsonData);
      context.fill();
      context.stroke();
      
      tempData.forEach(function(d, i) {
        context.beginPath();
        context.moveTo(d.x, d.y);
        context.arc(d.x , d.y, radius / transform.k , 0, 2 * Math.PI );  
        context.fillStyle = d.color;
        context.fill();
      });

      context.restore();

    }

  }

  return (
    <div ref={mapRef} className="map" onMouseEnter={() => drawMap(geojson)} id={mapKey} style={{ height: mapHeight , width: mapWidth }}>
      <Tooltip data={tooltipData} type={"geography"}/>
    </div>
  )
}
