import { getData , putData , postData, deleteData  } from './index';

import {
  ERROR_PATIENT,
  GET_PATIENTS,
  GET_PATIENT,
  PUT_PATIENT,
  POST_PATIENT,
  DELETE_PATIENT,
  POST_PATIENT_FILTERED,
  GET_LIST
} from "./types"


export async function getPatientsAction(dispatch) {
  let url = "/patient";
  var patients;

  await getData(ERROR_PATIENT, url, dispatch , true ).then((response)=>{
    patients = response.patients
  });

  dispatch({
    type : GET_PATIENTS,
    payload : patients
  });

};

export async function postPatientFiltredAction(dispatch , data ) {
  let url = `/patient/filtered`;
  var patients;

  await postData(POST_PATIENT_FILTERED, ERROR_PATIENT ,  url , dispatch, data , true ).then((response)=>{
    patients = response
  });

  dispatch({
    type : POST_PATIENT_FILTERED,
    payload : {
      ...patients,
      page: data.page
    }
  });
};

export async function getPatientAction(dispatch , id ) {
  let url = `/patient/${id}`;
  var patient;

  await getData(ERROR_PATIENT, url, dispatch , true ).then((response)=>{
    patient = response.patient
  });

  dispatch({
    type : GET_PATIENT,
    payload : patient
  });

};

export async function getPatientListsAction(dispatch) {
  let url = `/patient/list`;
  var lists;

  await getData(ERROR_PATIENT, url, dispatch , true ).then((response)=>{
    lists = response.lists
  });

  dispatch({
    type : GET_LIST,
    payload : lists
  });

};


export async function putPatientAction(dispatch , id , data ) {
  let url = `/patient/${id}`;
  var patient;

  await putData(PUT_PATIENT, ERROR_PATIENT ,  url , dispatch, data , true ).then((response)=>{
    patient = response
  });

  dispatch({
    type : PUT_PATIENT,
    payload : patient
  });

};

export async function postPatientAction(dispatch , data ) {
  let url = `/patient/`;
  var patient;

  await postData(POST_PATIENT, ERROR_PATIENT ,  url , dispatch, data , true ).then((response)=>{
    patient = response
  });

  dispatch({
    type : POST_PATIENT,
    payload : patient
  });

};

export async function deletePatientAction(dispatch , id ) {
  let url = "/patient/" + id;
  let isDelete = false

  await deleteData(ERROR_PATIENT, url, dispatch , true ).then((response)=>{
    isDelete = true
 

  });
  if (isDelete) {
    dispatch({
      type : DELETE_PATIENT,
      payload : id
    });
    dispatch({
      type: 'SET_TOAST',
      payload: {
        message: "Le patient a été supprimé",
        type: "success"
      }
    });
  }


};


