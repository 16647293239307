import React from 'react';
import { Link } from 'react-router-dom';
import './navViz.scss';

export default function NavViz({ activePage } ) {
  return (
    <nav className={`nav-viz${activePage === "dataviz-genealogy" || activePage === "dataviz-geography" || activePage === "dataviz-timeline" ? " isActive": "" }`}>
      <Link to={'/dataviz-genealogy'} className={activePage === "dataviz-genealogy" ? "isActive" : ""} >GÉNÉALOGIE</Link>
      <Link to={'/dataviz-geography'} className={activePage === "dataviz-geography" ? "isActive" : ""} >GÉOGRAPHIE</Link>
      <Link to={'/dataviz-timeline'} className={activePage === "dataviz-timeline" ? "isActive" : ""} >TIMELINE</Link>
    </nav>
  )
}
