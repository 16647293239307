import * as d3 from "d3";

export function setVhUnit() {
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  
  window.addEventListener('resize', () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });
}

export const hexToRgba = (hex, alpha) => {
  let r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}

export const brighterColorScale = (color , length , index ) => {
  const colorLighter = d3.scaleLinear().domain([0,length])
    .range([color, hexToRgba(color , 0.1)]);
  return colorLighter(index);
}

export const calcAge = (birthDate) => {
  let birthYear = new Date(birthDate).getFullYear();
  let thisYear = new Date().getFullYear();
  return thisYear - birthYear ;

}

export const closestLocation = (targetLocation, locationData) => {
  function vectorDistance(dx, dy) {
      return Math.sqrt(dx * dx + dy * dy);
  }

  function locationDistance(location1, location2) {
      var dx = location1.x- location2.x,
          dy = location1.y - location2.y;

      return vectorDistance(dx, dy);
  }

  return locationData.reduce(function(prev, curr) {
      var prevDistance = locationDistance(targetLocation , prev),
          currDistance = locationDistance(targetLocation , curr);
      return (prevDistance < currDistance) ? prev : curr;
  });
}

export function truncate(str, n) {
  return (str.length > n) ? `${str.substr(0, n - 1) } ...` : str;
}
