import React ,{ useEffect , useState , useRef , useCallback } from 'react';

import { useGlobalContext } from '../../../../context/GlobalContext';

import PatientPanel from '../../patients/partial/PatientPanel';
import Filters from '../../../partials/Filters/Filters';
import YearsPicker from '../../../partials/YearsPicker/YearsPicker';
import TimeViz from './Viz/TimeViz';

import { getPathologiesAction } from '../../../../actions/pathologyActions';
import { getNodesFiltredAction } from '../../../../actions/dataVizActions';

import './timeline.scss';

export default function TimeLineViz({ setActivePage }) {
  
  const [ context, dispatch ] = useGlobalContext();

  const data = context.dataviz.localistaions;
  const pathologiesList = context.pathology.pathologiesList;
  const patient = context.patient.patient;
  const grey =  "#D8D8D8";
  const pageRef = useRef(null);

  const [ filters , setFilters ] = useState({});
  const [ panelIsOpen , setPanelIsOpen ] = useState(false);
  const [ isCreate , setIsCreate ] = useState(false);
  const [ yearsFilters , setYearsFilters ] = useState({ type: "birthDate" , year: 1900 , years: []});
  const [ timelineData , setTimelineData ] = useState();

  const getFiltredNodes = useCallback(() => {
    getNodesFiltredAction(dispatch , filters);
  },[dispatch , filters])

  const getData = useCallback(() => {
    getPathologiesAction(dispatch);
    dispatch({
      type:'GET_PATIENT',
      payload: null
    })
  },[dispatch]);

  useEffect(() => {
    setActivePage("dataviz-timeline");
    getData();
    getFiltredNodes();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data && data.length > 0 && pathologiesList.length > 0) {
      data.forEach(node => {
        let color = grey
        let pathologyName = "pas de pathologie";
        if (node.pathology && pathologiesList.find(item => item._id === node.pathology)) {
          color = pathologiesList.find(item => item._id === node.pathology).color
          pathologyName = pathologiesList.find(item => item._id === node.pathology).name
        }
        node.color = color;
        node.pathologyName = pathologyName
      })
    }
  },[data , pathologiesList]);

  useEffect(() => {
    var filteredByYears = []
    if (data && data.length > 0) {
      data.forEach(patient => {
        if(new Date(patient[yearsFilters.type]).getFullYear() === yearsFilters.year ) {
          filteredByYears.push(patient)
        }
      })
      if (filteredByYears.length > 0 ) setTimelineData(filteredByYears);
    }
  }, [data , yearsFilters.year , yearsFilters.type]);

  useEffect(() => {
    getFiltredNodes();
  },[filters , getFiltredNodes])

  useEffect(() => {
    if (patient) {
      setPanelIsOpen(true);
    } else {
      setPanelIsOpen(false)
    }
  },[patient]);

  function closePatientPanel() {
    setPanelIsOpen(false);
    setIsCreate(false);
    dispatch({
      type:'GET_PATIENT',
      payload: null
    })
  }

  return (
    <>
      <div className="page-container timeline" ref={pageRef}>
        <Filters filters={filters} setFilters={setFilters} isDisabled={patient ? true : false } />
        <YearsPicker
          data={data}
          yearsFilters={yearsFilters}
          setYearsFilters={setYearsFilters}
          />
        <div className="timeline-container" style={{ transform: `translateX(${panelIsOpen? -22 : 0}%)` }}> 
          {timelineData && 
            <TimeViz
              data={timelineData}
              panelIsOpen={panelIsOpen}
              yearsFilters={yearsFilters}
              setYearsFilters={setYearsFilters}
            />
          }
        </div>
      </div>
      <PatientPanel
        isCreate={isCreate}
        setIsCreate={setIsCreate}
        isDataViz={true}
        isOpen={panelIsOpen}
        close={() => closePatientPanel()}
        />
    </>
  )
}
