import React from 'react'
import * as d3 from 'd3';

export default function MapNav({ selectedMaps , setSelectedMaps , mapOptions }) {

  const Button = ({ map , selectedMaps , setSelectedMaps }) => {

    function handleChangeMaps(map) {
      let updatedList = [...selectedMaps]
      if(selectedMaps.find(item => item.label === map.label)) {
        updatedList = updatedList.filter(item  => item.label !== map.label);
      } else {
        updatedList.push(map);
      }
      if (updatedList.length > 0 ) {
        d3.selectAll('canvas').remove();
        setSelectedMaps(updatedList);
      }
    }

    return (
      <button
        className={selectedMaps.find(item => item.label === map.label) ? "isActive" : ""}
        onClick={() => handleChangeMaps(map)}>
        <img src={map.icon} alt={map.label} />
        <p>{map.label}</p>
      </button>
    )
  };

  return (
    <div className="nav-map">
      {mapOptions.map((map , i) => <Button
        key={'nav-map'+ i}
        selectedMaps={selectedMaps}
        setSelectedMaps={setSelectedMaps}
        map={map} />)}
    </div>
  )
}
