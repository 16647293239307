import React, { useState } from 'react';
import { BiMinus } from "react-icons/bi";
import { BsPlus } from "react-icons/bs";
import { MdRadioButtonChecked , MdRadioButtonUnchecked } from "react-icons/md";

export default function FilterList({ name , list , activeList , setActiveList }) {

  const [ listIsOpen , setListIsOpen  ] = useState(false);

  function handleChangeActiveList(id) {
    let newList = [...activeList];
    if (activeList.find(d => d === id )) {
      newList = activeList.filter(item => item !== id);
    } else {
      newList.push(id)
    }
    setActiveList(newList);
  }

  return (
    <>
      <p>{name} {activeList.length > 0 && <span>({activeList.length})</span>} </p>
      <button
        onClick={() => setListIsOpen(!listIsOpen)}
        >
        {listIsOpen ? <BiMinus size={20} color={"#FFFFFF"}/> : <BsPlus size={20} color={"#FFFFFF"}/>}
      </button>
      {listIsOpen &&
        <div className="list">
        {list.map((item , i) => {
          if (name === "Maladie") {
            let isActive = activeList.find(d => d === item._id );
            return (
              <div key={i}
                onClick={() => handleChangeActiveList(item._id)}
                className={`item ${isActive ? "isActive" : ""}`}>
                <div className="pathology">
                  {isActive ? <MdRadioButtonChecked size={14} color={"#FFFFFF"}/> : <MdRadioButtonUnchecked size={14} color={"#5F657C"}/> }
                  <p>{item.name}</p> 
                  <div className="color-circle" style={{ backgroundColor: item.color }}></div>
                </div>
              </div>
            )
          } else if (name === "Genre") {
            let isActive = activeList.find(d => d === item );
            return (
              <div key={i}
                onClick={() => handleChangeActiveList(item)}
                className={`item ${isActive ? "isActive" : ""}`}>
                  {isActive ? <MdRadioButtonChecked size={14} color={"#FFFFFF"}/> : <MdRadioButtonUnchecked size={14} color={"#5F657C"}/> }
                  <p>{item}</p> 
              </div>
            )
          } else if (name === "Localisation") {
            let isActive = activeList.find(d => d === item.city );
            return (
              <div key={i}
                onClick={() => handleChangeActiveList(item.city)}
                className={`item ${isActive ? "isActive" : ""}`}>
                {isActive ? <MdRadioButtonChecked size={14} color={"#FFFFFF"}/> : <MdRadioButtonUnchecked size={14} color={"#5F657C"}/> }
                <p>{item.city}</p>
              </div>
            )
          } else if (name === "Mutations") {
            return (
              <div key={i}
                className={"item mutations"}>
                <div className="pathology">
                  <p>{item.name}</p> 
                  <div className="color-circle" style={{ backgroundColor: item.color }}></div>
                </div>
                <div className="mutations-list">
                {item.mutations && item.mutations.map((item , i)  => {
                  let isActive = activeList.find(d => d === item );
                  return (
                    <div key={i}
                      onClick={() => handleChangeActiveList(item)}
                      className={`mutation-item ${isActive ? "isActive" : ""}`}>
                      {isActive ? <MdRadioButtonChecked size={14} color={"#FFFFFF"}/> : <MdRadioButtonUnchecked size={14} color={"#5F657C"}/> }
                      <p>{item}</p>
                    </div>
                  )
                })}
                </div>
              </div>
            )
          } else {
            return null;
          }
        })}
      </div>
      }
    </>
  )
}
