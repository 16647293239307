import {
  MESSAGE_PATIENT,
  ERROR_PATIENT,
  GET_PATIENTS,
  GET_PATIENT,
  PUT_PATIENT,
  GET_LIST,
  POST_PATIENT,
  DELETE_PATIENT,
  POST_PATIENT_FILTERED
} from "../actions/types";

const initialState = {
  patientsList: [],
  patient: null,
  error: null,
  message: null,
  count: null,
  page : 1,
  lists: null
};

export default function patientsReducer(state = initialState, action) {
  let updatedList = state.patientsList
  switch (action.type) {
    case PUT_PATIENT:
        updatedList[updatedList.findIndex(item => item._id === action.payload.patient._id)] = action.payload.patient;
      return {...state , patient: action.payload.patient , message: action.payload.message  , patientsList: updatedList };
    case GET_LIST:
      return {...state , lists: action.payload };
    case DELETE_PATIENT:
        return {...state , patient: null , patientsList: updatedList.filter(p => p._id !== action.payload )};
    case GET_PATIENT:
      return {...state , patient: action.payload };
    case POST_PATIENT:
        updatedList.unshift(action.payload.patient)
      return {...state , patientsList: updatedList , message : action.payload.message };
    case GET_PATIENTS:
      return {...state , patientsList: action.payload };
    case POST_PATIENT_FILTERED:
        if (action.payload.page === 1) {
          updatedList = action.payload.patients;
        } else {
          action.payload.patients.forEach(patient => {
            if (!updatedList.find(item => item._id === patient._id)) {
              updatedList.push(patient)
            }
          })
        }
        return {...state , patientsList: updatedList , count : action.payload.count };
    case ERROR_PATIENT:
      return {...state , error: action.payload };
    case MESSAGE_PATIENT:
      return {...state , message : action.payload};
    default:
      return {...state};
  }
}