const dark = '#192243'

export const formSelect = {
  container: (provided, state) => ({
    ...provided,
    width: '100%',
    marginTop: 0,
    marginBottom: 0,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    // maxHeight: 31,
    top: '48%',
    padding: "0px 5px"
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#FFFFFF",
    top: '48%'
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    maxHeight: 31,
  }),
  indicatorSeparator:(provided, state) => ({
    ...provided,
    display: 'none'
  }),
  input: (provided, state) => ({
    ...provided,
    maxHeight: 31,
    margin: 0,
    top: 10,
    color: "#FFFFFF",
  }),
  control: (provided, state) => {
     return ({
    ...provided,
    boxShadow: "none",
    backgroundColor: 'rgba(95, 101, 124, 0.1)',
    minHeight: 25,
    borderRadius: 3,
    border: state.isFocused || state.isSelected ? "1px solid #FFFFFF" : "1px solid #5F657C",
    '&:hover': {
      border: state.isFocused || state.isSelected ? "1px solid #FFFFFF" : "1px solid #5F657C",
    }
  })},
  menu: (provided, state) => ({
    ...provided,
    marginTop: -1,
    borderRadius: 0,
    border: "1px solid #FFFFF"
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    color: state.isFocused || state.isDisabled || state.isSelected ? dark : dark,
    backgroundColor: state.isFocused ? '#c5c9d6': "#FFFFFF"
  })
}