import React , { useState , useEffect } from 'react';

import Select from '../../../../partials/Select/Select';
import { formSelect } from '../../../../partials/Select/styles';

import { getPatientAction } from '../../../../../actions/patientsActions';
import { hexToRgba } from '../../../../../utils/utils';

import { MdCreate , MdDeleteForever } from "react-icons/md";
import { BsPlus } from "react-icons/bs";
import link from '../../../../../assets/images/link.svg';

import './EditGenealogy.scss';

export default function EditGenealogy({ dispatch , patient , handleChange , setIsCreate , isPreview , findPathologie , patientsList,  color }) {

  const [ parents , setParents ] = useState([]);

  useEffect(() => {
    if (patient.parents) {
      setParents(patient.parents)
    } else {
      setParents([])
    }
    
  }, [patient])

  function getPatient(parent) {
    let id = parent._id ? parent._id : parent ;
    setIsCreate(false);
    getPatientAction(dispatch , id );
  }

  function handleChangeParents(val , index ) {
    let parentsArray = [...parents];
    if (parentsArray.find(item => item === val)) {
      parentsArray = parentsArray.filter(item => item !== val);
    } else {
      parentsArray[index] = val.value;
    }
    setParents(parentsArray);
    handleChange(parentsArray);
  }

  const Parent = ({ parent , index , patientsList } ) => {
    const [ addParent , setAddParent ] = useState(false);
    const [ parentIsHover , setParentIsHover ] = useState(false);

    const patientOptions = patientsList ? patientsList?.filter(p => p._id !== patient._id).map(item => ({ value: { _id : item._id , pathology: item.pathology , code: item.code , gender: item.gender }, label: item.code })) : [] ;
    var  parentFound = patientOptions.find(item => item.value._id === parent);
    return (
      <div className="parent">
        <>
        {parent &&
          <>
            {addParent ?
              <>
                <Select
                  styles={formSelect}
                  handleChange={(val) => handleChangeParents(val , index )}
                  options={patientOptions}
                  />
              </>
              :
              <>
                <button
                  onClick={() => getPatient(parent)}
                  onMouseOver={() =>setParentIsHover(true)}
                  onMouseOut={() =>setParentIsHover(false)}
                  style={{ border: parentFound && parentFound.value && parentFound.value.pathology ? `2px solid ${color}` : "2px solid #8C90A1" , backgroundColor: parentIsHover ? hexToRgba('#8C90A1' , 0.5 ) : "#FFFFFF" }}
                  type="button"
                  className="code">
                  {parent.code &&
                    <p>
                      {parent.code}
                      <span className="gender">{parent.gender === "Homme" ? " H." : " F." }</span>
                    </p>
                  }
                  {patientOptions.find(item => item.value._id === parent) &&
                    <p>
                      {patientOptions.find(item => item.value._id === parent).value.code}
                      <span className="gender">{patientOptions.find(item => item.value._id === parent).value.gender === "Homme" ? " H." : " F." }</span>
                    </p>
                  }
                </button>
                {!isPreview &&
                  <div className="btn-container">
                    <button
                      type="button"
                      style={{ backgroundColor: color }}
                      onClick={() => setAddParent(true)}
                      className="btn-primary">
                      <MdCreate size={18} color={"#FFFFFF"}/>
                    </button>
                    <button
                      type="button"
                      onClick={() => handleChangeParents(parent , index )}
                      style={{ backgroundColor: color }}
                      className="btn-primary">
                      <MdDeleteForever size={18} color={"#FFFFFF"}/>
                    </button>
                  </div>
                }
              </>
            }
            </>
          }
          </>
          {!parent &&
            <>
            {addParent ?
              <>
                <Select
                  styles={formSelect}
                  handleChange={(val) => handleChangeParents(val , index )}
                  options={patientOptions}
                  />
              </>
              :
              <>
                {!isPreview ?
                  <>
                    <button
                      type="button"
                      onClick={() => setAddParent(true)}
                      style={{ backgroundColor: color }}
                      className="btn-primary">
                      <BsPlus size={20} color={"#FFFFFF"}/>
                    </button>
                  </>
                :
                  <div className="code unknown">
                    <p>inconnu</p>
                  </div>
              }
              </>
            }
            </>
          }

      </div>
    )
  }

  return (
    <div className="genealogy">
      <div className="parents">
        <Parent parent={parents[0]} index={0} patientsList={patientsList}/> <Parent parent={parents[1]} index={1} patientsList={patientsList}/>
      </div>
      <img src={link} alt="link" />
      <div className="child">
        {patient.pathology && <div className="circle" style={{ backgroundColor: findPathologie(patient.pathology)?.color }}></div>}
        {patient.code} <span className="gender">{patient.gender === "Homme" ? "H." : "F." }</span>
      </div>
    </div>
  )
}
