import { getData , putData } from './index';

import {
  ERROR_PATHOLOGY,
  GET_PATHOLOGIES,
  GET_PATHOLOGY,
  PUT_PATHOLOGY
} from "./types"


export async function getPathologiesAction(dispatch) {
  let url = "/pathology";
  var pathologies;

  await getData(ERROR_PATHOLOGY, url, dispatch , true ).then((response)=>{
    pathologies = response.pathologies
  });

  dispatch({
    type : GET_PATHOLOGIES,
    payload : pathologies
  });

};

export async function getPathologyAction(dispatch , id ) {
  let url = `/pathology/${id}`;
  var pathology;

  await getData(ERROR_PATHOLOGY, url, dispatch , true ).then((response)=>{
    pathology = response.pathology
  });

  dispatch({
    type : GET_PATHOLOGY,
    payload : pathology
  });

};

export async function putPathologyAction(dispatch , id , data ) {
  let url = `/pathology/${id}`;
  var pathology;

  await putData(PUT_PATHOLOGY, ERROR_PATHOLOGY ,  url , dispatch, data , true ).then((response)=>{
    pathology = response
  });

  dispatch({
    type : PUT_PATHOLOGY,
    payload : pathology
  });

};


