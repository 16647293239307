import React , { useEffect , useCallback , useState  } from 'react';
import { Link } from 'react-router-dom';
import { useGlobalContext } from '../../../context/GlobalContext';
import { useForm } from "react-hook-form";
import { putUserAuthAction , getUserAuthAction } from '../../../actions/authActions';

import './account.scss';

export default function Account({ setActivePage }) {

  const [ context, dispatch ] = useGlobalContext();
  const { handleSubmit, register, errors } = useForm();
  const { userAuth , message } = context.auth

  const [ changePassword , setChangePassword ] = useState(false);

  const getUser = useCallback(() => {
    getUserAuthAction(dispatch);
  },[dispatch]);

  useEffect(() => {
    setActivePage("account");
    getUser();
  }, [setActivePage , getUser]);

  useEffect(() => {
    if (message && message === "profile updated") {
      dispatch({
        type: 'SET_TOAST',
        payload: {
          message: "Votre compte a été modifié",
          type: "success"
        }
      });
      dispatch({
        type:'MESSAGE_AUTH',
        payload: null
      });
    }
  }, [message , dispatch])

  const submitProfile = (values) => {
    setChangePassword(false);
    putUserAuthAction(dispatch , values);
  }

  return (
    <div className="page-container account-page">
     
      <form className="content" onSubmit={handleSubmit(submitProfile)}>
        
        <div className="row">
          <div className="col-2">
            <div className="form-section">
              
              <div className="title">
                <h3 className="bold">MON COMPTE</h3>
              </div>

              <label htmlFor="lastName">Nom</label>
              <input
                name="lastName"
                className="primary"
                defaultValue={userAuth && userAuth.lastName ? userAuth.lastName : ""}
                ref={register({
                  required: "Compléter le nom",
                })}
              />
              <p className="text-error">{errors  && errors.lastName && errors.lastName.message}</p>
              
              <label htmlFor="firstName">Prénom</label>
              <input
                name="firstName"
                defaultValue={userAuth && userAuth.firstName ? userAuth.firstName : ""}
                className="primary"
                ref={register({
                  required: "Compléter le prénom",
                })}
              />
              <p className="text-error">{errors && errors.firstName && errors.firstName.message}</p>

              <label htmlFor="email">Email</label>
              <input
                name="email"
                className="primary"
                autoComplete="off"
                defaultValue={userAuth && userAuth.email ? userAuth.email : ""}
                ref={register({
                  required: "Compléter l'email",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Adresse mail invalide"
                  }
                })}
              />

              <p className="text-error">{errors.email && errors.email.message}</p>

              <div className="btn-container password">
              {changePassword ? <button className="btn primary" type="button" onClick={() => setChangePassword(false)}>Conserver l'ancien mot de passe</button> : <button className="btn primary" type="button" onClick={() => setChangePassword(true)}>Changer le mot de passe</button>}
              </div>
              
              {changePassword &&
                <>
                  <label htmlFor="password">Mot de passe</label>
                  <input
                    name="password"
                    type="password"
                    className="primary"
                    autoComplete="new-password"
                    ref={register({
                      required: "Compléter le mot de passe",
                      pattern: {
                        value: /^(?=.*[0-9])(?=.{6,})/i,
                        message: "Au moins un chiffre et minimum 6 caracteres"
                      }
                    })}
                  />

                  <p className="text-error">{errors.password && errors.password.message}</p>
                </>
              }

              <div className="btn-container">
                <button className="btn primary" type="submit">valider</button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Link to="/legal">Conditions générales d'utilisation</Link>
    </div>
  )
}
